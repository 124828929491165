import "./documentsGroup.scss";

import React from "react";

import DocumentComponent from "../../document_component/document_component";


export default function DocumentsGroup(props: any) {
	if (props.downloads.length === 0) {
		return <div />
	}
	return (
		<div className="documents-group-root">
			<div className="hr-text-root">
				<hr className="hr-text" data-content={props.name} />
			</div>
			<div className="documentsContainer">
				{props.downloads.map((item: any) => (
					<DocumentComponent type={item.type} name={item.name} url={item.url} key={item.name} />
				))}
			</div>
		</div>
	);
}
