import "./AddNewPlayerComponent.scss";

import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";

import AuthDialog from "../../../Authentication/auth-popup/auth-popup";
import { Player } from "../../../interfaces/player";
import { createnewPlayer, getSinglePlayerData } from "../../../table-functions/table-player-functions";

interface AddNewPlayerProps {
	addPlayerToPlayerData?: (newPlayer: Player) => void;
}

/**
 * Fügt einen neues Spielerobjekt hinzu
 * @param props addPlayerToPlayerData(newPlayer: Player) fügt den Spieler auch lokal hinzu
 */
export default function AddNewPlayerComponent(props: AddNewPlayerProps) {
	const [nachname, setNachname] = useState<string>("");
	const [strasse, setStrasse] = useState<string>("");
	const [plz, setPLZ] = useState<string>("");
	const [ort, setOrt] = useState<string>("");
	const [telefonnummer, setTelefonnummer] = useState<string>("");
	const [trainingsgruppe, setTrainingsgruppe] = useState<string>("");
	const [vorname, setVorname] = useState<string>("");
	const [geburtsdatum, setGeburtsdatum] = useState<Date>();
	const [aerztlichesZeugnisDate, setAerztlichesZeugnisDate] = useState<Date>();
	const [spielerausweis, setSpielerausweis] = useState<string>("");
	const [error_log, setError_log] = useState<string[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const handleClick = () => {
		let errors = validatePlayerData();
		setError_log(errors);
		if (errors.length === 0 && !!geburtsdatum) {
			createnewPlayer(nachname, vorname, geburtsdatum, strasse, plz, ort, telefonnummer, trainingsgruppe, "", spielerausweis, aerztlichesZeugnisDate)
				.then((result: any) => {
					if (result[0]) {
						enqueueSnackbar("Der Spieler wurde hinzugefügt", { key: "addPlayerSuccess", variant: "success" });
						getSinglePlayerData(result[1])
							.then((result: Player | undefined) => !!result && !!props.addPlayerToPlayerData && props.addPlayerToPlayerData(result))
					} else
						enqueueSnackbar("Der Spieler konnte leider nicht hinzugefügt werden", { key: "addPlayerError", variant: "error" });
				})
		}
	}

	function validatePlayerData() {
		let error_log_arr: string[] = [];
		if (!nachname) {
			error_log_arr[0] = "Der Nachname wurde nicht gesetzt";
		}
		if (!vorname) {
			error_log_arr[1] = "Der Vorname wurde nicht gesetzt";
		}
		if (!geburtsdatum) {
			error_log_arr[2] = "Das Geburtsdatum wurde nicht gesetzt";
		}
		const regex = /\d/;
		if (!strasse) {
			//if string has a number in it
			error_log_arr[3] = "Die Straße wurde nicht gesetzt";
		} else if (!regex.test(strasse)) {
			error_log_arr[3] = "Bitte fügen Sie eine Hausnummer hinzu";

		}
		if (!plz) {
			error_log_arr[4] = "Die PLZ wurde nicht gesetzt";
		}
		if (!ort) {
			error_log_arr[5] = "Der Ort wurde nicht gesetzt";
		}
		if (!trainingsgruppe) {
			error_log_arr[7] = "Der Trainingsgruppe wurde nicht gesetzt";
		}
		// if (!!aerztlichesZeugnisDate && aerztlichesZeugnisDate < new Date().toISOString().substring(0, 10)) {
		// 	error_log_arr[8] = "Das Datum für das ärztliche Zeugnis darf nicht gesetzt";
		// }
		return (error_log_arr);

	}

	function hasError(index: number) {
		return error_log[index] !== undefined;
	}

	function generateTextField(required: boolean, name: string, setter: any, index: number, type?: string) {
		if (name === "Trainingsgruppe")
			return (
				<FormControl variant="outlined"
					margin="normal"
					required={required}
					fullWidth
				>
					<InputLabel id="demo-simple-select-label">Trainingsgruppe</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						onChange={(e) => setter(e.target.value)}
						error={hasError(index)}
					>
						<MenuItem value={"Anfänger"}>Anfänger</MenuItem>
						<MenuItem value={"Fortgeschrittene"}>Fortgeschrittene</MenuItem>
						<MenuItem value={"Leistungsgruppe"}>Leistungsgruppe</MenuItem>
						<MenuItem value={"Erwachsene"}>Erwachsene</MenuItem>
					</Select>
				</FormControl>
			)
		if (name === "Geburtsdatum" || name === "Sportmedizinisches Zeugnis gültig bis")
			return <TextField
				error={hasError(index)}
				helperText={!!error_log && error_log[index]}
				variant="outlined"
				margin="normal"
				required={required}
				fullWidth
				id={name}
				label={name}
				name={name}
				type={type}
				defaultValue={new Date().toISOString().substring(0, 10)}
				autoFocus
				onChange={(event: any) => setter(event.target.value)}
			/>
		return <TextField
			error={hasError(index)}
			helperText={!!error_log && error_log[index]}
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={name}
			name={name}
			type={type}
			autoFocus
			onChange={(event: any) => setter(event.target.value)}
		/>
	}

	const button = (
		<div>
			<Button className="addNewPlayer-Button-root">
				<AiOutlineUserAdd />
			</Button>
		</div>
	);
	const addUserComponent = (
		<div className="addUserInputComponents">
			<div className="addUserInputComponents-root-styles">
				{generateTextField(true, "Nachname", setNachname, 0)}
				{generateTextField(true, "Vorname", setVorname, 1)}
				{generateTextField(true, "Geburtsdatum", setGeburtsdatum, 2, "date")}
				{generateTextField(true, "Straße", setStrasse, 3)}
				{generateTextField(true, "PLZ", setPLZ, 4)}
				{generateTextField(true, "Ort", setOrt, 5, "street-address")}
				{generateTextField(true, "Trainingsgruppe", setTrainingsgruppe, 7)}
				{generateTextField(false, "Spielerausweis", setSpielerausweis, 9)}
				{generateTextField(false, "Telefonnummer", setTelefonnummer, 6)}
				{generateTextField(false, "Sportmedizinisches Zeugnis gültig bis", setAerztlichesZeugnisDate, 8, "date")}
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className="submit-button-styles-auth"
					onClick={() => handleClick()}
				>
					<p className="component-text-styles">Add Player</p>
				</Button>
			</div>
		</div>
	);


	return (
		<AuthDialog button_name={button} component={addUserComponent} open={false} function={() => { }} donotgoBack={true} close={true} />
	)
}
