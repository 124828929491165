import { FormControl, MenuItem, Select } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner";
import { Rolle } from "../../interfaces/rollen";
import { User } from "../../interfaces/user";
import PlayerTransferList from "../../player_transfer_list/playerTransferList";
import { setRolletoUser } from "../../table-functions/table-user-functions";
import "./user-config.scss";




export default function UserConfig(props: any) {
	const [currUser, setcurrUser] = useState<User>(props.user);
	const [rolle, setRolle] = useState<Rolle>(props.user.rolle);

	useEffect(() => {
		setRolle(props.user.rolle);
	}, [currUser, props, props.user]);

	function getComponenttoSelectRole() {
		return (
			<FormControl>
				<Select
					value={rolle}
					onChange={handleChange}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
				>
					<MenuItem value={Rolle.admin}>Admin</MenuItem>
					<MenuItem value={Rolle.creater}>Creater</MenuItem>
					<MenuItem value={Rolle.Vereinsmitglied}>Vereinsmitglied</MenuItem>
					<MenuItem value={Rolle.NotAuth}>Nicht autorisiert</MenuItem>
				</Select>
			</FormControl>
		);
	}

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setRolle(event.target.value as Rolle);
		setRolletoUser(currUser, event.target.value as Rolle);
		props.user.rolle = event.target.value as Rolle;
		props.update();
	};


	useEffect(() => {
		setcurrUser(props.user);
	}, [props, props.user]);

	return (
		<div className="user-config-root">
			<Paper className="curr-user-paper-data">
				<div className="user-config-user-data">
					<p>Benutzer: {currUser.email}</p>
				</div>
				<hr></hr>
				<div className="user-config-user-data">
					<p>Benutzer-ID: {currUser.uid}</p>
				</div>
				<div className="user-config-user-data">
					<p>Name: {currUser.name}</p>
				</div>
				<div className="user-config-user-data">
					<p>Email: {currUser.email}</p>
				</div>
				<div className="user-config-user-data">
					<p>Zugriffsrecht: {getComponenttoSelectRole()}</p>
				</div>

			</Paper>
			{
				(!!props.allPlayers)
					? (
						<Paper className="curr-user-paper-data transferlist-paper">
							<PlayerTransferList uid={currUser.uid} email={currUser.email} allPlayers={props.allPlayers} />
						</Paper>
					)
					: (
						<div className="SpinnerUserConfig">
							<Spinner />
						</div>
					)
			}


		</div>
	)
}
