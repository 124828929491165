// import ":/SportObjectToCSVButton.scss"
import { Button } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useState } from "react";
import { SportEvent } from "../model/event";


interface SportObjectToCSVButtonProps {
	events: SportEvent[];
}
// props: SportObjectToCSVButton
//https://www.npmjs.com/package/objects-to-csv
export default function SportObjectToCSVButton(eventsData: SportObjectToCSVButtonProps) {
	const [allEvents, setAllEvents] = useState<SportEvent[]>();
	useEffect(() => {
		setAllEvents(eventsData.events)
	}, [eventsData, eventsData.events])

	let eventsList: any = [];
	if (allEvents) {
		eventsList = [
			{
				Titel: "",
				Art: "",
				Adresse: "",
				Beschreibung: "",
				Spielbeginn: "",
				Startdatum: "",
				Enddatum: "",
				Link: ""
			},

			...allEvents.map((event: SportEvent) => {
				let startDate = event.start_date;
				let endDate = event.end_date;
				startDate.setUTCHours(10);
				if (endDate instanceof Date)
					endDate.setUTCHours(10);
				else
					endDate = undefined;

				return {
					Titel: event.event_title,
					Art: event.tournierart,
					Adresse: event.location,
					Beschreibung: event.event_description.__html || "",
					Spielbeginn: event.spielbeginn || "",
					Startdatum: startDate.toISOString().slice(0, 10) || "",
					Enddatum: endDate?.toISOString().slice(0, 10) || "",
					Link: event.tournament_link || ""
				}
			}
			)

		]
	}

	function convertArrayOfObjectsToCSV(args: any) {
		let result: any, ctr: any, keys: any, columnDelimiter: any, lineDelimiter: any, data: any;

		data = args.data || null;
		if (data == null || !data.length) {
			return null;
		}

		columnDelimiter = args.columnDelimiter || ';';
		lineDelimiter = args.lineDelimiter || '\n';

		keys = Object.keys(data[0]);

		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item: any) {
			ctr = 0;
			keys.forEach(function (key: any) {
				if (ctr > 0) result += columnDelimiter;

				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		return result;
	}
	function downloadCSV(args: any) {
		var data, filename, link;
		var csv = convertArrayOfObjectsToCSV({
			data: eventsList
		});
		if (csv == null) return;

		filename = args.filename || 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = 'data:text/csv;charset=utf-8,' + csv;
		}
		data = encodeURI(csv);

		link = document.createElement('a');
		link.setAttribute('href', data);
		link.setAttribute('download', filename);
		link.click();
	}

	return <div>
		<Button className="eventsDownload-button" onClick={() => downloadCSV({ filename: "Events.csv" })}>Events als CSV-Dokument downloaden<GetAppIcon></GetAppIcon></Button>
	</div>
}
