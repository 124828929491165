import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSnackbar } from "notistack";
import React from "react";
import { DocumentLink, Player } from "../../authentication_required/interfaces/player";
import { uploadDocToPlayer } from "../../authentication_required/table-functions/table-player-functions";
import { createScheduledEmailForNewDocAvailable, newDocAvailableEmail } from "../../model/email-functions";
import "./uploadDocOfPlayersDialog.scss";



interface UploadDocOfPlayersDialogProps {
	index: number,
	links: DocumentLink[] | undefined,
	currPlayer: Player
	updatePlayer: (index: number, links: DocumentLink[]) => void
}

export default function UploadDocOfPlayersDialog(props: UploadDocOfPlayersDialogProps) {

	const [checked, setChecked] = React.useState(false);
	const [value, setValue] = React.useState('sofort');
	const [file, setFile] = React.useState<FileList | undefined>();
	const [selectedDate, setSelectedDate] = React.useState<Date | null>(
		new Date(),
	);
	const { enqueueSnackbar } = useSnackbar();

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);
	};

	const { index, links, currPlayer, updatePlayer } = props;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};
	const handleClickSave = () => {
		if (file && file[0]) {
			Array.from(file).map((single_file) => (
				uploadDocToPlayer(single_file, currPlayer.pid)
					.then((result: DocumentLink | null) => {
						if (!!result) {
							let final_links: DocumentLink[] = [];
							if (props.links && links) {
								final_links = [
									...links, result
								]
							} else {
								final_links = [result];
							}
							updatePlayer(index, final_links)
						} else {
							enqueueSnackbar("Fehler beim Hochladen der Dokumente", { key: "uploadDocError", variant: "error" });
						}
					})
			));
			enqueueSnackbar("Hochladen der Dokumente abgeschlossen", { key: "uploadDocFinished", variant: "info" });
			if (checked && selectedDate) {
				if (selectedDate <= new Date()) {
					newDocAvailableEmail(props.currPlayer.eMail, currPlayer.full_name)
						.then(result => {
							if (result)
								enqueueSnackbar("E-Mail wurde versendet", { key: "sendMailSuccess", variant: "success" });
							else
								enqueueSnackbar("E-Mail konnte nicht versendet werden", { key: "sendMailErr", variant: "error" });
						});
					} else {
						selectedDate.setHours(0);
						createScheduledEmailForNewDocAvailable(props.currPlayer.eMail, currPlayer.full_name, selectedDate)
							.then(result => {
								if (result)
									enqueueSnackbar(`E-Mail wird am ${selectedDate.toDateString()} versendet`, { key: "sendMailToSuccess", variant: "success" });
								else
									enqueueSnackbar("E-Mail konnte nicht versendet werden", { key: "sendMailErr", variant: "error" });
							});
				}
			}
		} else {
			enqueueSnackbar("Keine Datei zum Uploaden vorhanden", { key: "uploadErr", variant: "error" });
		}
	}

	return (
		<div className="upload-data-for-player-root">
			<div className="upload-data-root">
				<input
					multiple
					accept=".doc,.docx,.xlsx,.csv,.pdf,.txt"
					className="input-upload-file-dialog"
					id={"contained-button-file-" + index}
					type="file"
					onChange={(e) => {
						if (!!e.target.files) {
							setFile(e.target.files);
						}
					}}
				/>
			</div>
			<div className="sende_notifikation-root-container" >
				<div className="sende_notifikation">
					<Checkbox
						checked={checked}
						onChange={handleChange}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>
					<p>Sende eine Beachrichtigung</p>
				</div>
				<div className={checked ? "sende-notification-settings" : "sende-notification-settings disable-text-color"} >
					<p className="email-for-notification">An: </p>
					{
						!!props.currPlayer.eMail && props.currPlayer.eMail.map((email: string) =>
							<p className="email-for-notification">- {email}</p>
						)
					}
				</div>
				<div className="time-to-send-mail-root">
					<div className="time-to-send-mail-radio-buttons">
						<FormControl component="fieldset">
							<RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeRadioButton} >
								<div className="date-time-picker-container-root" contentEditable={false}>
									<FormControlLabel value="zeit" control={<Radio className="radioButton" />} label="zu einer gewissen Zeit" disabled={!checked} />
									{getDatePicker()}
								</div>
								<FormControlLabel value="sofort" control={<Radio className="radioButton" />} label="sofort" disabled={!checked} />
							</RadioGroup>
						</FormControl>
					</div>
				</div>
			</div>
			<div className="upload-file-buttons-root">
				<Button onClick={() => handleClickSave()}>
					Speichern
				</Button>
			</div>
		</div>
	)

	function getDatePicker() {
		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Grid container justifyContent="space-around">

					<KeyboardDatePicker
						disabled={value !== "zeit" || !checked}
						margin="normal"
						id="date-picker-dialog"
						label="Date picker dialog"
						className="picker-of-data"
						format="MM/dd/yyyy"
						value={selectedDate}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>

				</Grid>
			</MuiPickersUtilsProvider>
		)
	}
}
