import { FormControlLabel } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import "firebase/auth";
import "firebase/firestore";
import React, { useState } from "react";
import { newUserSignedUpEmail } from "../../../model/email-functions";
import { createanewUser } from "../../table-functions/table-user-functions";
import { validateSignUpData } from "../user-functions";
import "./signup.scss";





const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
	},
}));



export default function SignUp() {

	const [user_name, setUserName] = useState<string>("");
	const [user_mail, setUserMail] = useState<string>("");
	const [user_password, setUserPassword] = useState<string>("");
	const [user_password_repeat, setUserPasswordRepeat] = useState<string>("");
	//Zugriff auf folgende Spieler
	const [zugriff] = useState<string>("");
	const [checked, setButtonchecked] = useState<boolean>(false);
	const [error_log, setError_log] = useState<Array<string> | null>(null);
	const classes = useStyles();

	async function signUpto() {
		let err = await validateSignUpData(user_name, user_mail, user_password, user_password_repeat);
		await setError_log(err)
		if (await err === null && checked === true) {
			createanewUser(user_name, user_mail, user_password)
				.then((error: string[]) => { setError_log(error); return error })
				.then((err: string[]) => { if (err === [] || err.length === 0) newUserSignedUpEmail(user_name, user_mail, zugriff).then(() => window.location.href = "/"); return [] })

		}
	}

	function hasError(id: number): boolean {
		if (!!error_log) {
			return !!error_log[id];
		}
		return false;
	}

	return (
		<div className="signup-outer-container">
			<Container component="main" maxWidth="xs" aria-labelledby="simple-dialog-title">
				<CssBaseline />
				<div className={classes.paper}>
					<Avatar src="logo100.webp">
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign Up
					</Typography>
					<form className={classes.form} >
						<TextField
							error={hasError(0)}
							helperText={!!error_log && error_log[0]}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="username"
							label="Vor-/Nachname"
							name="username"
							autoFocus
							type="text"
							//Bei click keine Notification
							onChange={(event: any) => setUserName(event.target.value)}
						/>
						<TextField
							error={hasError(1)}
							helperText={!!error_log && error_log[1]}
							required
							fullWidth
							id="e-mail"
							label="E-Mail"
							name="e-mail"
							autoComplete="e-mail"
							variant="outlined"
							margin="normal"
							type="email"
							//Bei click keine Notification
							onChange={(event: any) => setUserMail(event.target.value)}
						/>
						<TextField
							error={hasError(2)}
							helperText={!!error_log && error_log[2]}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="password"
							label="Passwort"
							name="password"
							type="password"
							//Bei click keine Notification
							onChange={(event: any) => setUserPassword(event.target.value)}
						/>
						<TextField
							error={hasError(3)}
							helperText={!!error_log && error_log[3]}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="password-repeat"
							label="Passwort wiederholen"
							name="password-repeat"
							autoComplete="password-repeat"
							type="password"
							//Bei click keine Notification
							onChange={(event: any) => setUserPasswordRepeat(event.target.value)}
						/>

						{/* <TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="zugriff"
							label="Zugriff auf folgende Spieler"
							name="zugriff"
							type="text"
							onChange={(event: any) => setZugriff(event.target.value)}
						/> */}
						<FormControlLabel className="signUp-checkbox" value={checked} control={<Checkbox name="checkedC" onChange={(e) => setButtonchecked(!checked)}/>} label="Hiermit bestätigen Sie die AGB und Datenschutzrichtlinien" />

						<Button
							fullWidth
							variant="contained"
							color="primary"
							className="submit-button-styles-auth"
							onClick={() => signUpto()}
						>
							<p className="auth-component-text-styles">Sign Up</p>
						</Button>

						<Button
							fullWidth
							variant="contained"
							color="primary"
							className="submit-button-styles-auth"
							onClick={() => window.history.back()}
						>
							<p className="auth-component-text-styles">go back</p>
						</Button>
					</form>
				</div>
			</Container >
		</div>
	);
}
