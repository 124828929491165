export const firebaseConfig = {
	apiKey: "AIzaSyB-RMJcJ08FUJF7MLfvZkoeW_ztq4PUX-s",
	authDomain: "badmintonueberetsch-a1790.firebaseapp.com",
	databaseURL: "https://badmintonueberetsch-a1790-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "badmintonueberetsch-a1790",
	storageBucket: "badmintonueberetsch-a1790.appspot.com",
	messagingSenderId: "426608956268",
	appId: "1:426608956268:web:c9bea254a8e2761b40d949",
	measurementId: "G-FB0FLSG6CK"
  };
