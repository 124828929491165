import firebase from "firebase";
import { analytics, firebaseApp } from "../../App";
import { getCurrentUser } from "../Authentication/user-functions";
import { Rolle } from "../interfaces/rollen";
import { User } from "../interfaces/user";
import { deleteUserfromUserPlayerTable } from "./table-user-player-functions";

/**
 * liefert die Rolle eines Nutzers zurück, falls vorhanden
 * @param uid die User-ID des Nutzers
 * @returns die Rolle oder null sollte ein Fehler auftreten
 */

export function getRolleOfUser(uid: string): Promise<Rolle | null> {
	var docRef = firebaseApp.firestore().collection("Benutzer").doc(uid);
	return docRef.get()
		.then((doc) => (doc.exists ? (doc.data()?.rolle || null) : null))
		.then((rolle: string) => {
			if (rolle === "admin")
				return Rolle.admin
			else if (rolle === "creater")
				return Rolle.creater
			else if (rolle === "member")
				return Rolle.Vereinsmitglied
			return Rolle.NotAuth
		})
		.catch((err) => {
			analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-functions.tsx:31" });
			return null;
		});
}

export function getNameofUser(user: User) {
	return user.name;
}

export function getEmailofUser(user: User) {
	return user.email;
}

/**
 * Erstellt neuen Benutzer, der zudem in der Tabelle Benutzer gespeichert wird
 * bei fehlerbehafteten Daten, wird die Funktion abgebrochen und ein Fehler zurückgegeben
 * @param name
 * @param email
 * @param password
 * @return error log
 */
export async function createanewUser(name: string, email: string, password: string): Promise<string[]> {
	return firebaseApp.auth().createUserWithEmailAndPassword(email, password)
		.then(data => { !!data && !!data.user && addUsertoBenutzerTable(data.user, name); setNameforCurrentUser(name); return []; })
		.catch((e: any) => {
			if (e.code === "auth/email-already-in-use")
				return [
					"", "Diese E-Mail Adresse wird bereits verwendet", "", ""
				]
			else if (e.message.toString().includes("badly formatted."))
				return [
					"", "Die eingegebene E-Mail Adresse ist ungültig", "", ""
				]
			else {
				analytics.logEvent("exception", { ErrorMsg: e.message, ErrorCode: e.code, Located: "table-user-functions.tsx:62" });
				return [
					"", e.message, "", ""
				]
			}
		});

}

export function sendVerificationEmail(user: firebase.User): Promise<boolean> {
	window.localStorage.setItem('emailForSignIn', user.email || " ");
	return user.sendEmailVerification()
		.then(d => {
			return true
		})
		.catch(err => {
			analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-functions.tsx:78" });
			return false
		});
}

/**
 * Fügt eine gerade erstellten User in die Benutzertabelle ein
 * @param user firebase User
 * @param name display Name
 */
export async function addUsertoBenutzerTable(user: any, name: string) {
	let mail = user.email;
	let data = {
		email: mail,
		name: name,
	};
	return await firebaseApp.firestore().collection("Benutzer").doc(user.uid).set(data)
		.then(() => true)
		.catch(d => {
			console.error("Benutzer", d.message);
			analytics.logEvent("exception", { ErrorMsg: d.message, ErrorCode: d.code, Located: "table-user-functions.tsx:98" });
			return false;
		});
}

/**
 * setzt den Namen des Benutzers
 * @param name
 */
export function setNameforCurrentUser(name: string) {
	const update = {
		displayName: name,
		photoURL: null,
	};
	firebase.auth().currentUser?.updateProfile(update);
}

/**
 * Hohlt alle Benutzer von der Benutzertabelle und gibt diese als User-Array zurück oder -1 bei error
 */
let user: User[] | null = null;
export async function getallBenutzer(): Promise<User[]> {
	if (user == null) {
		return firebaseApp.firestore().collection("Benutzer").get()
			.then(querySnapshot => querySnapshot.docs)
			.then(querySnapshot => {
				return querySnapshot.map((doc) => ({
					uid: doc.id,
					name: doc.data().name,
					email: doc.data().email,
					rolle: doc.data().rolle || null,
				}))
			})
			.then((data: User[]) => {
				user = data;
				return data;
			})
			.catch(err => {
				analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-functions.tsx:136" });
				return []
			});
	} else
		return user;
}

/**
 * Ändert eine Benutzer - Error display name wird nicht verändert
 * @param user
 * @param newName
 * @param newRolle
 */
// export function updateUserinTable(user: User, newName: string | null, newRolle?: string | null) {
// 	var i = firebase.firestore(firebaseApp);
// 	if (!!newRolle)
// 		i.collection("Benutzer").doc(user.uid).update({
// 			name: newName || user.name,
// 			email: user.email,
// 			rolle: newRolle || user.rolle,
// 		})
// 			.catch(error => console.log(error.message));
// 	else
// 		i.collection("Benutzer").doc(user.uid).update({
// 			name: newName || user.name,
// 			email: user.email,
// 		})
// 			.catch(error => console.log(error.message));
// }

/**
 * Setzt eine Rolle zu einem bestimmten Benutzer in der Benutzertabelle
 * Nur als Admin-User möglich
 * @param user der User, indem die Rolle gesetzt werden soll
 * @param rolleofUser die Rolle, die gesetzt wird
 * @returns true, falls erfolgreich, ansonsten false
 */
export function setRolletoUser(user: User, rolleofUser: Rolle | null): Promise<boolean> {
	return firebaseApp.firestore().collection("Benutzer").doc(user.uid).update({
		rolle: rolleofUser
	})
		.then(() => true)
		.catch(err => {
			analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-functions.tsx:179" });
			return false
		});
}

/**
 * setzt die Rolle eines Users auf null
 * @param user der User, indem die Rolle verändert werden soll
 * @returns true, falls erfolgreich, ansonsten false
 */
export function deleteRollefromUser(user: User): Promise<boolean> {
	return setRolletoUser(user, null);
}



export async function deleteUser(uid: string) {
	const erg = await deleteUserfromUserPlayerTable(uid);
	const db = firebaseApp.firestore();
	let erg_delete_from_table = await db.collection("Benutzer").doc(uid).delete()
		.then(t => true)
		.catch(err => analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-functions.tsx:200" }));
	if (await erg_delete_from_table) {
		let curr_u = await getCurrentUser();
		return curr_u?.delete()
			.then(() => {
				window.location.href = "/";
				return true;
			})
			.catch((err) => {
				analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-functions.tsx:209" });
				return false;
			}
			)

	}
	return erg === erg_delete_from_table;
}
