import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import React from "react";

interface TableWithPaginatorProps {
	rows: any[];
	columns: any[];
	onClick: (data: any) => void;
}

const useStyles = makeStyles({
	root: {
		width: '100%',
		backgroundColor: 'white',
	},
	container: {
		maxHeight: 440,
	},
});

export default function TableWithPaginator(props: TableWithPaginatorProps) {
	const { rows, columns, onClick } = props;
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const classes = useStyles();
	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};
	return (
		<div>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
							return (
								<TableRow hover role="checkbox" tabIndex={-1} key={ row.player?.pid || row.user.uid} onClick={() => onClick(row.player || row.user)}>
									{columns.map((column) => {
										const value = row[column.id];
										return (
											<TableCell key={column.id} align={column.align}>
												{column.format && typeof value === 'number' ? column.format(value) : value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 200]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</div>
	)
}
