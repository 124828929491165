import React, { useEffect, useState } from "react";
import { FiMail } from "react-icons/fi";
import { GrFacebookOption } from "react-icons/gr";
import { TiSocialInstagram } from "react-icons/ti";
import { Link } from "react-router-dom";
import { firebaseApp } from "../App";
import "./footer.scss";


function sponsoredBY() {
	return (
		<div className="sponsor-styles">
			<div>
				<a href="https://www.raiffeisen.it/de/privatkunden.html" target="_blank"  rel="noopener noreferrer">
					<img src="banner-raiffeisenok.jpg" alt="Raiffeisen" />
				</a>
			</div>
			<div>
				<a href="https://www.holzhof.com/de" target="_blank"  rel="noopener noreferrer">
					<img src="holzhof-logo.webp" alt="Holzofen Logo" />
				</a>
			</div>
			<div>
				<a href="https://www.hell-profitechnik.com/" target="_blank"  rel="noopener noreferrer">
					<img src="hell-logo.webp" alt="HEllLogo" />
				</a>
			</div>
			<div>
				<a href="https://www.havoklima.com/" target="_blank"  rel="noopener noreferrer">
					<img src="havoklima-logo.webp" alt="havoklimaLogo" />
				</a>
			</div>
			<div>
				<a href="https://www.suedtirol.info/de" target="_blank"  rel="noopener noreferrer">
					<img src="suedtirol-logo.webp" alt="SüdtirolLogo" />
				</a>
			</div>
		</div>
	)
}

export default function Footer(data: any) {
	const [isLogedIn, setIsLogedIn] = useState<boolean>(false);
	const [windowSize, setWindowSize] = useState<number>();


	firebaseApp.auth().onAuthStateChanged(function (user_change) {
		if (user_change) {
			setIsLogedIn(true);
		} else {
			setIsLogedIn(false);
		}
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize(window.screen.width);
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);


	return (
		<div className="footer">
			<div className="content_container_footer">
				{
					(!!windowSize && windowSize > 1100)
						? (
							<div className="footer_logo_Verein">
								<Link to="">
									<img src="logo100.webp" alt="Logo Badminton Überetsch" />
								</Link>
							</div>
						)
						: (<div />)
				}


				<div className="left_content_container_footer">
					<div className="content">
						<Link to="/kontakt" className="footer-point" >
							<p onClick={() => window.scrollTo(0, 0)}>Kontakt / Impressum</p>
						</Link>
						<Link to="/datenschutz" className="footer-point" >
							<p>Privacy / Datenschutz</p>
						</Link>
						<Link to="/statut" className="footer-point" >
							<p>Statut Badminton Überetsch</p>
						</Link>
					</div>
					<div className="content">
						{
							(isLogedIn)
								? (
									<Link to="/logout" className="footer-point" >
										<p>Abmelden</p>
									</Link>
								)
								: (
									<Link to="/anmelden" className="footer-point" >
										<p>Anmelden</p>
									</Link>
								)
						}
						{
							(isLogedIn)
								? (
									<Link to="/yourplayerdata" className="footer-point" >
										<p>Spielerdaten</p>
									</Link>
								)
								: (
									<Link to="/signup" className="footer-point" >
										<p>Registrieren</p>
									</Link>
								)
						}
						<Link to="/spielregeln" className="footer-point" >
							<p>Spielregeln</p>
						</Link>
					</div>
				</div>
				<div className="followus-container">
					<a href="https://it-it.facebook.com/BadmintonUeberetsch/" target="_blank"  rel="noopener noreferrer" className="hyperlink">
						<div className="company-logo">
							<GrFacebookOption size="35px" />
						</div>
					</a>
					<a href="https://www.instagram.com/ueberetsch.badminton/?hl=de" target="_blank"  rel="noopener noreferrer" className="hyperlink">
						<div className="company-logo">
							<TiSocialInstagram size="35px" />
						</div>
					</a>
					<a href="mailto:badueber@gmail.com" target="_blank"  rel="noopener noreferrer" className="hyperlink">
						<div className="company-logo" >
							<FiMail size="35px" />
						</div>
					</a>
				</div>
			</div>
			<div>
				{sponsoredBY()}
			</div>
			<div className="sponsored-by-root">
				<a href="https://www.asahotel.com/" target="_blank"  rel="noopener noreferrer">
					<div className="sponsored-by-content">
						<p>powered by</p>
						<img src="MAIN-LOGO.webp" alt="ASA Logo"/>
					</div>
				</a>5
			</div>
		</div >

	);
}

