import "./auth-popup.scss";

import { DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";

import SignUp from "../signup-component/signup";


export interface SimpleDialogProps {
	open: boolean;
	close: boolean;
	com: JSX.Element
	onClose: (value: string) => void;
	win_size: number;
	donotgoBack: boolean;
}

function SimpleDialog(props: SimpleDialogProps) {
	const { onClose, com, open, win_size, close, donotgoBack } = props;

	const handleClose = () => {
		onClose("");
	};


	return (
		<Dialog className="dialogContainer-auth-popup" fullScreen={win_size < 900 && win_size > 0} onClose={handleClose} open={open}>
			<div className="dialogContainer-auth-popup-inner-root">
				{
					((win_size < 900 && win_size > 0) || com.type === (<SignUp />).type || close === false )
						? (<div className="dialogTitle-mobile-menu" onClick={() => (donotgoBack) ? handleClose() : window.history.back()}><DialogTitle ><CloseIcon /></DialogTitle></div>)
						: (<div />)
				}
				{com}
			</div>
		</Dialog>
	);
}


interface AuthDialogProps {
	button_name: JSX.Element | string,
	component: JSX.Element,
	donotgoBack?: boolean  | undefined,
	open?: boolean | undefined,
	close?: boolean | undefined,
	function?: (param: boolean) => void,
	onMouseLeave?: any,
	onClick?: any,
}

//button_name={"Anmelden"} component={<SignIn />} open={true} close={false}
/**
 *
 * @param props bestehend aus button_name, component, open?, close?
 * @param button_name der Name des Buttons, string oder "", sollte kein Text angezeigt werden
 * @param component die Komponente, die im Dialog angezeigt werden sollte
 * @param open optional - sollte der Dialog sofort geöffnet werden (true), oder erst nach dem Betätigen des Knopfes (false oder nicht gesetzt)
 * @param close optional - sollte sich der Dialog nicht schließen dürfen (false), ansonsten true oder nicht gesetzt
 */
export default function AuthDialog(props: AuthDialogProps) {
	const [open, setOpen] = React.useState(props.open || false);
	const { button_name, component } = props;
	const [windowSize, setWindowSize] = useState<number>(0);




	useEffect(() => {
		function handleResize() {
			setWindowSize(window.screen.width);
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [window.screen.width, windowSize]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		if (component.type !== (<SignUp />).type && props.close !== false)
			setOpen(false);
	};



	return (
		<div onMouseLeave={props.onMouseLeave} onClick={props.onClick}>
			<div className="button_popup_menu_auth" onClick={handleClickOpen} onMouseEnter={() => !!props.function && props.function(true)} onMouseLeave={() => !!props.function &&props.function(false)}>
				{button_name}
			</div>
			<SimpleDialog open={open} onClose={handleClose} com={component} win_size={windowSize} close={props.close || false} donotgoBack={props.donotgoBack || false} />
		</div>
	);
}
