import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import "firebase/auth";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { analytics, firebaseApp } from "../../../App";
import { sendErrorToDev } from "../../../model/email-functions";
import "./signin.scss";




const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));



export default function SignIn() {
	const [user_mail, setUserName] = useState<string>("");
	const [error_log, setError_log] = useState<any>("");
	const [password, setPassword] = useState<string>("");
	const [forgotPasswordPressed, setForgotPasswordPressed] = useState<boolean>(false);
	const [notifi_class, setNotifiClass] = useState<string>("");
	const classes = useStyles();
	function handleErrror(error: any) {
		switch (error.code) {
			case "auth/wrong-password": {
				setError_log("Ein falsches Passwort wurde eingegeben");
				break;
			}
			case "auth/invalid-email": {
				setError_log("die E-Mail Adresse ist falsch formatiert");
				break;
			}
			case "auth/user-not-found": {
				setError_log("Leider ist kein User mit dieser E-Mail Adresse vorhanden");
				break;
			}
			default: {
				sendErrorToDev(error.toString(),"signIn.tsx:60");
				analytics.logEvent("exception", { ErrorMsg: error.code, ErrorCode: error.status, Located: "signIn.tsx:74" });
				setError_log(error.code);
				break;
			}
		}
	}

	function signinWithAccount(email: string, password: string) {

		firebaseApp.auth().signInWithEmailAndPassword(email, password)
			.then((userCredential: any) => {
				// See the UserRecord reference doc for the contents of userRecord.

				//TODO: wieso ist dieser user hier und wird nicht verwendet
				// let user = userCredential.user;
			})
			.catch((err: any) => {
				handleErrror(err);
			});

	}
	const handleResetPassword = (email: string) => {
		firebaseApp.auth().sendPasswordResetEmail(email)
			.then((_: any) => {
				setNotifiClass("success");
				setError_log("We have send you an E-Mail");
			})
			.catch((error: any) => {
				setNotifiClass("");
				setError_log(error.message);
			});
	}
	const handlePressedEnterKey = (charCode: number) => {
		if (charCode === 13) {
			signinWithAccount(user_mail, password);
		}
	}

	useEffect(() => {
		if (forgotPasswordPressed) {
			setError_log("");
		}
	}, [user_mail, forgotPasswordPressed]);


	return (
		<div className="signIn-outer-container">

			{
				(forgotPasswordPressed)
					? (
						<Container component="main" maxWidth="xs">
							<CssBaseline />
							<div className={classes.paper}>
								<Avatar src="logo100.webp">
								</Avatar>
								<Typography component="h1" variant="h5">
									Reset password
								</Typography>
								<div className={`error ${notifi_class}`}>
									<p>{error_log}</p>
								</div>
								<form className={classes.form} >
									<TextField
										value={user_mail}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										autoFocus
										//Bei click keine Notification
										onChange={(event: any) => setUserName(event.target.value)}
									/>

									<Button
										fullWidth
										variant="contained"
										color="primary"
										className="submit-button-styles-auth"
										onClick={() => handleResetPassword(user_mail)}
									>
										<p className="auth-component-text-styles">send notification to my email-account</p>
									</Button>

									<Button
										fullWidth
										variant="contained"
										color="primary"
										className="submit-button-styles-auth"
										onClick={() => setForgotPasswordPressed(false)}
									>
										<p className="auth-component-text-styles">go back</p>
									</Button>
								</form>
							</div>
						</Container >
					)
					: (
						<Container component="main" maxWidth="xs">
							<CssBaseline />
							<div className={classes.paper}>
								<Avatar src="logo100.webp">
								</Avatar>
								<Typography component="h1" variant="h5">
									Sign in
								</Typography>
								<div className={`error ${notifi_class}`}>
									<p>{error_log}</p>
								</div>
								<form className={classes.form} noValidate>
									<TextField
										value={user_mail}
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="e-mail"
										label="E-Mail"
										name="e-mail"
										autoComplete="e-mail"
										type="email"
										autoFocus
										onChange={(event: any) => setUserName(event.target.value)}
										onKeyPress={(event) => handlePressedEnterKey(event.charCode)}
									/>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="password"
										label="Password"
										type="password"
										id="password"
										autoComplete="current-password"
										onKeyPress={(event) => handlePressedEnterKey(event.charCode)}
										onChange={(event: any) => setPassword(event.target.value)}
									/>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										className="submit-button-styles-auth"
										onClick={() => signinWithAccount(user_mail, password)}
									>
										<p className="auth-component-text-styles">Sign In</p>
									</Button>
									<hr></hr>
									<div className="user_help">
										<Button onClick={() => setForgotPasswordPressed(true)} className="auth-component-text-styles">
											<p className="auth-component-text-styles">Forgot password?</p>
										</Button>
										<Link to="/signup">
											<Button >
												<p className="auth-component-text-styles">Don't have an account? Sign up</p>
											</Button>
										</Link>
									</div>
								</form>
							</div>
						</Container >
					)
			}
		</div>
	);
}
