import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuListUser from "../model/menulist_user_pages/menuUser";
import "./menue-leiste.scss";


interface MenueItem {
	title: string | JSX.Element;
	path?: string;
}

function generateMenueItem(menueItems: MenueItem[], location: any): JSX.Element[] {
	return menueItems.map(
		item => (
			<Link to={`/${item.path}`} key={item.path} className={`punkt ${(location.pathname === "/" + item.path ? 'active' : "")}`} >
				<div className="rounditem">
					<p>{item.title}</p>
				</div>
			</Link>
		)
	)
}

export default function MenueLeiste() {
	const defaultItems: MenueItem[] = [
		{ title: "Home", path: "" },
		{ title: "Events", path: "events" },
		{ title: "Documents", path: "documents" },
		{ title: "Shop", path: "shop" },
		{ title: "News", path: "news" },
	];
	const [menueItems, setMenueItems] = useState<MenueItem[]>(defaultItems);

	async function getMenueItems() {
		setMenueItems(defaultItems);
	}

	useEffect(() => {
		getMenueItems();
	}, []);


	const location = useLocation();
	const menueClass = location.pathname === "/infos/verein" ? "no-background" : "";

	return (
		<div className={menueClass}>
			<div className="menuleiste">
				<div className="menuitems">
					{generateMenueItem(menueItems, location)}
					<div className="punkt color_menu_item" >
						<MenuListUser />
					</div>
				</div>
			</div>
		</div>
	);
}
