import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { analytics, curr_user, firebaseApp } from "../../../App";
import { Rolle } from "../../interfaces/rollen";
import AuthDialog from "../auth-popup/auth-popup";
import SignIn from "../signin-component/signIn";


export enum AuthTypes {
	LogIn = "login",
	SignUp = "signup",
	LogOut = "logout",
	otherAuth = "otherAuth"
}

export interface HandleAuthProps {
	type: AuthTypes;
	isUserLogedIn?: boolean;
	button_name?: string;
	display_component?: JSX.Element
	open?: boolean;
	close?: boolean;
	userRolle?: Rolle | null;
	berechtigunsStufe?: Rolle[];
	isSignUp?: boolean;
}
let hasDonelogOut: boolean = false;

export default function HandleAuth(props: HandleAuthProps) {
	const { type, button_name, display_component, open, close, isUserLogedIn, userRolle, berechtigunsStufe } = props;
	const [return_value, setReturnValue] = useState<JSX.Element>();
	const { enqueueSnackbar } = useSnackbar();
	const logOut = () => {
		if (!hasDonelogOut) {
			firebaseApp.auth().signOut().then((e) => {
				hasDonelogOut = true;
				localStorage.removeItem("allPlayerData");
				enqueueSnackbar(`Erfolgreich abgemeldet`, { key: "logoutSuccess", variant: "success" });
				setTimeout(function () { window.location.href = "/" }, 500)
			}).catch((err) => {
				analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "handle-auth.tsx:42" });
				hasDonelogOut = false;
				enqueueSnackbar(`Fehler beim abmelden, versuchen Sie es erneut`, { key: "errorLogout", variant: "error" });
				setTimeout(function () { window.location.href = "/" }, 2000)
			});
		}
	}


	if ((type === AuthTypes.LogIn || type === AuthTypes.SignUp) && !isUserLogedIn)
		return (
			<AuthDialog button_name={button_name || ""} component={display_component || <div />} open={open || false} close={close} />
		)


	if (type === AuthTypes.LogOut) {
		logOut();
		return (
			<div />
		)
	}
	//Bereits angemeldet
	if (isUserLogedIn && AuthTypes.otherAuth !== type && props.isSignUp !== true) {
		if (curr_user !== undefined) {
			setTimeout(() => {
				window.location.href = "/";
			}, 800)
		}
		return (
			<div></div>
		)
	}
	setTimeout(() => {
		if (!isUserLogedIn) {
			setReturnValue(<div>
				<HandleAuth type={AuthTypes.LogIn} button_name={""} display_component={<SignIn />} open={true} close={false} isUserLogedIn={isUserLogedIn} />
			</div>)

		}

	}, 3000);
	if (AuthTypes.otherAuth === type) {
		if (!!display_component && !!userRolle && berechtigunsStufe?.includes(userRolle))
			return (display_component)
		else
			return return_value || (<p>Sie haben nicht die nötige Berechtigung diesen Vorgang durchzuführen</p>)
	}
	return return_value || (
		<div>
			error
		</div>
	);
}
