import firebase from 'firebase/app';
import 'firebase/firestore';
import { analytics, firebaseApp, perf } from '../../App';
import { Player } from '../interfaces/player';
import { getSinglePlayerData } from '../table-functions/table-player-functions';
import { getPlayerIDsofaUser } from '../table-functions/table-user-player-functions';




export let curr_user: firebase.User | null = null;

//return the error message or ""
export function validateSignUpData(userName: string, email: string, password: string, password_repeat: string): Array<string> | null {
	let error_msg: Array<string> = ["", "", "", ""];
	let error: boolean = false;

	if (!userName || userName === "" || !userName.includes(" ")) {
		error_msg[0] = "Vor-/ oder Nachname ungültig";
		error = true;
	}
	const expression = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
	if (!expression.test(String(email).toLowerCase())) {
		error_msg[1] = "keine gültige E-Mail Adresse eingegeben";
		error = true;
	}
	if (password.length < 6) {
		error_msg[2] = "Das Passwort muss mindestens aus 6 Zeichen bestehen!";
		error = true;
	}
	if (password !== password_repeat) {
		error_msg[2] = "Die Passwörter stimmen nicht überein!";
		error = true;
	} else
		if (password_repeat.length < 6) {
			error_msg[3] = "Das Passwort muss mindestens aus 6 Zeichen bestehen!";
			error = true;
		}
	if (error === true)
		return error_msg;
	else
		return null;
}


/**
 * Hohlt alle Spieler eines User mit deren Dokumenten
 * @param uid der User
 * @returns die Spieler oder [] sollte ein Fehler oder keine Berechtigung vorhanden sein
 */
let customPlayerData: any = null;
export async function getPlayerDataofUser(uid: string, doIt?: boolean): Promise<Player[]> {
	if (!customPlayerData || customPlayerData[0] !== uid || doIt === true) {
		const trace = perf.trace("getPlayerDataofAUser-Auth");
		trace.start();
		let err = 0;
		let final_data: Player[] = [];
		// if (!localStorage.getItem(uid)) {
		let player_ids = await getPlayerIDsofaUser(uid);
		if (player_ids != null) {
			for (let player_id of player_ids) {
				let data: Player | undefined = await getSinglePlayerData(player_id)
				if (data == null) {
					err = -1;
					break;
				}
				final_data.push(data);
			}
		}
		if (err === 0) {
			let sort_data = await final_data.sort((a, b) => (a.nachname === b.nachname) ? (a.vorname > b.vorname ? 1 : -1) : (a.nachname > b.nachname) ? 1 : -1);
			localStorage.setItem(`customUserData-${uid}`, JSON.stringify(sort_data));
			customPlayerData = [uid, sort_data];
			trace.stop();
			return sort_data;
		}
		else {
			trace.stop();
			return [];
		}
	} else {
		return customPlayerData[1];
	}
}

//liefert true zurück, wenn ein User angemeldet ist
export function isAUserLogedIn() {
	return (firebaseApp.auth().currentUser != null);
}

export function getCurrentUser(): firebase.User | null {
	return firebaseApp.auth().currentUser;
}

export function getUIDofCurrentUser(): string | null {
	let curr_user = getCurrentUser();
	if (curr_user !== null)
		return curr_user.uid;
	return null;
}


export async function changeYourPasswort(newPassword: string): Promise<boolean | null>  {
	const user = await getCurrentUser();
	if (user !== null) {
		return user.updatePassword(newPassword)
			.then(() => true)
			.catch((err: any) => {
				analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "user-functions.tsx:109" });
				return false;
			});
	} else
		return null;
}


