import "./editPlayerComponent.scss";

import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { Player } from "../../../interfaces/player";
import { updatePlayer } from "../../../table-functions/table-player-functions";

interface EditPlayerProps {
	currPlayer: Player;
	update: () => void;
	disableFields?: boolean[];
}

export default function EditPlayerComponent(props: EditPlayerProps) {
	const { disableFields } = props;
	const [nachname, setNachname] = useState<string>(props.currPlayer.nachname);
	const [strasse, setStrasse] = useState<string>(props.currPlayer.strasse);
	const [plz, setPLZ] = useState<string>(props.currPlayer.plz);
	const [ort, setOrt] = useState<string>(props.currPlayer.ort);
	const [telefonnummer, setTelefonnummer] = useState<string>(props.currPlayer.telefonnummer);
	const [trainingsgruppe, setTrainingsgruppe] = useState<string>(props.currPlayer.trainingsgruppe);
	const [vorname, setVorname] = useState<string>(props.currPlayer.vorname);
	const [geburtsdatum, setGeburtsdatum] = useState<Date>(props.currPlayer.birthdate);
	const [aerztlichesZeugnisDate, setAerztlichesZeugnisDate] = useState<Date | undefined>(props.currPlayer.sportmed_gueltig_bis);
	const [spielerausweis, setSpielerausweis] = useState<string | undefined>(props.currPlayer.spielerausweis);
	const [email, setEMail] = useState<string>(props.currPlayer.eMail[0]);
	const [error_log, setError_log] = useState<string[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const handleClick = () => {
		let errors = validatePlayerData();
		setError_log(errors);
		if (errors.length === 0) {
			let all_emails = props.currPlayer.eMail;
			all_emails[0] = email;
			updatePlayer(props.currPlayer.pid, nachname, vorname, geburtsdatum, strasse, plz, ort, telefonnummer, trainingsgruppe, spielerausweis || "", aerztlichesZeugnisDate, all_emails)
				.then((result: boolean) => {
					if (result) {
						props.currPlayer.vorname = vorname;
						props.currPlayer.nachname = nachname;
						props.currPlayer.strasse = strasse;
						props.currPlayer.spielerausweis = spielerausweis;
						props.currPlayer.trainingsgruppe = trainingsgruppe;
						props.currPlayer.plz = plz;
						props.currPlayer.ort = ort;
						props.currPlayer.sportmed_gueltig_bis = aerztlichesZeugnisDate;
						props.currPlayer.birthdate = geburtsdatum;
						props.currPlayer.telefonnummer = telefonnummer;
						props.currPlayer.eMail[0] = email;
						props.update();
						enqueueSnackbar("Der Spieler wurde erfolgreich verändert", { key: "SpielerChangeSuccess", variant: "success" });
					} else {
						enqueueSnackbar("Der Spieler konnte leider nicht verändert werden", { key: "SpielerChangeError", variant: "error" });
					}
				}).catch((e) => enqueueSnackbar("Der Spieler konnte leider nicht verändert werden" + e.message, { key: "SpielerChangeError", variant: "error" }));
		}
	}

	function validatePlayerData() {
		let error_log_arr: string[] = [];
		if (!nachname) {
			error_log_arr[0] = "Der Nachname wurde nicht gesetzt";
		}
		if (!vorname) {
			error_log_arr[1] = "Der Vorname wurde nicht gesetzt";
		}
		if (!geburtsdatum) {
			error_log_arr[2] = "Das Geburtsdatum wurde nicht gesetzt";
		}
		const regex = /\d/;
		if (!strasse) {
			//if string has a number in it
			error_log_arr[3] = "Die Straße wurde nicht gesetzt";
		} else if (!regex.test(strasse)) {
			error_log_arr[3] = "Bitte fügen Sie eine Hausnummer hinzu";

		}
		if (!plz) {
			error_log_arr[4] = "Die PLZ wurde nicht gesetzt";
		}
		if (!ort) {
			error_log_arr[5] = "Der Ort wurde nicht gesetzt";
		}
		if (!trainingsgruppe) {
			error_log_arr[7] = "Der Trainingsgruppe wurde nicht gesetzt";
		}
		// if (!!aerztlichesZeugnisDate && aerztlichesZeugnisDate < new Date().toISOString().substring(0, 10)) {
		// 	error_log_arr[8] = "Das Datum für das ärztliche Zeugnis darf nicht gesetzt";
		// }
		return (error_log_arr);

	}

	function hasError(index: number) {
		return error_log[index] !== undefined;
	}

	function generateTextField(required: boolean, name: string, setter: any, index: number, defaultValue: string | Date | undefined, disable: boolean, type?: string) {
		if (name === "Trainingsgruppe" && disable)
			return (

				<TextField
					className="trainingruppenart"
					variant="outlined"
					label="Trainingsgruppe"
					disabled
					fullWidth
					value={trainingsgruppe}
					onChange={(e) => setTrainingsgruppe(e.target.value)}
				>
				</TextField>

			)
		if (name === "Trainingsgruppe")
			return (
				<FormControl variant="outlined"
					margin="normal"
					required={required}
					fullWidth
				>
					<InputLabel id="demo-simple-select-label">Trainingsgruppe</InputLabel>
					<Select
						defaultValue={defaultValue}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						onChange={(e) => setter(e.target.value)}
						error={hasError(index)}
					>
						<MenuItem value={"Anfänger"}>Anfänger</MenuItem>
						<MenuItem value={"Fortgeschrittene"}>Fortgeschrittene</MenuItem>
						<MenuItem value={"Leistungsgruppe"}>Leistungsgruppe</MenuItem>
						<MenuItem value={"Erwachsene"}>Erwachsene</MenuItem>
					</Select>
				</FormControl>
			)

		if (name === "Geburtsdatum" || name === "Sportmedizinisches Zeugnis gültig bis")
			return <TextField
				error={hasError(index)}
				helperText={!!error_log && error_log[index]}
				disabled={disable || false}
				variant="outlined"
				margin="normal"
				required={required}
				fullWidth
				id={name}
				label={name}
				name={name}
				type={type}
				defaultValue={!!defaultValue && defaultValue instanceof Date && defaultValue.toISOString().substring(0, 10)}
				onChange={(event: any) => setter(event.target.value)}
			/>
		return <TextField
			disabled={disable || false}
			error={hasError(index)}
			helperText={!!error_log && error_log[index]}
			variant="outlined"
			margin="normal"
			required={required}
			fullWidth
			id={name}
			label={name}
			name={name}
			defaultValue={defaultValue}
			type={type}
			onChange={(event: any) => setter(event.target.value)}
		/>
	}

	const addPlayerComponent = (
		<div className="addUserInputComponents-root-styles">
			<div className="alertMsg-player-component">
			</div>
			{generateTextField(true, "Nachname", setNachname, 0, props.currPlayer.nachname, (disableFields && disableFields[0]) || false)}
			{generateTextField(true, "Vorname", setVorname, 1, props.currPlayer.vorname, (disableFields && disableFields[1]) || false)}
			{generateTextField(true, "Geburtsdatum", setGeburtsdatum, 2, props.currPlayer.birthdate, (disableFields && disableFields[2]) || false, "date")}
			{generateTextField(true, "Straße", setStrasse, 3, props.currPlayer.strasse, (disableFields && disableFields[3]) || false)}
			{generateTextField(true, "PLZ", setPLZ, 4, props.currPlayer.plz, (disableFields && disableFields[4]) || false)}
			{generateTextField(true, "Ort", setOrt, 5, props.currPlayer.ort, (disableFields && disableFields[5]) || false, "street-address")}
			{generateTextField(true, "Trainingsgruppe", setTrainingsgruppe, 7, props.currPlayer.trainingsgruppe, (disableFields && disableFields[6]) || false)}
			{generateTextField(false, "E-Mail", setEMail, 10, props.currPlayer.eMail[0], (disableFields && disableFields[7]) || false)}
			{generateTextField(false, "Spielerausweis", setSpielerausweis, 9, props.currPlayer.spielerausweis, (disableFields && disableFields[8]) || false)}
			{generateTextField(false, "Telefonnummer", setTelefonnummer, 6, props.currPlayer.telefonnummer, (disableFields && disableFields[9]) || false, "tel")}
			{generateTextField(false, "Sportmedizinisches Zeugnis gültig bis", setAerztlichesZeugnisDate, 8, props.currPlayer.sportmed_gueltig_bis, (disableFields && disableFields[10]) || false, "date")}
			<Button
				fullWidth
				variant="contained"
				color="primary"
				className="submit-button-styles-auth"
				onClick={() => handleClick()}
			>
				<p className="component-text-styles">Änderung speichern</p>
			</Button>
		</div>
	);


	return (
		addPlayerComponent
	)
}
