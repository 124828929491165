import "./App.scss";

import { Fade, Paper } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import firebase from "firebase";
import { SnackbarProvider, useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Link, Route, Switch, useLocation } from "react-router-dom";
import { setTimeout } from "timers";

import HandleAuth, { AuthTypes } from "./authentication_required/Authentication/handle-auth/handle-auth";
import SignIn from "./authentication_required/Authentication/signin-component/signIn";
import SignUp from "./authentication_required/Authentication/signup-component/signup";
import { getPlayerDataofUser } from "./authentication_required/Authentication/user-functions";
import CustomPlayerData from "./authentication_required/custom-player-data/customPlayerData";
import { getPlayerDocuments } from "./authentication_required/custom-player-data/customPlayerFunctions";
import PlayerDataGUI from "./authentication_required/data-components/playerData/playerDataPage";
import ChangeUserInformationGUI from "./authentication_required/data-components/table/table";
import { Player } from "./authentication_required/interfaces/player";
import { Rolle } from "./authentication_required/interfaces/rollen";
import { getAllPlayers } from "./authentication_required/table-functions/table-player-functions";
import { getRolleOfUser, sendVerificationEmail } from "./authentication_required/table-functions/table-user-functions";
import ScheduleCalendar from "./components/calendarTable/calendar";
import Documents from "./documents/documents";
import Events from "./events/events";
import { firebaseConfig } from "./firebaseConfig";
import Footer from "./footer/footer";
import Verein from "./infos/verein/verein";
import MenueLeisteMobile from "./menue-leiste-mobile/menue-leiste-mobile";
import MenueLeiste from "./menue-leiste/menue-leiste";
import { fetchEvents, getAllArticles, import_downloads } from "./model/abfragen";
import { Article } from "./model/article";
import { SportEvent } from "./model/event";
import { DocumentItem } from "./model/utils";
import News from "./news/news";
import Profil from "./Profil/profil";
import { sendErrorToDev } from "./model/email-functions";




export var t_message_code: JSX.Element | null = null;

export const firebaseApp = getFirebaseApp();

function getFirebaseApp() {
	if (!firebase.apps.length) {
		const app = firebase.initializeApp(firebaseConfig);
		console.log(firebase.appCheck().activate("6LcbwRwcAAAAAF3yY8KnP_Y8rECggIIP6TaT3D89", true));
		return app;
	} else {
		return firebase.app();
	}
}
export const analytics = firebaseApp.analytics();
function ScrollTop(props: any) {
	const { children } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100
	});


	const handleClick = (event: any) => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			"#back-to-top-anchor"
		);

		if (anchor) {
			anchor.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	return (
		<Zoom in={trigger}>
			<div onClick={handleClick} role="presentation" className="scrollTopStyles">
				{children}
			</div>
		</Zoom>
	);
}

ScrollTop.propTypes = {
	children: PropTypes.element.isRequired
};

export let curr_user: firebase.User | null = null;
export let perf = firebase.performance(firebaseApp);
export const RUN_AS_PWA: boolean = window.matchMedia('(display-mode: standalone)').matches;

const Snake = lazy(() => import("./eastereggs/snake"));
const Privacy = lazy(() => import("./privacy/privacy"));
const Spielregeln = lazy(() => import("./infos/spielregeln/spielregeln"));
const Kontakt = lazy(() => import("./kontakt/kontakt"));
const Shop = lazy(() => import("./shop/shop"));

const ChangePassword = lazy(() => import("./passwort_ändern/change_password"));
const ErrorPage = lazy(() => import("./404/404Page"));
const SpaceGame = lazy(() => import("./eastereggs/space/spaceGame"));
const Reacteroids = lazy(() => import("./eastereggs/space2/Reacteroids"));
const ContentCreaterPage = lazy(() => import("./lazyLoadComp/contentCreater"));



export default function App() {
	return (
		<div >
			<React.Fragment >
				<SnackbarProvider maxSnack={3} >
					<Toolbar id="back-to-top-anchor" />
					<Router >
						<Suspense fallback={<div>Loading...</div>}>
							<Switch>
								<Route path="*">
									<RouteDefinition />
								</Route>
							</Switch>
						</Suspense >
					</Router>
				</SnackbarProvider>
			</React.Fragment>
		</div >
	);
}


function RouteDefinition() {
	const { enqueueSnackbar } = useSnackbar();
	let location = useLocation();
	const [allPlayer, setAllPlayer] = useState<Player[]>();
	const [customUserPlayer, setCustomPlayer] = useState<Player[]>();
	const [isUserLogedIn, setUserLogedIn] = useState<boolean>(false);
	const [rolle, setRolle] = useState<Rolle | null>(null);
	const [hassendEMail, setHassendEMail] = useState<boolean | null>(null);
	const [news, setNews] = useState<Article[]>();
	const [events, setEvents] = useState<SportEvent[]>();
	const [latestArticle, setLatestArticle] = useState<Article>();
	const [documents_public, setDocumentsPublic] = useState<DocumentItem[]>();
	const [getAccessToTrainingsgruppe, setAccessToTrainingsgruppe] = useState<string[]>([]);


	if (window.performance && performance.navigation.type === 1) {
		window.localStorage.removeItem('emailForSignIn');
	}
	useEffect(() => {
		const trace = perf.trace("getAllArticlesEveryone");
		trace.start();
		const traceEvents = perf.trace("getEventsEveryone");
		traceEvents.start();
		fetchEvents()
			.then(result => {
				setEvents(result);
				traceEvents.stop();
			})
		setTimeout(() => 
			getAllArticles()
				.then((result) => {
					setLatestArticle(result[0]);
					setNews(result);
					trace.stop();
				})
				.catch(e => {
					if (e && !e.sent)
						sendErrorToDev(e.toString(), "app.tsx:169");
					trace.stop()
				}),
			10)
	}, []);


	useEffect(() => {
		firebaseApp.auth().onAuthStateChanged(function (user_change: any) {
			if (user_change) {
				if (isUserLogedIn !== true) {
					curr_user = user_change;
					getRolleOfUser(user_change.uid)
						.then(cur_rolle => setRolle(cur_rolle));
					setUserLogedIn(true)
					if (user_change.emailVerified === false && !!user_change.email && localStorage.getItem('emailForSignIn') !== user_change.email.toString()) {
						sendVerificationEmail(user_change)
							.then(result => {
								setHassendEMail(result);
							});
					}
				}
			} else {
				window.localStorage.removeItem('emailForSignIn');
				localStorage.setItem("haveseenMsg", "false");
				setUserLogedIn(false);
				setRolle(null);
				curr_user = null;
			}
		});

	}, [])
	useEffect(() => {
		if (isUserLogedIn && curr_user !== null) {
			getPlayerDataofUser(curr_user.uid)
				.then(d => { !!d && setCustomPlayer(d); return d })
				.then((d) => setAccessToTrainingsgruppe(d.map(data => data.trainingsgruppe)))
		}
	}, [isUserLogedIn])

	useEffect(() => {
		if (rolle !== null && rolle === "admin") {
			getAllPlayers()
				.then(data => { setAllPlayer(data); return data })
		}
	}, [rolle])



	useEffect(() => {
		const locStor = localStorage.getItem("haveseenMsg");
		if (!!curr_user && isUserLogedIn === true && curr_user.emailVerified === true && (locStor === null || locStor === "false")) {
			enqueueSnackbar(`Hallo ${(curr_user.displayName === null) ? "" : curr_user.displayName}!`, { key: "loginSuccess", variant: "info" });
			localStorage.setItem("haveseenMsg", "true");
		} else if (hassendEMail) {
			enqueueSnackbar(`Wir haben ihnen eine verifizierungs E-Mail gesendet.`, { key: "sendMsg", variant: "info" });
		}
		if (hassendEMail === false) {
			enqueueSnackbar(`Wir konnten ihnen leider keine verifizierungs E-Mail senden, da sie bereits zu viele Anfragen getätigt haben. Versuchen Sie es später noch einmal`, { key: "notsendMsg", variant: "warning" });

		}
	}, [isUserLogedIn, hassendEMail]);
	useEffect(() => {
		setTimeout(() => {
			import_downloads()
				.then(d => setDocumentsPublic(d))
		}, 1000)
	}, []);

	/**
	 * hohlt von einem Spieler die Dokumente (nur im AdminModus möglich)
	 * @param pid die ID des Spielers
	 */
	async function getDocOfPlayerAddToData(pid: string): Promise<boolean> {
		const index = allPlayer?.findIndex(allPlayer => allPlayer.pid === pid) || -1;
		if (index >= 0 && allPlayer && !allPlayer[index].links) {
			const playerDoc = await getPlayerDocuments(pid);
			let player = allPlayer;
			player[index].links = playerDoc;
			setAllPlayer(player);
		}
		return true;
	}

	return (
		<div >
			<React.Fragment >
				<Toolbar id="back-to-top-anchor" />
				<div >
					{
						(!!window.screen.width && window.screen.width > 800)
							? (<MenueLeiste />)
							: (<MenueLeisteMobile />)
					}
					<div className="background-container">
						<Fade
							in
							key={location.key}
							timeout={200}
						>

							<Paper className="paper-app-style">
								<Switch location={location}>
									<Route exact path="/" >
										<Verein latestArticle={latestArticle} events={events} />
									</Route>
									<Route path="/datenschutz" component={Privacy}></Route>
									<Route path="/statut">
										<Documents rolle={rolle} documents_public={documents_public} accessToTrainingsgruppe={getAccessToTrainingsgruppe} />
									</Route>
									<Route path="/kontakt" component={Kontakt}></Route>
									<Route path="/news" >
										<News news={news} />
									</Route>
									<Route path="/spielregeln" component={Spielregeln}></Route>
									<Route path="/documents">
										<Documents rolle={rolle} documents_public={documents_public} accessToTrainingsgruppe={getAccessToTrainingsgruppe} />
									</Route>
									<Route path="/events" >
										<Events events={events} />
									</Route>
									<Route path="/anmelden" >
										(<HandleAuth type={AuthTypes.LogIn} button_name={"Anmelden"} display_component={<SignIn />} open={true} close={false} isUserLogedIn={isUserLogedIn} />)
									</Route>
									<Route path="/signup" >
										<HandleAuth type={AuthTypes.SignUp} button_name={""} display_component={<SignUp />} open={true} isUserLogedIn={isUserLogedIn} isSignUp={true} />
									</Route>
									<Route exact path="/logout" >
										<HandleAuth type={AuthTypes.LogOut} isUserLogedIn={isUserLogedIn} />
									</Route>
									<Route path="/UserInformation">
										<HandleAuth type={AuthTypes.otherAuth} display_component={<ChangeUserInformationGUI player={allPlayer} />} isUserLogedIn={isUserLogedIn} userRolle={rolle} berechtigunsStufe={[Rolle.admin]} />
									</Route>
									<Route path="/PlayerData">
										<HandleAuth type={AuthTypes.otherAuth} display_component={<PlayerDataGUI player={allPlayer} getDocOfPlayerAddToData={getDocOfPlayerAddToData} />} isUserLogedIn={isUserLogedIn} userRolle={rolle} berechtigunsStufe={[Rolle.admin]} />
									</Route>
									<Route path="/YourPlayerData">
										<HandleAuth type={AuthTypes.otherAuth} display_component={<CustomPlayerData userPlayer={customUserPlayer} />} isUserLogedIn={isUserLogedIn} userRolle={rolle} berechtigunsStufe={[Rolle.admin, Rolle.Vereinsmitglied, Rolle.creater]} />
									</Route>
									<Route path="/Snake" ><Link to="/game2"><button>Play Snake (Mobile/PC)</button></Link><Link to="/game1"><button>Play Space Game (PC)</button></Link><Link to="/game3"><button>Play Space Game (PC)</button></Link></Route>
									<Route path="/game1" component={Reacteroids}></Route>
									<Route path="/game2" component={Snake}></Route>
									<Route path="/game3" component={SpaceGame}></Route>
									<Route path="/Passwort_ändern" component={ChangePassword}></Route>
									<Route path="/contentCreator">
										<ContentCreaterPage events={events} isUserLogedIn={isUserLogedIn} userRolle={rolle} />
									</Route>
									<Route path="/Profil" >
										<HandleAuth type={AuthTypes.otherAuth} display_component={<Profil />} isUserLogedIn={isUserLogedIn} userRolle={rolle} berechtigunsStufe={[Rolle.admin, Rolle.creater, Rolle.Vereinsmitglied, Rolle.NotAuth]} />
									</Route>
									<Route path="/shop" component={Shop} ></Route>
									<Route path="/trainingsplan" >
										<HandleAuth type={AuthTypes.otherAuth} display_component={<ScheduleCalendar allPlayer={allPlayer} />} isUserLogedIn={isUserLogedIn} userRolle={rolle} berechtigunsStufe={[Rolle.admin, Rolle.creater]} />
									</Route>
									<Route component={ErrorPage}>

									</Route>
								</Switch>
							</Paper>
						</Fade>
					</div>
					<Footer />
					<CookieConsent>Diese Webseite verwendet Cookies</CookieConsent>
				</div>
				<ScrollTop>
					<Fab color="secondary" size="small" aria-label="scroll back to top">
						<KeyboardArrowUpIcon />
					</Fab>
				</ScrollTop>
			</React.Fragment>
		</div >

	);
}
