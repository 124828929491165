import { analytics, firebaseApp } from "../../App";

export async function getCalendarData(setCalendarData: (data: any) => void) {
	const ref = firebaseApp.database().ref("calendar");
	ref.on('value', (snapshot) => {
		const data = snapshot.val();
		let ret: any = [];
		Object.keys(data).forEach(key => {
			ret.push({ ...data[key], dbIndex: key, id: key });
		});
		setCalendarData(ret);
	});
}
export async function addCalendarData(title: string, priorityId: number, startDate: Date, endDate: Date, allDay: boolean, rRule: string | undefined) {
	firebaseApp.database().ref('calendar').push({
		title,
		priorityId: priorityId || 0,
		startDate: startDate.toISOString(),
		endDate: endDate.toISOString(),
		allDay,
		rRule: rRule || "",
	}).catch(err => analytics.logEvent("exception",  { ErrorMsg: err.message, ErrorCode: err.code, Located: "calendarFunctions.tsx:22" }));
}
export async function updateCalendarData(dbIndex: string, calendarItem: any) {
	firebaseApp.database().ref(`calendar/${dbIndex}`).update({
		...calendarItem,
	}).catch(err => {
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "calendarFunctions.tsx:28" });
	});;
}

export async function deleteCalendarItem(dbIndex: string) {
	firebaseApp.database().ref(`calendar/${dbIndex}`).remove().catch(err => {
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "calendarFunctions.tsx:34" });
	});;
}

export async function givePlayerAnwesendState(pid: string, eventID: string, eventstartDate: Date, endDate: Date) {
	let sdateString = new Date(eventstartDate).toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll(".", "");
	let edateString = new Date(endDate).toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll(".", "");
	const ref = firebaseApp.database().ref(`presentlyList/${eventID}${sdateString}${edateString}`);
	ref.push(pid).catch(err => {
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "calendarFunctions.tsx:43" });
	});;
}
export async function deletePlayerAnwesendState(dbIndex: string, eventID: string, eventstartDate: Date, endDate: Date) {
	let sdateString = new Date(eventstartDate).toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll(".", "");
	let edateString = new Date(endDate).toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll(".", "");
	const ref = firebaseApp.database().ref(`presentlyList/${eventID}${sdateString}${edateString}/${dbIndex}`).remove()
		.catch(err => {
			analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "calendarFunctions.tsx:51" });
		});;
}

export async function getPlayerAnwesendStates(setCalendarData: (data: any) => void, dbIndex: string, eventstartDate: Date, endDate: Date) {
	let sdateString = new Date(eventstartDate).toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll(".", "");
	let edateString = new Date(endDate).toISOString().replaceAll("-", "").replaceAll(":", "").replaceAll(".", "");
	const ref = firebaseApp.database().ref(`presentlyList/${dbIndex}${sdateString}${edateString}`);
	ref.on('value', (snapshot) => {
		const data = snapshot.val();
		if (data) {
			let ret: any = [];
			Object.keys(data).forEach(key => {
				ret.push({ playerID: data[key], dbIndex: key });
			});
			if (ret) {
				setCalendarData(ret);
			} else {
				setCalendarData([{ playerID: "", dbIndex: "" }]);
			}
		} else {
			setCalendarData([{ playerID: "", dbIndex: "" }]);
		}
	});
}
