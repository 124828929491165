import React, { useEffect, useState } from "react";
import { SportEvent } from "../model/event";
import EventFilter from "../model/filterevents/filter_events";
import "./events.scss";



interface PropsEvents {
	events: SportEvent[] | undefined;
}

export default function Events(props: PropsEvents) {
	const [events, setEvents] = useState<SportEvent[]>();
	useEffect(() => {
		setEvents(props.events);
	}, [props.events, props])
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div>
			<EventFilter events={events} id="Events" />
		</div>
	);





}
