import "./verein.scss";

import React, { useEffect, useState } from "react";

import KachelnHomepage from "../../kacheln_home_page/kacheln_home_page";
import MiniEvents from "../../mini_events/mini_events";
import { getGruppenContent } from "../../model/abfragen";
import { Article } from "../../model/article";
import { SportEvent } from "../../model/event";
import Gruppe from "../../model/gruppe";

interface VereinProps {
	events: SportEvent[] | undefined;
	latestArticle: Article | undefined;
}

export default function Verein(props: VereinProps) {
	const [infocontent, setInfocontent] = useState<Gruppe[]>([]);
	const [allEvents, setEvents] = useState<SportEvent[]>([]);
	const [latestNews, setLatestNews] = useState<Article>();
	useEffect(() => {
		getGruppenContent()
			.then((items: Gruppe[]) => setInfocontent(items));
	}, []);

	useEffect(() => {
		setLatestNews(props.latestArticle);
		setEvents(props.events || []);
	}, [props, props.latestArticle, props.events]);

	if (!allEvents || !infocontent || !infocontent[0]) {
		return (
			<div>
			</div>
		)
	} else {


		return (
			<div className="vereinsite">
				<div className="outercentercontainer" >
					<div className="start-badminton-ueberetsch-container">
						<div className="outercontainer">
							<div className="start-badminton-ueberetsch">
								<KachelnHomepage data={infocontent[0]} />
							</div>
						</div>

						<div className="kacheln_container">
							<div className="trainer-verein-gruppen-container">
								{/* Trainer */}
								{
									infocontent.map((item: Gruppe, i) => {
										return (i === 0)
											? ("")
											: (
												<div className="trainer" key={item.name+item.id}>
													<KachelnHomepage data={item} />
												</div>
											)
									})
								}
								{
									(!!latestNews)
										? (
											<div className="trainer" >
												<KachelnHomepage data_art={latestNews} isNews={true} />
											</div>
										)
										: ("")
								}

							</div>
							<div className="latestEvent-container">
								<MiniEvents events={allEvents} />
							</div>
						</div>
					</div>

				</div>
				
			</div>
		);
	}
}

