import {
	Paper, TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner";
import TableWithPaginator from "../../../model/table/tableWithPaginator";
import { Player } from "../../interfaces/player";
import PlayerConfig from "../table/player-config";
import AddNewPlayerComponent from "./addNewPlayer/AddNewPlayerComponent";
import "./playerDataPage.scss";



interface Column {
	id: 'vorname' | 'nachname' | 'icon' | 'trainingsgruppe';
	label: string;
	align?: 'right';
	format?: (value: number) => string;
}

const columns: Column[] = [
	{ id: 'vorname', label: 'Vorname' },
	{ id: 'nachname', label: 'Nachname' },
	{ id: 'trainingsgruppe', label: 'Gruppe' },

];

interface Data {
	vorname: string;
	nachname: string;
	trainingsgruppe: string;
	player: Player;
}

function createData(vorname: string, nachname: string, trainingsgruppe: string, player: Player): Data {
	return { vorname, nachname, trainingsgruppe, player };
}

const useStyles = makeStyles({
	root: {
		width: '100%',
		backgroundColor: 'white',
	}

});

interface PlayerDataProps {
	player: Player[] | undefined;
	getDocOfPlayerAddToData: (pid: string) => Promise<boolean>; //in App implementiert
}

export default function PlayerDataGUI(props: PlayerDataProps) {
	const classes = useStyles();
	const [update, setUpdate] = React.useState(false);
	const [playerData, setPlayerData] = useState<Player[]>();
	const [currPlayer, setCurrPlayer] = useState<Player>();
	const [rows, setRows] = useState<any>();
	const [filteredData, setFilteredData] = useState<Player[]>();
	const [searching_input, setSearchingInput] = useState<string>("");



	const onClick = (onClickPlayer: Player) => {
		setCurrPlayer(onClickPlayer);
	}

	const updateContent = (setCurrPlayerToNull?: boolean) => {
		if (setCurrPlayerToNull === true) {
			setCurrPlayer(undefined);
		}
		setUpdate(!update);
	}

	const addPlayerToPlayerData = (newPlayer: Player) => {
		let data = playerData;
		if (data) {
			data.push(newPlayer);
			setPlayerData(data);
		}

	}

	useEffect(() => {
		setPlayerData(props.player);
	}, [props, props.player]);


	useEffect(() => {
		if (searching_input.length !== 0 && !!playerData) {
			setCurrPlayer(undefined);
			setFilteredData(playerData.filter((single_player: Player) => single_player.nachname.toUpperCase().includes(searching_input.toLocaleUpperCase())
				|| single_player.vorname.toUpperCase().includes(searching_input.toLocaleUpperCase())
				|| single_player.full_name.toUpperCase().includes(searching_input.toLocaleUpperCase())))
		} else
			setFilteredData(playerData);
	}, [playerData, searching_input, update]);

	useEffect(() => {
		let final_rows = [];
		if (!!filteredData)
			for (let player of filteredData) {
				final_rows.push(createData(player.vorname, player.nachname, player.trainingsgruppe, player))
			}
		setRows(final_rows)
	}, [filteredData]);

	useEffect(() => {
		let final_rows = [];
		if (!!playerData)
			for (let player of playerData) {
				final_rows.push(createData(player.vorname, player.nachname, player.trainingsgruppe || "- -", player))
			}
		setRows(final_rows)
	}, [playerData, update]);


	if (!!playerData) {
		return (
			<div>
				<form className="input-text-field-playerDataPage-styles" noValidate autoComplete="off">
					<AddNewPlayerComponent addPlayerToPlayerData={addPlayerToPlayerData} />
					<TextField id="standard-basic" label="Suche einen Spieler" onChange={(e: any) => setSearchingInput(e.target.value)} />

				</form>
				<Paper className={classes.root}>
					<TableWithPaginator rows={rows} columns={columns} onClick={onClick}/>
				</Paper>
				{
					(!!currPlayer && !!playerData)
						? (
							<PlayerConfig currPlayer={currPlayer} allPlayers={playerData} update={updateContent} getDocOfPlayerAddToData={props.getDocOfPlayerAddToData}/>
						)
						: (<div></div>)
				}
			</div >
		);
	} else
		return (<div className="spinner-playerDataPage"><div ><Spinner /><p>Bitte warten. Daten werden geladen</p></div></div>)
}
