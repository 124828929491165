import React, { useEffect, useState } from "react";
import { FcCalendar } from "react-icons/fc";
import { Link } from "react-router-dom";
import EventDialog from "../event-beschreibung/event-beschreibung";
import { SportEvent } from "../model/event";
import { formatDate } from "../model/utils";
import "./mini_events.scss";


const now = new Date();
now.setDate(now.getDate() - 5);
export default function MiniEvents(event: any) {
	const [allEvents, setEvents] = useState<SportEvent[]>(event.events);
	const [filter_start_date] = useState<Date>(now);
	const getFilteredEvents = () => {
		return allEvents && allEvents.filter(
			(event: SportEvent) => (event.start_date >= filter_start_date || (event.end_date && event.end_date>= filter_start_date))
		).slice(0, (window.screen.height - 180) / 160);
	}

	useEffect(() => {
		setEvents(event.events)
	}, [event, event.events])
	return (
		<div className="homepage_events">
			<div className="navigation">
				<div className="next_event_title">
					<p>NEXT EVENTS</p>
				</div>
			</div>
			<div className="mini_event_homepage_outer_container">
				{
					(!!allEvents && allEvents !== [] && allEvents.length > 0 && getFilteredEvents().length > 0)

						? (getFilteredEvents().map((event_data: SportEvent) =>
							<div className="mini_event_home" key={event_data.id}>
								<div className="calendar_icon">
									<FcCalendar />
								</div>
								<div className="content_root">
									<div className="content_container">
										<div className="event_title">
											{event_data.event_title}
										</div>
										<div className="event_subtitle">
											{
												(!(event_data.end_date && event_data.end_date > event_data.start_date))
													? (<p>{event_data.location} / {formatDate(event_data.start_date)} / {(event_data.spielbeginn !== "") ? (event_data.spielbeginn + " / " ) : " "}{event_data.tournierart}</p>)
													: (<p>{event_data.location} / {formatDate(event_data.start_date)} - {formatDate(event_data.end_date)} / {(event_data.spielbeginn !== "") ? (event_data.spielbeginn + " / ") : " "}{event_data.tournierart}</p>)
											}
										</div>

									</div>
									<div className="tournamentSW-icon">
										{
											(event_data.event_description.__html !== "")
												? (<EventDialog data={event_data} />)
												: ("")
										}
										{
											(!!(event_data.tournament_link) && event_data.tournament_link !== "")
												? (<a href={event_data.tournament_link} target="_blank" rel="noopener noreferrer">
													<img src="iconTournamentSW.webp" alt="iconTournamentSW"/></a>)
												: ("")
										}
									</div>
								</div>
							</div>
						))
						: (
							<div className="event-container">
								<div className="event_not_found margin_event">
									<p>Zurzeit finden keine Events statt</p>
								</div>
							</div>
						)
				}
			</div>
			<div className="navigation">
				<Link to="/events#Events" className="event-link">
					<div className="next_event_title">
						<p>MORE EVENTS</p>
					</div>
				</Link>
			</div>
		</div>
	)
}
