import "./menupointsDrawer.scss";

import { Button, Collapse, List, ListItem, ListItemIcon } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import WebIcon from "@material-ui/icons/Web";
import firebase from "firebase";
import React, { useState } from "react";
import { AiFillFileText, AiFillHome, AiFillInfoCircle, AiOutlineSmile } from "react-icons/ai";
import { BsNewspaper } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { GiShuttlecock } from "react-icons/gi";
import { GrDocumentText, GrSchedules } from "react-icons/gr";
import { RiCalendarEventLine, RiFolderUserLine } from "react-icons/ri";
import { Link } from "react-router-dom";

import { firebaseApp } from "../../App";
import { Rolle } from "../../authentication_required/interfaces/rollen";
import {
    getRolleOfUser,
    sendVerificationEmail
} from "../../authentication_required/table-functions/table-user-functions";



// import { SiAboutDotMe } from "react-icons/si";
interface MenueItem {
	title: string;
	path?: string;
}

export function getItem(menuItem: MenueItem): JSX.Element {
	if (menuItem.title === "Home")
		return <AiFillHome />;
	if (menuItem.title === "Shop")
		return  <ShoppingCartIcon />;
	if (menuItem.title === "News")
		return <BsNewspaper />;
	if (menuItem.title === "Events")
		return <RiCalendarEventLine />;
	if (menuItem.title === "Documents")
		return <AiFillFileText />;
	if (menuItem.title === "Spielregeln")
		return <GiShuttlecock />;
	if (menuItem.title === "Infos")
		return <AiFillInfoCircle />;
	return <AiFillHome />;
}

export default function MenuPointsDrawer(menueItems: any) {
	const [open, setOpen] = useState(false);
	const [userLogedIn, setUserLogedIn] = useState<boolean>();
	const [rolle, setRolle] = useState<Rolle | null>(null);
	const [currUser, setCurrUser] = useState<firebase.User | null>(null);

	firebaseApp.auth().onAuthStateChanged(function (user_change) {
		if (user_change) {
			if (!userLogedIn)
				setUserLogedIn(true);
			if (currUser === null) {
				getRolleOfUser(user_change.uid)
					.then(cur_rolle => setRolle(cur_rolle));
			}
			setCurrUser(user_change);
		} else {
			setCurrUser(null);
			setUserLogedIn(false);
			setRolle(null);
		}
	});

	function generateAuthItems() {
		if (!userLogedIn)
			return (<div>
				<Link to="/anmelden" className="menu-items-mobile" onClick={toggleDrawer(false)}>
					< ListItem button >
						<ListItemIcon>
							<AccountCircle />
						</ListItemIcon>
						<ListItemText>Anmelden</ListItemText>
					</ListItem>
				</Link>
				<Link to="/signup" className="menu-items-mobile" onClick={toggleDrawer(false)}>
					< ListItem button >
						<ListItemIcon>
							<PersonAddIcon />
						</ListItemIcon>
						<ListItemText>Registrieren</ListItemText>
					</ListItem>
				</Link>
			</div>);
		return (
			<div>
				< ListItem button onClick={handleClick} >
					<ListItemIcon>
						<AiOutlineSmile />
					</ListItemIcon>
					<ListItemText>Hallo {currUser?.displayName}</ListItemText>
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem >
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding className="styles-get-specific-elements">
						{getListforAspecificRole()}
					</List>
				</Collapse>
			</div>
		)
	}

	const getListforAspecificRole = () => {

		const user_profil = (
			< Link to="/Profil" >
				<ListItem button onClick={toggleDrawer(false)}>
					<ListItemIcon>
						<AccountCircle />
					</ListItemIcon>
					<ListItemText primary="Profil" />
				</ListItem>
			</Link >);
		const user_bearbeiten = (
			< Link to="/UserInformation" >
				<ListItem button onClick={toggleDrawer(false)}>
					<ListItemIcon>
						<FaUserEdit />
					</ListItemIcon>
					<ListItemText primary="User bearbeiten" />
				</ListItem>
			</Link >);

		const spieler_bearbeiten = (
			<Link to="/PlayerData">
				<ListItem button onClick={toggleDrawer(false)} >
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary="Spieler bearbeiten" />
				</ListItem>
			</Link>
		);
		const trainingsplan = (
			<Link to="/trainingsplan">
				<ListItem button onClick={toggleDrawer(false)} >
					<ListItemIcon>
						<GrSchedules />
					</ListItemIcon>
					<ListItemText primary="Trainingsplan" />
				</ListItem>
			</Link>
		);

		const send_verification_mail = (
			<div onClick={() => !!currUser && sendVerificationEmail(currUser)}>
				<ListItem button onClick={toggleDrawer(false)} >
					<ListItemIcon>
						<PeopleIcon />
					</ListItemIcon>
					<ListItemText primary="Sende erneut eine Verifizierungs-E-Mail" />
				</ListItem>
			</div>
		)

		const webseite_bearbeiten = (
			<Link to="/contentCreator">
				<ListItem button onClick={toggleDrawer(false)}>
					<ListItemIcon>
						<WebIcon />
					</ListItemIcon>
					<ListItemText primary="Webseiteninhalt bearbeiten" />
				</ListItem>
			</Link>
		);

		const dokumente = (
			<Link to="/documents">
				<ListItem button onClick={toggleDrawer(false)} >
					<ListItemIcon>
						<GrDocumentText />
					</ListItemIcon>
					<ListItemText primary="Dokumente" />
				</ListItem>
			</Link>
		);
		const spielerdaten_bearbeiten = (
			<Link to="/YourPlayerData">
				<ListItem button onClick={toggleDrawer(false)} >
					<ListItemIcon>
						<RiFolderUserLine />
					</ListItemIcon>
					<ListItemText primary="Spielerdaten" />
				</ListItem>
			</Link>
		);

		const abmelden = (
			<Link to="/logout">
				<ListItem button onClick={toggleDrawer(false)} >
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Abmelden" />
				</ListItem>
			</Link>
		);
		if(!!currUser && currUser.emailVerified === false) {
			return (
				<div>
					{user_profil}
					{send_verification_mail}
					{abmelden}
				</div>
			)
		}
		if (rolle === Rolle.admin) {
			return (
				<div >
					{user_profil}
					{user_bearbeiten}
					{spieler_bearbeiten}
					{webseite_bearbeiten}
					{dokumente}
					{spielerdaten_bearbeiten}
					{trainingsplan}
					{abmelden}
				</div>)
		}
		if (rolle === Rolle.creater) {
			return (
				<div>
					{user_profil}
					{webseite_bearbeiten}
					{spielerdaten_bearbeiten}
					{dokumente}
					{trainingsplan}
					{abmelden}
				</div>)
		}
		if (rolle === Rolle.Vereinsmitglied) {
			return (
				<div>
					{user_profil}
					{spielerdaten_bearbeiten}
					{dokumente}
					{abmelden}
				</div>)
		}
		return (
			<div>
				{user_profil}
				{abmelden}
			</div >
		)
	}


	function generateMenueItem(menueItems: MenueItem[]): JSX.Element[] {
		return menueItems.map(
			item => (
				<Link to={`/${item.path}`} className="menu-items-mobile" onClick={toggleDrawer(false)}>
					<ListItem button >
						<ListItemIcon>
							{getItem(item)}
						</ListItemIcon>
						<ListItemText>{item.title}</ListItemText>
					</ListItem>
				</Link>
			)
		)
	}

	const [state, setState] = React.useState({
		left: false,
	});

	const toggleDrawer = (open: boolean) => (
		event: React.KeyboardEvent | React.MouseEvent,
	) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' ||
				(event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setState({ ...state, 'left': open });
	};

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<div>
			<React.Fragment key={'left'}>
				<Button onClick={toggleDrawer(true)}>
					<MenuIcon />
				</Button>
				<Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer(false)}>
					<Divider />
					<div className="mobile-text">
						<List className="mobile_items">
							{generateMenueItem(menueItems)}
							<Divider />
							{generateAuthItems()}

						</List>
						{/* <Divider />
						<div className="follow_outer_ontainer">
							<div className="follow_container">
								<a href="https://it-it.facebook.com/BadmintonUeberetsch/" target="_blank" >
									<div className="company-logo">
										<GrFacebookOption size="35px" color="black" />
									</div>
								</a>
								<a href="https://www.instagram.com/ueberetsch.badminton/?hl=de" target="_blank" >
									<div className="company-logo">
										<TiSocialInstagram size="35px" color="black" />
									</div>
								</a>
							</div>
						</div> */}
						<Divider />
					</div>
					<div className="sponsored_by">
						<div className="sponsored_by_content">
							<p>powered by</p>
							<a href="https://www.asahotel.com/" target="_blank"  rel="noopener noreferrer">
								<img src="MAIN-LOGO.webp" alt="ASA-Logo"/>
							</a>
						</div>
					</div>
					{/* <Divider /> */}
				</Drawer>
			</React.Fragment>
		</div >
	);
}


