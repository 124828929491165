import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Spinner from "../../components/spinner";
import { getPlayerDataofUser } from "../Authentication/user-functions";
import { Player } from "../interfaces/player";
import { deleteEmailFromAPlayer } from "../table-functions/table-player-functions";
import {
	addreferencebetUserPlayer,
	deletereferencebetUserPlayer
} from "../table-functions/table-user-player-functions";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: 'auto',
		},
		paper: {
			width: 200,
			height: 230,
			overflow: 'auto',
		},
		button: {
			margin: theme.spacing(0.5, 0),
		},
	}),
);

function not(a: Player[], b: Player[]) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Player[], b: Player[]) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

export default function PlayerTransferList(props: any) {
	const classes = useStyles();
	const [all_player] = useState<Player[]>(props.allPlayers);
	const [playersOfUser, setPlayersofUser] = useState<Player[]>([]);
	const [checked, setChecked] = React.useState<Player[]>([]);
	const [left, setLeft] = React.useState<Player[]>([]);
	const [right, setRight] = React.useState<Player[]>();
	const [searching_input_left, setSearchingInputLeft] = useState<string>("");
	const [searching_input_right, setSearchingInputRight] = useState<string>("");
	const { enqueueSnackbar } = useSnackbar();


	useEffect(() => {
		setRight(undefined);
		getPlayerDataofUser(props.uid)
			.then(data => { setPlayersofUser(data) })
	}, [props.uid]);


	useEffect(() => {
		setLeft(all_player.filter(player => !playersOfUser?.find(d => d.pid === player.pid)));
		setRight(all_player.filter(player => playersOfUser?.find(d => d.pid === player.pid)));
	}, [playersOfUser, all_player]);


	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right || []);

	const handleToggle = (value: Player) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};


	async function handleCheckedRight() {
		if (right) {
			for (let add_p of leftChecked) {
				if (! await addreferencebetUserPlayer(props.uid, add_p.pid, add_p, props.email)) {
					enqueueSnackbar(`Fehler bei der Zuordnung ab den Spieler ${add_p.full_name}`, { key: "errorAddPlayer", variant: "error" });
					break;
				}
			}
			enqueueSnackbar(`Spieler wurden erfolgreich zugeordnet`, { key: "successAddPlayer", variant: "success" });
			setRight(right.concat(leftChecked));
			setLeft(not(left, leftChecked));
			setChecked(not(checked, leftChecked));
		}
	};

	async function handleCheckedLeft() {
		if (right) {
			for (let add_p of rightChecked) {
				if (!await deletereferencebetUserPlayer(props.uid, add_p.pid) || !await deleteEmailFromAPlayer(add_p, [props.email])) {
					enqueueSnackbar(`Fehler beim Löschen ab den Spieler ${add_p.full_name}`, { key: "errorDeletePlayer", variant: "error" });
					break;
				}
			}
			enqueueSnackbar(`Spieler wurden erfolgreich zugeordnet`, { key: "successAddPlayer", variant: "success" });
			setLeft(left.concat(rightChecked));
			setRight(not(right, rightChecked));
			setChecked(not(checked, rightChecked));
		}
	};

	useEffect(() => {
		setLeft(left.filter(player => player.full_name?.toUpperCase().includes(searching_input_left.toUpperCase())))
		if (searching_input_left.length === 0)
			setLeft(all_player.filter(player => !playersOfUser?.find(d => d.pid === player.pid)));
	}, [searching_input_left])

	useEffect(() => {
		if (right)
			setRight(right.filter(player => player.full_name?.toUpperCase().includes(searching_input_right.toUpperCase())))
		if (searching_input_right.length === 0)
			setRight(all_player.filter(player => playersOfUser?.find(d => d.pid === player.pid)));
	}, [searching_input_right])


	const customList = (items: Player[]) => (
		<Paper className={classes.paper}>
			<List dense component="div" role="list">
				{items.map((value: Player) => {
					const labelId = `transfer-list-item-${value.pid}-label`;

					return (
						<ListItem key={value.pid} role="listitem" button onClick={handleToggle(value)}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value.full_name || " "} />
						</ListItem>
					);
				})}
				<ListItem />
			</List>
		</Paper>
	);

	if (right === undefined) {
		return <div className="SpinnerPlayerTransferList">
			<Spinner />
		</div>
	}

	return (
		<div className={classes.root}>
			< Grid container spacing={2} justify="center" alignItems="center"  >
				<div>
					<form className={classes.root} noValidate autoComplete="off">
						<TextField id="standard-basic" label="Suche einen Spieler" onChange={(e) => setSearchingInputLeft(e.target.value)} />
					</form>
					<Grid item>{customList(left)}</Grid>
				</div>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={handleCheckedRight}
							disabled={leftChecked.length === 0}
							aria-label="move selected right"
						>
							&gt;
						</Button>
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={handleCheckedLeft}
							disabled={rightChecked.length === 0}
							aria-label="move selected left"
						>
							&lt;
						</Button>
					</Grid>
				</Grid>
				<div>
					<form className={classes.root} noValidate autoComplete="off">
						<TextField id="standard-basic" label="Suche einen Spieler" onChange={(e) => setSearchingInputRight(e.target.value)} />
					</form>
					<Grid item>{customList(right)}</Grid>
				</div>
			</Grid >
		</div>
	);
}
