import "./filter_events.scss";
import "date-fns";

import DateFnsUtils from "@date-io/date-fns";
import { FormControl, InputBase, MenuItem, Select, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";

import { curr_user } from "../../authentication_required/Authentication/user-functions";
import MiniEventsMobile from "../../mini_events_mobile/mini_events_mobile";
import SportObjectToCSVButton from "../../sportObjectToCSV/SportObjectToCSVButton";
import { betweenADate, SportEvent } from "../event";
import { event_category_array } from "../html-generator";
import Spinner from "../../components/spinner";

const numWeeks = 20;
const now = new Date();
now.setDate(now.getDate() + numWeeks * 7);
let date = new Date();
date.setDate(date.getDate() - 7);


export default function EventFilter(data: any) {
	//Categories
	const [filter_category, setFilter_category] = useState<string>();
	const [allEvents, setEvents] = useState<SportEvent[]>([]);
	const [filteredEvents, setFilteredEvents] = useState<SportEvent[]>([]);
	const handleChange = (event: any) => setFilter_category(event.target.value);
	//Date
	const [filter_start_date, setFilterStartDate] = useState<Date>(date);
	const [filter_end_date, setFilterEndDate] = useState<Date>(now);
	const handleChangeStartDate = (date: any) => {
		if (date !== null)
			setFilterStartDate(date);
	}
	const handleChangeEndDate = (date: any) => {
		if (date !== null)
			setFilterEndDate(date);
	}

	//GetFiltertEvents
	const getFilteredEvents = () => {
		let filterEvents = allEvents && allEvents.filter(
			(event: SportEvent) => (!filter_category || filter_category === "allEvents" || event.tournierart === filter_category)
				&& (
					betweenADate(event.start_date, filter_start_date, filter_end_date)
					|| betweenADate(event.end_date || event.start_date, filter_start_date, filter_end_date)
				)
		);
		setFilteredEvents(filterEvents);
	}

	useEffect(() => {
		setEvents(data.events)
	}, [data, data.events]);

	useEffect(() => {
		getFilteredEvents();
	}, [filter_start_date, filter_end_date, filter_category, allEvents])

	return (
		<div>
			<div className="filter-container-events" id="event">
				<div className="filter-options">
					<div className="filter">
						<div className="filter_category">
							<FormControl>
								<Select labelId="select-label" value={filter_category ? filter_category : "allEvents"} onChange={(value) => handleChange(value)} input={<BootstrapInput />}>
									<MenuItem value="allEvents" selected>Alle Events anzeigen</MenuItem>
									{
										event_category_array.map(filter_type =>
											<MenuItem key={filter_type} value={filter_type}>{filter_type}</MenuItem>
										)
									}
								</Select>
							</FormControl>

						</div>
						<div className="date-container-root">
							<div className="date_container">
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Grid container justify="space-around">
										<KeyboardDatePicker
											focused={false}
											autoOk
											disableToolbar
											variant="inline"
											format="dd.MM.yyyy"
											margin="normal"
											id="date-picker-inline"
											label="Startdatum"
											value={filter_start_date}
											onChange={(value: any) => handleChangeStartDate(value)}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>
									</Grid>
								</MuiPickersUtilsProvider>
							</div>
							<div className="date_container">
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Grid container justify="space-around">
										<KeyboardDatePicker
											focused={false}
											autoOk
											disableToolbar
											variant="inline"
											format="dd.MM.yyyy"
											margin="normal"
											id="date-picker-inline"
											label="Enddatum"
											value={filter_end_date}
											onChange={(value: any) => handleChangeEndDate(value)}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>
									</Grid>
								</MuiPickersUtilsProvider>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="event-container-root">

				<div className="event-container">
					{
						(!allEvents)
							? (<Spinner></Spinner>)
							: (<div />)
					}

					{filteredEvents?.map(e => (
						<div key={e.event_title}>
							<MiniEventsMobile event={e} />
						</div>
					))}
					{
						(!!filteredEvents && filteredEvents.length === 0)
							? (
								<div className="event_not_found event_not_found_width">
									<p>No Event found</p>
								</div>
							)
							: ("")
					}
				</div>
			</div>
			{
				(!!filteredEvents && filteredEvents.length === 0 && curr_user === null)
					? ("")
					: (
						<div className="eventsDownload-container">
							<SportObjectToCSVButton events={filteredEvents} ></SportObjectToCSVButton>
							{/* <Button className="eventsDownload-button" onClick={() => SportObjectToCSVButton()}><GetAppIcon></GetAppIcon></Button> */}
						</div>
					)
			}

		</div>
	);
}






const BootstrapInput = withStyles((theme) => ({

	input: {
		height: '26px',
		marginleft: '40px',
		borderRadius: 4,
		border: '1px solid #ced4da',
		fontSize: 15,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		display: 'flex',
		alignItems: 'center',

		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',

		},
	},
}))(InputBase);
