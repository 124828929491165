import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import React, { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { Article } from "../model/article";
import { generateImagesForArticles } from "../model/html-generator";
import "./popupwindow_news.scss";


export default function ScrollDialogNews(props: any) {
	let styles;
	let style_dialog_content;
	const [open, setOpen] = useState(false);
	const [data, setData] = useState<Article>(props.info_data);
	const [windowSize, setWindowSize] = useState<number>();
	let fullSizeAtribute: boolean = false;



	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = useRef<HTMLElement>(null);
	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
		setData(props.info_data)

		function handleResize() {
			setWindowSize(window.screen.width);
		}

		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);

	}, [open, props]);

	if (!!windowSize && windowSize < 800) {
		fullSizeAtribute = true;
	}


	if (data.content.__html === "") {
		styles = "left-dialog-content left_width_max_container";
	} else
		styles = "left-dialog-content";

	if (!!props.info_data.image_urls && props.info_data.image_urls.length > 0 && props.info_data.image_urls[0] != null) {
		style_dialog_content = "scroll-dialog-content dialog-content-width";
	} else
		style_dialog_content = "scroll-dialog-content dialog-content-autoSize";

	return !!data ? (
		<div className="newsContent-scrollDialog">
			<Button onClick={handleClickOpen}>
				{(props.html)}
			</Button>
			<Dialog fullScreen={fullSizeAtribute}
				open={open}
				onClose={handleClose}
				aria-describedby="scroll-dialog-description"
				className="dialog-styles"
			>
				<div className="closeicon-news">
					<p>{data.title}</p>
					<Button onClick={handleClose} color="primary">
						<GrFormClose className="icon" />
					</Button>
				</div>
				<div className="dialog-container">
					{
						(!!props.info_data.image_urls && props.info_data.image_urls.length > 0 && props.info_data.image_urls[0] != null)
							? (
								<div className={styles}>
									<div className="scroll-dialog-image">
										{generateImagesForArticles(props.info_data.image_urls)}
									</div>
								</div>
							)
							: (<div />)
					}

					{
						(data.content.__html !== "")
							? (
								<div className="right-dialog-content">

									<DialogContent dividers={true}>
										<DialogContentText
											id="scroll-dialog-description"
											ref={descriptionElementRef}
											tabIndex={-1}
										>
											<div className={style_dialog_content} dangerouslySetInnerHTML={data && data.content}></div>

										</DialogContentText>
									</DialogContent>
								</div>
							)
							: (<div />)

					}

				</div>
				{
					(!isNaN(new Date(data.created_time).getTime()))
						? (<div className="erstelltDate closeicon-news">
							<p>Erstellt am {new Date(data.created_time).toLocaleDateString()}</p>
						</div>)
						: ("")
				}
			</Dialog>
		</div>
	) : (<div></div>);
}


