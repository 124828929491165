import "./menuUser.scss";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AccountCircle from "@material-ui/icons/AccountCircle";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { firebaseApp } from "../../App";
import AuthDialog from "../../authentication_required/Authentication/auth-popup/auth-popup";
import SignIn from "../../authentication_required/Authentication/signin-component/signIn";
import { Rolle } from "../../authentication_required/interfaces/rollen";
import {
    getRolleOfUser,
    sendVerificationEmail
} from "../../authentication_required/table-functions/table-user-functions";



export default function MenuListUser() {
	const [open, setOpen] = React.useState(false);
	const [userLogedIn, setUserLogedIn] = useState<boolean>();
	const [rolle, setRolle] = useState<Rolle | null>(null);
	const [currUser, setCurrUser] = useState<firebase.User | null>(null);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	useEffect(() => {

		firebaseApp.auth().onAuthStateChanged(function (user_change) {
			if (user_change) {
				if (!userLogedIn)
					setUserLogedIn(true);
				if (currUser === null) {
					getRolleOfUser(user_change.uid)
						.then(cur_rolle => setRolle(cur_rolle));
				}
				setCurrUser(user_change);
			} else {
				setCurrUser(null);
				setUserLogedIn(false);
				setRolle(null);
			}
		});
	}, [])


	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};


	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	const getListforAspecificRole = (curr_rolle: Rolle | null) => {
		const user_bearbeiten = (
			< Link to="/UserInformation" >
				<MenuItem onClick={handleClose}>User bearbeiten</MenuItem>
			</Link >
		)
		const spieler_bearbeiten = (
			<Link to="/PlayerData">
				<MenuItem onClick={handleClose}>Spieler bearbeiten</MenuItem>
			</Link>
		)

		const spielerdaten = (
			<Link to="/YourPlayerData">
				<MenuItem onClick={handleClose}>Spielerdaten</MenuItem>
			</Link>
		)
		const send_verification_mail = (
			<div onClick={() => !!currUser && sendVerificationEmail(currUser)}>
				<MenuItem onClick={handleClose}>Bestätigungsmail senden</MenuItem>
			</div>
		)

		const webseite_bearbeiten = (
			<Link to="/contentCreator">
				<MenuItem onClick={handleClose}>Webseite bearbeiten</MenuItem>
			</Link>
		)
		const trainingsplan = (
			<Link to="/trainingsplan">
				<MenuItem onClick={handleClose}>Trainingsplan</MenuItem>
			</Link>
		)
		const abmelden = (
			<Link to="/logout">
				<MenuItem onClick={handleClose}>Abmelden</MenuItem>
			</Link>
		)
		const dokumente = (
			<Link to="documents">
				<MenuItem onClick={handleClose}>Dokumente</MenuItem>
			</Link>
		)
		const user_profil = (
			<Link to="/Profil">
				<MenuItem onClick={handleClose}>Profil</MenuItem>
			</Link>
		)

		if (curr_rolle === Rolle.admin) {
			return (
				<div>
					{user_profil}
					{user_bearbeiten}
					{spieler_bearbeiten}
					{webseite_bearbeiten}
					{dokumente}
					{spielerdaten}
					{trainingsplan}
					{abmelden}
				</div>)
		}
		if (curr_rolle === Rolle.creater) {
			return (
				<div>
					{user_profil}
					{webseite_bearbeiten}
					{dokumente}
					{spielerdaten}
					{trainingsplan}
					{abmelden}
				</div>)
		}
		if (curr_rolle === Rolle.Vereinsmitglied) {
			return (
				<div>
					{user_profil}
					{dokumente}
					{spielerdaten}
					{abmelden}
				</div>
			)
		}
		if (curr_rolle === Rolle.NotAuth) {
			return (
				<div>
					{user_profil}
					{send_verification_mail}
					{abmelden}
				</div>
			)
		}
		if (curr_rolle == null) {
			return (
				<Link to="/signup">
					<MenuItem onClick={handleClose}>Registrieren</MenuItem>
				</Link>
			)
		}
	}

	// return focus to the button when we transitioned from !open -> open

	return (
		<div >
			<Button
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"

			>
				{
					(!userLogedIn)
						? (<AuthDialog
							button_name={<div
								onMouseEnter={() => setOpen(true)}
								onMouseLeave={() => setOpen(false)}>
								Anmelden
							</div>}
							component={<SignIn />}
							onMouseLeave={() => setOpen(false)}
							onClick={() => setOpen(false)}
							function={setOpen}
						/>)
						: (<AccountCircle className="menu-user-icon"
							onMouseEnter={() => setOpen(true)}
							onMouseLeave={() => setOpen(false)}
						/>)
				}

			</Button>
			<Popper onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<ClickAwayListener onClickAway={() => { }}>
						<Grow
							{...TransitionProps}
							style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
						>
							<Paper>
								<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
									{getListforAspecificRole(rolle)}
								</MenuList>
							</Paper>
						</Grow>
					</ClickAwayListener>
				)}
			</Popper>
		</div>);
}
