import React from "react";
import { AiFillFilePdf, AiFillFolder, AiOutlineLink, AiTwotoneFileText } from "react-icons/ai";
import { FaFilePowerpoint } from "react-icons/fa";
import { RiFileExcel2Line, RiFileWord2Line } from "react-icons/ri";

import { DocumentLink } from "../authentication_required/interfaces/player";
import Slideshow from "../components/slide_show/slideshow";
import { Article } from "./article";



export enum EventCategory {
	MANNSCHAFT = "Mannschaftsspiel",
	INTER_TURNIER = "Internationales Turnier",
	INTER_TURNIER_UNDER = "Internationales Turnier Under",
	ITAL_MEISTER = "Italienmeisterschaft",
	ITAL_MEISTER_MASTER = "Italienmeisterschaft Master",
	ITAL_MEISTER_UNDER = "Italienmeisterschaft Under",
	LANDES_MEISTER = "Landesmeisterschaft",
	LANDES_MEISTER_UNDER = "Landesmeisterschaft Under",
	NATIONALES = "Nationales Turnier",
	VSS_JUGEND = "VSS Jugendcup",
	VSS_KINDER = "VSS Kinderolympiade",
	VSS_MANNSCHAFT = "VSS Mannschaftsturnier",
	MANNSCHAFTS_OVER = "Mannschaftsturnier Over",
	VSS_FAMILY = "VSS Familytrophy",
	KURS = "Kurse",
	CAMPS = "Camps",
	SONSTIGES = "Sonstiges",
}

export const event_category_array: string[] = [
	EventCategory.MANNSCHAFT,
	EventCategory.INTER_TURNIER,
	EventCategory.INTER_TURNIER_UNDER,
	EventCategory.ITAL_MEISTER,
	EventCategory.ITAL_MEISTER_MASTER,
	EventCategory.ITAL_MEISTER_UNDER,
	EventCategory.LANDES_MEISTER,
	EventCategory.LANDES_MEISTER_UNDER,
	EventCategory.NATIONALES,
	EventCategory.VSS_JUGEND,
	EventCategory.VSS_KINDER,
	EventCategory.VSS_MANNSCHAFT,
	EventCategory.MANNSCHAFTS_OVER,
	EventCategory.VSS_FAMILY,
	EventCategory.KURS,
	EventCategory.CAMPS,
	EventCategory.SONSTIGES,
]


export function generateLatestNews(latestArticle: Article) {
	return (
		<div className="latest-news-container">
			<div className="title">
				<h2>{latestArticle.title}</h2>
			</div>
			<div className="latest-preview">
				{getImageContent(latestArticle.image_urls[0], latestArticle.content_short)}
			</div>
		</div>
	)
}


function getImageContent(image_url: string, content_short: any) {
	if (!!image_url) {
		return (
			<div className="image">
				<img src={image_url} alt="NewsImage" />
				<div className="content" dangerouslySetInnerHTML={(content_short)}></div>
			</div>
		)
	} else
		if (!!content_short) {
			return (
				<div dangerouslySetInnerHTML={(content_short)}></div>
			);
		} else
			return (
				<div className="image">
					<img src={image_url} alt="NewsImage" />
				</div>
			)
}


export function generateDownloads(downloadItems: DocumentLink[]) {
	if (!downloadItems || !downloadItems.length)
		return <div></div>

	return downloadItems.map(
		doc => (
			<div className="download_container">
				<a href={doc.url} target="_blank" rel="noopener noreferrer">

					<div className="file">
						{generateDownloadIcon(doc.type)}
					</div>
					<div className="footer-file">
						<p>{doc.name}</p>
					</div>
				</a>
			</div>
		));
}

export function generateImagesForArticles(images: string[]) {
	if (!!images) {

		if (images.length <= 1)
			return (<div className="single_image ">
				<img src={images[0]} alt="NewsImage" /></div>);
		else {
			return <Slideshow images={images} />
		}

	}

}

export function generateDownloadIcon(type: string, size: number = 50) {

	const size_px = `${size}px`;

	if (type.includes("pdf")) {
		return (
			<AiFillFilePdf size={size_px} color="grey" />
		);
	}
	if (type.includes("word")) {
		return (
			<RiFileWord2Line size={size_px} color="grey" />
		);
	}
	if (type.includes("excel")) {
		return (
			<RiFileExcel2Line size={size_px} color="grey" />
		);
	}

	switch (type) {
		case "xlsx":
			return (
				<RiFileExcel2Line size={size_px} color="grey" />
			);
		case "csv":
			return (
				<RiFileExcel2Line size={size_px} color="grey" />
			);
		case "pptx":
			return (
				<FaFilePowerpoint size={size_px} color="grey" />
			);
		case "link":
			return (
				<AiOutlineLink size={size_px} color="grey" />
			);
		case "folder":
			return (
				<AiFillFolder size={size_px} color="grey" />
			);
		default:
			return (
				<AiTwotoneFileText size={size_px} color="grey" />
			);
	}
}


