import "./change_password.scss";

import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { changeYourPasswort } from "../authentication_required/Authentication/user-functions";


export default function ChangePassword() {
	const { enqueueSnackbar } = useSnackbar();
	interface State {
		amount: string;
		password: string;
		weight: string;
		weightRange: string;
		showPassword: boolean;
	}
	const [values, setValues] = React.useState<State>({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
	});
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const [newPassword, setNewPassword] = useState<string>("");
	const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("");

	function checkPasswords() {
		if (newPassword !== newPasswordRepeat) {
			enqueueSnackbar(`Die Passwörter stimmen nicht überein`, { key: "PasswordNotEqual", variant: "error" });
		}
		else if (newPassword.length < 6) {
			enqueueSnackbar(`Dein Passwort muss mindestens 6 Zeichen lang sein`, { key: "errorToShort", variant: "info" });
		}
		else {
			changeYourPasswort(newPasswordRepeat)
				.then(result => (

					(result === null || result === false)
						? (enqueueSnackbar(`Ein unbekannter Fehler ist aufgetreten.`, { key: "ErrorPasswordChange", variant: "error" }))
						: (enqueueSnackbar(`Dein Passwort wurde erfolgreich geändert`, { key: "ChangePasswordSuccess", variant: "success" }))
				))
		}
	}


	return <div className="outer-container">

		<h1>Passwort ändern</h1>
		<div>
			<form noValidate autoComplete="off">
				<div className="passwortInput-container">
					<div className="passwort-inner-container">
						<FormControl className="input-field" variant="outlined" >
							<InputLabel htmlFor="outlined-adornment-password" className="input-field">neues Passwort</InputLabel>
							<OutlinedInput
								onChange={(e) => setNewPassword(e.target.value)}
								id="outlined-adornment-password"
								type={values.showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								labelWidth={70}
							/>
						</FormControl>
					</div>
					<div className="passwort-inner-container">
						<FormControl className="input-field" variant="outlined" >
							<InputLabel htmlFor="outlined-adornment-password" className="input-field">Passwort wiederholen</InputLabel>
							<OutlinedInput
								onChange={(e) => setNewPasswordRepeat(e.target.value)}
								id="outlined-adornment-password"
								type={values.showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{values.showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								labelWidth={70}
							/>
						</FormControl>
					</div>
					<div className="savePasswordButton-container">

						<Button
							onClick={(event) => checkPasswords()}
							variant="contained"
							color="primary"
							size="small"
							className="savePasswordButton"
							startIcon={<SaveIcon />}
						>Sichern
						</Button>
					</div>
				</div>
			</form>
		</div>
	</div>
}
