import "./menue-leiste-mobile.scss";

import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MenuPointsDrawer from "./menu-points-drawer/menueleistemobile_drawer";



interface MenueItem {
	title: string;
	path?: string;
}


export default function MenueLeisteMobile(data: any) {

	const defaultItems: MenueItem[] = [
		{ title: "Home", path: "" },
		{ title: "Events", path: "events" },
		{ title: "Documents", path: "documents" },
		{ title: "News", path: "news" },
		{title: "Spielregeln", path: "spielregeln"},
		{ title: "Shop", path: "shop" },
	];
	const [menueItems, setMenueItems] = useState<MenueItem[]>(defaultItems);

	async function getMenueItems() {
		setMenueItems(defaultItems);
	}

	useEffect(() => {
		getMenueItems();
	}, []);


	return (
		<div className="menueleiste-outer-container">
			<AppBar position="static">
				<Toolbar>
					<IconButton edge="start" color="inherit" aria-label="menu" name="MenuleisteMobile">
						{MenuPointsDrawer(menueItems)}
					</IconButton>
					<span>Badminton Überetsch</span>
					<Link to="/" className="logo-mobile" >
						<img src="../logo512.webp" alt="Logo Badminton Überetsch" />
					</Link>
				</Toolbar>
			</AppBar>
		</div>
	);
}


