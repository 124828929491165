import firebase from "firebase";
import { analytics, firebaseApp } from "../../App";
import { Player } from "../interfaces/player";
import { addEmailToAPlayer } from "./table-player-functions";

/**
 * fügt in die BenutzerSpieler Tabelle eine Player-ID zu einer User-ID hinzu,
 * falls diese nicht bereits vorhanden ist.
 * Sollte kein Dokument bereits bestehen, wird ein neues erstellt
 * @param uid
 * @param pid
 * @return false, sollte ein Fehler auftreten, ansonsten true
 */
export async function addreferencebetUserPlayer(uid: string, pid: string, player: Player, email: string): Promise<boolean> {
	let db = firebase.firestore(firebaseApp);
	let docRef = db.collection("BenutzerSpieler").doc(uid);
	if ((await docRef.get()).exists) {
		let dataisntintoArray = await docRef.get().then(data => data.data()?.Spieler)
			.then((player_data: string[]) => (!player_data.includes(pid)))
			.catch((err: any) => false);
		if (dataisntintoArray) {
			return docRef.get().then(data => data.data()?.Spieler)
				.then((player_data) => docRef.update({
					Spieler: [...player_data, pid]
				}))
				.then(() => {
					addEmailToAPlayer(player,[email]);
					return true})
				.catch((err: any) => {
					analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-player-functions.tsx:30" });
					return false});
		} else
			return true;
	} else
		return docRef.set({
			Spieler: [pid]
		})
			.then(() => {
				addEmailToAPlayer(player,[email])
				return true;
			})
			.catch(e => false);
}

/**
 * löscht in der BenutzerSpieler Tabelle einen Spieler mit der Spieler-ID pid aus dem User mit der User-ID uid heraus
 * @param uid die User ID
 * @param pid die ID de zu hinzufügenden Spielers
 * @returns true, falls erfolgreich oder nicht vorhanden, ansonsten false
 */
export async function deletereferencebetUserPlayer(uid: string, pid: string): Promise<boolean> {
	let db = firebase.firestore(firebaseApp);
	let docRef = db.collection("BenutzerSpieler").doc(uid);
	return docRef.get().then(data => data.data()?.Spieler)
		.then((player_data: string[]) => docRef.update({
			Spieler: [...player_data.filter((p: string) => p !== pid)]
		}))
		.then(() => true)
		.catch((err: any) => false);
}
/**
 * löscht in der BenutzerSpieler Tabelle alle Spieler  aus dem User mit der User-ID uid heraus
 * @param uid die User ID
 * @returns true, falls erfolgreich, ansonsten false
 */
export async function deleteAllreferencebetUserPlayer(uid: string): Promise<boolean> {
	let db = firebase.firestore(firebaseApp);
	let docRef = db.collection("BenutzerSpieler").doc(uid);
	return docRef.get().then(data => data.data()?.Spieler)
		.then((player_data: string[]) => docRef.update({
			Spieler: []
		}))
		.then(() => true)
		.catch((err: any) => false);
}


/**
 * Hohlt alle Player-IDs aus der BenutzerSpieler Tabelle heraus, die mit dem Benutzer mit der ID uid verknüpft sind
 * @param uid der Benutzer, ausdem die Spieler-IDs geholt werden
 * @returns string[] aus Player-IDs wenn einige vorhanden sind
 * @returns [] wenn keine vorhanden sind
 * @returns null wenn ein Fehler aufgetreten ist
 */
export async function getPlayerIDsofaUser(uid: string): Promise<string[] | null> {
	let db = firebase.firestore(firebaseApp);
	let docRef = db.collection("BenutzerSpieler").doc(uid);
	return docRef.get().then(data => data.data()?.Spieler)
		.catch((err: any) => null);
}

/**
 * Löscht einen User und deren Daten von der BenutzerSpieler Tabelle, somit besitzt dieser keine Spieler mehr
 * @param uid den zu löschenden User
 * @returns true falls erfolgreich, ansonsten false
 */
export function deleteUserfromUserPlayerTable(uid: string): Promise<boolean> {
	let db = firebase.firestore(firebaseApp);
	return db.collection("BenutzerSpieler").doc(uid).delete()
		.then(t => true)
		.catch(err => {analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-user-player-functions.tsx:101" });; return false});
}

/**
 * Löscht in jedem User den Spieler mit der Player-ID pid
 * @param pid den zu löschenden Spielers
 * @return true, falls der Spieler in jedem User gelöscht wurde, ansonsten false
 */
export async function cancelPlayerineveryUser(pid: string): Promise<boolean> {
	let db = firebase.firestore(firebaseApp);
	return db.collection("BenutzerSpieler").get()
		.then((d: any) => d.docs)
		.then(querySnapshot => {
			return querySnapshot.map((doc: any) => deletereferencebetUserPlayer(doc.id, pid))
		})
		.then((d) => true).catch(() => false)

}
