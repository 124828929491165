import { green, deepOrange, lightBlue, deepPurple, lime,grey} from '@material-ui/core/colors';

export const tasks = [
  {
    title: 'Recall Rebate Form',
    priorityId: 2,
    startDate: '2018-04-17T09:30',
    endDate: '2018-04-17T10:00',
  },


];

export const priorities = [
  { id: 1, text: 'Anfänger', color: green },
  { id: 2, text: 'Fortgeschrittene', color: lightBlue },
  { id: 3, text: 'Leistungsgruppe', color: deepOrange },
  { id: 4, text: 'Erwachsene', color:  deepPurple},
  { id: 5, text: 'Individual Training', color:  lime},
  { id: 5, text: 'Sonstiges', color:  grey},
];

export const owners = [
  {
    text: 'Andrew Glover',
    id: 1,
    color: '#7E57C2',
  }, {
    text: 'Arnie Schwartz',
    id: 2,
    color: '#FF7043',
  }, {
    text: 'John Heart',
    id: 3,
    color: '#E91E63',
  }, {
    text: 'Taylor Riley',
    id: 4,
    color: '#E91E63',
  }, {
    text: 'Brad Farkus',
    id: 5,
    color: '#AB47BC',
  }, {
    text: 'Arthur Miller',
    id: 6,
    color: '#FFA726',
  },
];
