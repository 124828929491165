import "./mini_events_mobile.scss";

import CategoryIcon from "@material-ui/icons/Category";
import EventNoteIcon from "@material-ui/icons/EventNote";
import React, { useEffect, useState } from "react";
import ICalendarLink from "react-icalendar-link";
import { BsClockFill } from "react-icons/bs";
import { FaCalendarDay } from "react-icons/fa";
import { GiCampingTent, GiShuttlecock, GiTrophyCup, GiWorld } from "react-icons/gi";
import { MdLocationOn } from "react-icons/md";

import EventDialog from "../event-beschreibung/event-beschreibung";
import { SportEvent } from "../model/event";
import { formatDate } from "../model/utils";

function getIcons(tournierart: string) {
	switch (tournierart) {
		case "Internationales Turnier": {
			return <GiWorld size="100px" color="white" />
		}
		case "Internationales Turnier Under": {
			return <GiWorld size="100px" color="white" />
		}
		case "Mannschaftsspiel": {
			return <GiTrophyCup size="100px" color="white" />
		}
		case "Mannschaftsturnier Over": {
			return <GiShuttlecock size="100px" color="white" />
		}
		case "Italienmeisterschaft": {
			return <img src="/italy.png" alt="italyIcon"></img>
		}
		case "Italienmeisterschaft Under": {
			return <img src="/italy.png" alt="italyIcon"></img>
		}
		case "Italienmeisterschaft Master": {
			return <img src="/italy.png" alt="italyIcon"></img>
		}
		case "Nationales Turnier": {
			return <img src="/italy.png" alt="italyIcon"></img>
		}
		case "VSS Mannschaftsturnier": {
			return <img src="/vss.png" alt="VSSLogo"></img>
		}
		case "VSS Familytrophy": {
			return <img src="/vss.png" alt="VSSLogo"></img>
		}
		case "VSS Jugendcup": {
			return <img src="/vss.png" alt="VSSLogo"></img>
		}
		case "VSS Kinderolympiade": {
			return <img src="/vss.png" alt="VSSLogo"></img>
		}
		case "Landesmeisterschaft": {
			return <img className="suedtirol-icon" src="/südtirol.png" alt="SüdtirolLogo"></img>
		}
		case "Landesmeisterschaft Under": {
			return <img className="suedtirol-icon" src="/südtirol.png" alt="SüdtirolLogo"></img>
		}
		case "Sonstiges": {
			return ""
		}
		case "Camps": {
			return <GiCampingTent size="100px" color="white" />
		}
		default: {
			return <GiShuttlecock size="100px" color="white" />
		}
	}
}

export default function MiniEventsMobile(data: any) {
	const [event, setEvent] = useState<SportEvent>(data.event);
	const [windowSize, setWindowSize] = useState<number>();
	const colour_event = (event.tournierart) + " mini_event";
	useEffect(() => {
		setEvent(data.event);
	}, [data]);

	useEffect(() => {
		function handleResize() {
			setWindowSize(window.screen.width);
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [window.screen.width, windowSize]);
	let data_start = event.start_date;
	let data_end = event.end_date || event.start_date;
	// data_start.setHours(10, 0, 0, 0);
	//  data_end.setHours(23, 59, 0, 0);
	const eventForLocalCalendar = {
		title: event.event_title,
		description: event.event_description.__html,
		startTime: data_start.toISOString(),
		endTime: data_end.toISOString(),
		location: event.location,
		attendees: ["badueber@gmail.com"],
	}


	return (
		<div className="mini_event_outer_container">
			<div className={colour_event} >
				<div className="mini_event">
					<div className="mini_event_header">
						<div className="mini_event_title">
							<p>{event.event_title}</p>
						</div>
						<div className="mini_event_icons">
							<div className="mini_event_icons_info">
								{
									(event.event_description.__html)
										? (<EventDialog data={data.event} />)
										: ("")
								}
							</div>
							<div className="mini_event_icons_tournament">
								{
									(!!(event.tournament_link))
										? (<a href={event.tournament_link} target="_blank" rel="noopener noreferrer">
											<img src="iconTournamentSW.webp" alt="Tournament SW" /></a>)
										: ("")
								}
							</div>
						</div>
					</div>
					<div className="mini_event_content_root">
						<div className="mini_event_content">
							<div className="mini_event_data">
								<div className="icon">
									<MdLocationOn />
								</div>
								<div className="text">
									<p>{event.location}</p>
								</div>
							</div>
							<div className="mini_event_data">
								<div className="icon">
									<FaCalendarDay />
								</div>
								<div className="text">
									{
										(!!event.end_date)
											? (
												<p>{formatDate(event.start_date)} - {formatDate(event.end_date)}</p>
											)
											: (
												<p>{formatDate(event.start_date)}</p>
											)
									}
								</div>
							</div>
							{
								(event.spielbeginn && event.spielbeginn.length > 2)
									? (
										<div className="mini_event_data">
											<div className="icon">
												<BsClockFill />
											</div>
											<div className="text">
												<p>{event.spielbeginn}</p>
											</div>
										</div>
									)
									: ("")
							}
							<div className="mini_event_data">
								<div className="icon">
									<CategoryIcon />
								</div>
								<div className="text">
									<p>{event.tournierart}</p>
								</div>
							</div>
							<div className="mini_event_data">
								<div className="icon">
									<EventNoteIcon />
								</div>
								<div className="text">
									<ICalendarLink event={eventForLocalCalendar}>
										<p>Zu Kalender hinzufügen</p>
									</ICalendarLink>
								</div>
							</div>
						</div>
						{
							(!!windowSize && windowSize < 355)
								? ("")
								: (
									<div className="mini_event_avatar">
										{getIcons(event.tournierart)}
									</div>
								)
						}
					</div>
				</div>
			</div>
		</div >
	)

}
