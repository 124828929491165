import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { BsDownload } from "react-icons/bs"
import { Player } from "../../authentication_required/interfaces/player"
import { getPlayerAnwesendStates } from "../calendarTable/calendarFunctions";

interface DownloadAListProps {
	allPlayer: Player[] | undefined;
	dbIndex: string;
	startDate: Date;
	endDate: Date;
}

export default function DownloadAList(props: DownloadAListProps) {

	const [anwesendPlayer, setAnwesendPlayer] = useState<any>();

	function setAnwesendPlayerPID(playerpids: { dbIndex: string, playerID: string }[]) {
		let pidArray = playerpids.map(p => p.playerID);
		const filterPlayer = props.allPlayer?.filter(player => pidArray.includes(player.pid)).map((p: any) => ({ Name: p.full_name, Geburtsdatum: p.birthdate, Adresse: p.strasse, PLZ: p.plz, Ort: p.ort, Telefonnummer: p.telefonnummer, EMail: p.eMail }));
		if (filterPlayer)
			setAnwesendPlayer(filterPlayer);
	}

	useEffect(() => {
		getPlayerAnwesendStates(setAnwesendPlayerPID, props.dbIndex, props.startDate, props.endDate);
	}, [])

	function convertArrayOfObjectsToCSV(args: any) {
		let result: any, ctr: any, keys: any, columnDelimiter: any, lineDelimiter: any, data: any;

		data = args.data || null;
		if (data == null || !data.length) {
			return null;
		}

		columnDelimiter = args.columnDelimiter || ';';
		lineDelimiter = args.lineDelimiter || '\n';

		keys = Object.keys(data[0]);

		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item: any) {
			ctr = 0;
			keys.forEach(function (key: any) {
				if (ctr > 0) result += columnDelimiter;

				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		return result;
	}
	function downloadCSV(args: any) {
		var data, filename, link;
		var csv = convertArrayOfObjectsToCSV({
			data: anwesendPlayer
		});
		if (csv == null) return;

		filename = args.filename || 'export.csv';

		if (!csv.match(/^data:text\/csv/i)) {
			csv = 'data:text/csv;charset=utf-8,' + csv;
		}
		data = encodeURI(csv);

		link = document.createElement('a');
		link.setAttribute('href', data);
		link.setAttribute('download', filename);
		link.click();
	}

	return (<Button
		onClick={() => !!anwesendPlayer && downloadCSV({ filename: "anwesenheitsliste.csv" })}
		variant="outlined"
		color="inherit"
		component="span"
		disabled={!anwesendPlayer || anwesendPlayer.length === 0}>
		<BsDownload />
	</Button>)
}
