import "./documents.scss";

import React, { useEffect, useState } from "react";

import { firebaseApp } from "../App";
import { Rolle } from "../authentication_required/interfaces/rollen";
import DocumentsGroup from "../model/documents/documentsGroup";
import { DocumentItem } from "../model/utils";

interface DocProps {
	documents_public: DocumentItem[] | undefined;
	rolle: any;
	accessToTrainingsgruppe: string[];
}


export default function Documents(props: DocProps) {
	const [isuserloggedin, setisuserloggedin] = useState<boolean>();
	const [downloads, setDownloads] = useState<DocumentItem[]>();
	const [accessToTrainingsgruppe, setaccessToTrainingsgruppe] = useState<string[]>(props.accessToTrainingsgruppe);

	useEffect(() => {
		setDownloads(props.documents_public);
		setaccessToTrainingsgruppe(props.accessToTrainingsgruppe);
	}, [props, props.documents_public, props.accessToTrainingsgruppe]);

	firebaseApp.auth().onAuthStateChanged(function (user_change) {
		if (user_change) {
			setisuserloggedin(true)
		} else {
			setisuserloggedin(false)
		}
	});
	if (downloads)
		return (<div>
			<div className="documents-root-styles-container">
				<DocumentsGroup downloads={downloads.filter((download: DocumentItem) => download.access.includes("öffentlich"))} name="öffentliche Dokumente" />

				{
					(props.rolle !== Rolle.NotAuth && isuserloggedin === true)
						? (<DocumentsGroup downloads={downloads.filter((download: DocumentItem) => download.access.includes("nur für Mitglieder"))} name="vereinsinterne Dokumente" />)
						: ("")
				}
				{
					(props.rolle !== Rolle.NotAuth && isuserloggedin === true && accessToTrainingsgruppe.includes("Erwachsene"))
						? (<DocumentsGroup downloads={downloads.filter((download: DocumentItem) => download.access.includes("Erwachsene"))} name="Erwachsene Spieler" />)
						: ("")
				}
				{
					(props.rolle !== Rolle.NotAuth && isuserloggedin === true && accessToTrainingsgruppe.includes("Leistungsgruppe"))
						? (<DocumentsGroup downloads={downloads.filter((download: DocumentItem) => download.access.includes("Leistungsgruppe"))} name="Leistungsgruppe" />)
						: ("")
				}
				{
					(props.rolle !== Rolle.NotAuth && isuserloggedin === true && accessToTrainingsgruppe.includes("Fortgeschrittene"))
						? (<DocumentsGroup downloads={downloads.filter((download: DocumentItem) => download.access.includes("Fortgeschrittene"))} name="Fortgeschrittene" />)
						: ("")
				}
				{
					(props.rolle !== Rolle.NotAuth && isuserloggedin === true && accessToTrainingsgruppe.includes("Anfänger"))
						? (<DocumentsGroup downloads={downloads.filter((download: DocumentItem) => download.access.includes("Anfäner"))} name="Anfäner" />)
						: ("")
				}

			</div>
		</div>
		);
	return <div />;
}
