import { Button, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";
import { MdFileDownload } from "react-icons/md";
import UploadDocOfPlayersDialog from "../../components/upload-doc-dialog-player/uploadDocOfPlayersDialog";
import { userhasUploadedaNewDoc } from "../../model/email-functions";
import { generateDownloadIcon } from "../../model/html-generator";
import TableWithoutPaginator from "../../model/table/tableWithoutPaginator";
import AuthDialog from "../Authentication/auth-popup/auth-popup";
import { getCurrentUser } from "../Authentication/user-functions";
import { DocumentLink } from "../interfaces/player";
import { deleteDocfromPlayer, uploadDocToPlayer } from "../table-functions/table-player-functions";
import "./StickyHeadTable.scss";



interface Column {
	id: 'icon' | 'name' | 'timeCreated' | 'size' | 'options';
	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const all_columns: Column[] = [
	{ id: 'icon', label: '', minWidth: 20 },
	{ id: 'name', label: 'Name', minWidth: 170 },
	{
		id: 'timeCreated',
		label: 'Erstellt am',
		align: 'right',
	},
	{
		id: 'size',
		label: 'Größe',
		align: 'right',
		format: (value: number) => value.toLocaleString('en-US'),
	},
	{
		id: 'options',
		label: '',
		align: 'right',
	},
];

interface Data {
	icon: JSX.Element;
	name: string;
	timeCreated: string;
	size: string;
	options: JSX.Element;
	url: string;
}


export default function StickyHeadTable(props: any) {
	const { enqueueSnackbar } = useSnackbar();
	const [columns, setColumns] = useState<Column[]>([]);
	const [hasSendEMailTO, setHasSendEmailTO] = useState<string[]>([]);

	let rows: Data[] = [];


	function handleDeleteDocument(doc: DocumentLink, pid: string) {
		var eingabe = window.confirm("ACHTUNG! Du bist dabei die Datei " + doc.name + " zu löschen. Bitte bestätige den Vorgang");
		if (eingabe) {
			deleteDocfromPlayer(pid, doc.name)
				.then((result) => {
					if (result) {
						let final_links: DocumentLink[] = props.links;
						final_links.splice(final_links.indexOf(doc), 1);
						props.updatePlayer(props.index, final_links);
						enqueueSnackbar("Die Datei wurde erfolgreich gelöscht", { key: "deleteDocTrue", variant: "success" });
					} else {
						enqueueSnackbar("Fehler beim löschen der Datei " + doc.name, { key: "deleteDocFalse", variant: "error" });
					}
				});
		}
	}

	function createData(icon: JSX.Element, link: DocumentLink): Data {
		let name = link.name;
		let url = link.url;
		let timeCreated = link.timeCreated;
		let size = link.size;
		let options: JSX.Element = <div><a href={url} className="tableCellStyles" download target="_blank" rel='noopener noreferrer'><MdFileDownload /></a><AiFillDelete onClick={() => handleDeleteDocument(link, props.pid)} /></div>
		return { icon, name, timeCreated, size, options, url };
	}
	useEffect(() => {
		function handleResize() {
			if (!!window.screen.width && window.screen.width < 800) {
				setColumns([all_columns[0], all_columns[1], all_columns[4]])
			}
			if (!!window.screen.width && window.screen.width > 800) {
				setColumns(all_columns);
			}
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [window.screen.width]);

	function getUploadComponent(): JSX.Element {
		if (!!props.canSendNotifications && props.canSendNotifications === true) {
			return <div className="authDialogforUploadDoc"><AuthDialog button_name={<div className="uploadIconDialog"><FiUpload /></div>} component={<UploadDocOfPlayersDialog index={props.index} links={props.links} currPlayer={props.currPlayer} updatePlayer={props.updatePlayer} />} donotgoBack={true} close={true} /></div>;
		}
		return (
			<div className="upload-doc-style-outer" tabIndex={- 1} >
				<div className="tableCellStyles">
					<p>Laden sie hier ihr Dokument hoch</p>
				</div>
				<div>
					<input
						accept=".doc,.docx,.xlsx,.csv,.pdf,.txt"
						className="input-upload-file"
						id={"contained-button-file-" + props.index}
						type="file"
						onChange={(e) => {
							if (!!e.target.files) {
								let file: File = e.target.files[0];
								uploadDocToPlayer(file, props.pid)
									.then((result: DocumentLink | null) => {
										if (!!result) {
											let final_links: DocumentLink[] = [];
											if (props.links) {
												final_links = [
													...props.links, result
												]
											} else {
												final_links = [result];
											}
											props.updatePlayer(props.index, final_links);
											enqueueSnackbar("Das Dokument wurde hochgeladen", { key: "docUploadSuccess", variant: "success" });
											if (!hasSendEMailTO.includes(props.pid)) {
												setHasSendEmailTO([...hasSendEMailTO, props.pid]);
												let user = getCurrentUser();
												if (user && !!user.email){
													userhasUploadedaNewDoc(user.displayName || "", user?.email, props.player.full_name)
												}
											}
										} else
											enqueueSnackbar("Das Dokument konnte nicht hochgeladen werden", { key: "docUploadError", variant: "error" });
									});
							}
						}}
					/>
					<label htmlFor={"contained-button-file-" + props.index}>
						<Button component="span">
							<div className="tableCellStyles uploadIcon">
								<FiUpload />
							</div>
						</Button>
					</label>
				</div>
			</div >
		)
	}

	if (props.links && props.links.length > 0)
		props.links.map((link: DocumentLink) => (
			rows.push(createData(generateDownloadIcon(link.type, 20), link))
		))
	else
		return <div className="null-links-outer">
			<p className="tableCellStyles no-document">Leider sind keine Dokumente für diesem Spieler vorhanden</p>
			{
				(getUploadComponent())
			}
		</div>
	if (columns)
		return (
			<Paper className="stickyheadtable-root">
				<TableWithoutPaginator columns={columns} rows={rows}/>
				{
					(getUploadComponent())
				}
			</Paper>
		);
	return <div />
}
