import React from 'react';
import { generateDownloadIcon } from "../model/html-generator";
import "./document_component.scss";

interface DocumentComponentProps {
	type: string;
	name: string;
	url?: string;
}

export default function DocumentComponent(props: DocumentComponentProps) {
	const { name, type, url } = props;
	return (
		<div className="DocumentComponent-root" >
			<a {...(url !== undefined) ? { href: url } : ""} target="_blank">
				<div className="ItemIcon">
					{generateDownloadIcon(type, 40)}
				</div>
				<hr />
				<div className="itemName">
					<p>{name}</p>
				</div>
			</a>
		</div>
	);
}

