import { Button, List, TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useEffect, useState } from "react";
import AuthDialog from "../../authentication_required/Authentication/auth-popup/auth-popup";
import "./PlayerAnwesendList.scss"

import { Player } from "../../authentication_required/interfaces/player";
import { deletePlayerAnwesendState, getPlayerAnwesendStates, givePlayerAnwesendState } from "../calendarTable/calendarFunctions";

export default function PlayerAnwesendList(props: { allPlayer: Player[] | undefined, title: string, trainingsgruppe: number, startDate: Date, endDate: Date, dbIndex: string }) {
	const [data, setData] = useState<any>([]);
	const [filteredData, setFilteredData] = useState<any>(data);
	const [checked, setChecked] = React.useState<{ playerID: string, dbIndex: string }[]>([{ playerID: "", dbIndex: "" }]);
	const [searchingInput, setSearchingInput] = useState<string>("");

	useEffect(() => {
		setData(props.allPlayer)
	}, [props, props.allPlayer])
	useEffect(() => {
		if (props.dbIndex && props.startDate && props.endDate)
			getPlayerAnwesendStates(setChecked, props.dbIndex, props.startDate, props.endDate);
	}, [props.startDate, props.allPlayer, props.dbIndex, props.trainingsgruppe, props.endDate]);


	useEffect(() => {
		if (searchingInput.length >= 1 && !!data) {
			setFilteredData(data.filter((singlePlayer: any) => singlePlayer.full_name.toUpperCase().includes(searchingInput.toLocaleUpperCase())))
		} else if (searchingInput.length === 0) {
			switch (props.trainingsgruppe) {
				case 1: {
					setFilteredData(data.filter((singlePlayer: any) => singlePlayer.trainingsgruppe === "Anfänger"))
					break;
				}
				case 2: {
					setFilteredData(data.filter((singlePlayer: any) => singlePlayer.trainingsgruppe === "Fortgeschrittene"))
					break;
				}
				case 3: {
					setFilteredData(data.filter((singlePlayer: any) => singlePlayer.trainingsgruppe === "Leistungsgruppe"))
					break;
				}
				case 4: {
					setFilteredData(data.filter((singlePlayer: any) => singlePlayer.trainingsgruppe === "Erwachsene"))
					break;
				}
				default: {
					setFilteredData(data)
					break;
				}
			}
		}
	}, [data, props.trainingsgruppe, searchingInput]);


	function getCheckedPosition(value: any) {
		let currentIndex = -1;
		for (let i = 0; i < checked.length; i++) {
			if (checked[i].playerID === value.pid) {
				currentIndex = i;
				break;
			}
		}
		return currentIndex;
	}

	const handleToggle = (value: any) => () => {
		let currentIndex = getCheckedPosition(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			givePlayerAnwesendState(value.pid, props.dbIndex, props.startDate, props.endDate)
		} else {
			deletePlayerAnwesendState(checked[currentIndex].dbIndex, props.dbIndex, props.startDate, props.endDate)
			newChecked.splice(currentIndex, 1);
			setChecked(newChecked);
		}

	};

	if (!data) {
		return <Button disabled>Spieler werden geladen....</Button>
	}

	if (data && !props.dbIndex) {
		return <Button
			className="uploadEventsButton"
			variant="outlined"
			color="inherit"
			component="span"
			disabled
		>Anwesenheitsliste bearbeiten</Button>
	}

	return (
		<AuthDialog
			button_name={
				<Button
					className="uploadEventsButton"
					variant="outlined"
					color="inherit"
					component="span"
				>Anwesenheitsliste bearbeiten</Button>
			}
			component={
				<div className="playerPresentRoot">
					<form className="input-text-field-table-styles" noValidate autoComplete="off">
						<TextField id="standard-basic" label="Suche Spieler" onChange={(e) => setSearchingInput(e.target.value)} />
					</form>
					<List >
						{filteredData.map((value: any) => {
							const labelId = `checkbox-list-secondary-label-${value}`;
							return (
								<ListItem
									key={value.full_name}
								>
									<div>
										<ListItemText id={labelId} primary={value.full_name} />
									</div>
									<Checkbox
										edge="end"
										onChange={handleToggle(value)}
										checked={getCheckedPosition(value) !== -1}
										inputProps={{ 'aria-labelledby': labelId }}
									/>
								</ListItem>
							);
						})}
					</List >
				</div>
			}
			open={false} function={() => { }}
			donotgoBack={true}
			close={true}
		/>
	)
}
