import firebase from "firebase";
import { analytics, firebaseApp, perf } from "../../App";
import { getPlayerDocuments, parseSizeToString } from "../custom-player-data/customPlayerFunctions";
import { DocumentLink, Player } from "../interfaces/player";
import { cancelPlayerineveryUser } from "./table-user-player-functions";


let allPlayers: Player[] | null = null;

/**
 * Hohlt die Daten eines Spielers mit der Player-ID pid, wenn man die nötigen Zugriffsrechte besitzt
 * @param pid der Spieler, von dem die Daten gebraucht werden
 * @returns Player, wenn dieser Player vorhanden ist, sonst null wenn nicht vorhanden, Fehler oder kein Zugriffsrecht
 */
export async function getSinglePlayerData(pid: string): Promise<Player | undefined> {
	try {
		let db = firebase.firestore(firebaseApp);
		const docRef = await db.collection("Spieler").doc(pid);
		const links = await getPlayerDocuments(pid);
		return await docRef.get()
			.then(d => d.data())
			.then((player_data: any) => ({
				pid: pid,
				vorname: player_data.vorname,
				nachname: player_data.nachname,
				links: links,
				full_name: (player_data.nachname + " " + player_data.vorname),
				bild: player_data.bild,
				birthdate: player_data.birthdate.toDate ? player_data.birthdate.toDate() : new Date(player_data.birthdate),
				strasse: player_data.strasse,
				plz: player_data.plz,
				ort: player_data.ort,
				telefonnummer: player_data.telefonnummer,
				trainingsgruppe: player_data.trainingsgruppe,
				spielerausweis: player_data.spielerausweis,
				sportmed_gueltig_bis: player_data.sportmed_gueltig_bis ? player_data.sportmed_gueltig_bis.toDate() : "",
				eMail: player_data.eMail,
			}))
			.then((player: Player) => !!player ? player : undefined)
			.catch(err => {
				analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:42" });
				return undefined
			})
	} catch (error) {
		const err = error as any;
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:47" });
		return undefined;
	}

}
/**
 * Liefert alle Dokumente eines Spielers zurück
 * @param pid die ID des Spielers
 * @returns ein string[][] sollten Dokumente vorhanden sein, ansonsten null
 */
export async function getDocumentsofaPlayer(pid: string): Promise<string[][] | null> {
	return null;
	// 	return getSinglePlayerData(pid)
	// 		.then(data => !!data ? data.links : null);
}


/**
 * Parst ein 2D-Stringarray bestehend aus ["name","http://link.com"] von typ string zu einem string ("name / http://link.com")
 * @param links das Array, welches die Links enthält, die zusammengefügt werden sollen
 * @returns ein string[] in der Form "name / http://link.com"
 */
// function parseLinktoStringforDoc(links: string[][]): string[] {
// 	let ret = links.map((link: string[]) =>
// 		link[0] + " / " + link[1]
// 	);
// 	return ret;
// }

/**
 * Prüft, ob ein Spieler mit Vorname vorname und Nachname nachname bereits existiert
 * @param vorname der zu suchende Vorname des Spielers
 * @param nachname der zu suchende Nachname des Spielers
 * @return true falls der Spieler bereits vorhanden ist, ansonsten false
 */
export async function existsaPlayerwiththeseName(vorname: string, nachname: string): Promise<boolean> {
	try {
		const docRef = await firebaseApp.firestore().collection("Spieler");
		return docRef.get()
			.then(d => d.docs)
			.then(querySnapshot => querySnapshot.map(data =>
				data.data().vorname === vorname &&
				data.data().nachname === nachname
			))
			.then(bool_array => bool_array.find(d => d === true) || false)
			.catch(err => {
				analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:92" })
				return false
			})
	} catch (error) {
		const err = error as any;
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:97" });
		return false;
	}

}

/**
 * Fügt einen neuen Spieler in die Spielertabelle hinzu
 * @param nachname Nachnamen des Spielers
 * @param vorname Vornamen des SPielers
 * @param geburtsdatum Geburtsdatum des Spielers
 * @param strasse Straße und Hausnummer des Spielers
 * @param plz PLZ des Wohnortes des Spielers
 * @param ort Wohnort des Spielers
 * @param telefonnummer Telefonnummer des Spielers
 * @param trainingsgruppe die dazugehörige Trainingsgruppe
 * @param spielerausweis die SpielerID des Spielers
 * @param sportmed_gueltig_bis falls vorhanden - das Datum an welchen die Sportärztliche Visite verfällt
 * @returns
 */
export async function createnewPlayer(nachname: string, vorname: string, geburtsdatum: Date, strasse: string, plz: string, ort: string, telefonnummer: string, trainingsgruppe: string, email: string, spielerausweis?: string, sportmed_gueltig_bis?: Date): Promise<any> {
	try {

		const db = firebaseApp.firestore();
		const exist_these_player = await existsaPlayerwiththeseName(vorname, nachname);
		let profil_image = "";
		if (!!spielerausweis) {
			profil_image = await getImageOfPlayer(spielerausweis);
		}

		if (!exist_these_player) {
			return await db.collection("Spieler").add({
				nachname: nachname,
				vorname: vorname,
				birthdate: geburtsdatum,
				strasse: strasse,
				plz: plz,
				ort: ort,
				telefonnummer: telefonnummer,
				trainingsgruppe: trainingsgruppe,
				sportmed_gueltig_bis: sportmed_gueltig_bis || "",
				spielerausweis: spielerausweis,
				eMail: !!email ? [email] : [],
				bild: profil_image,
			})
				.then(e => {
					return [true, e.id];
				})
				.catch(err => {
					analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:146" });
					return [false]
				})
		}
		return [false];
	} catch (error) {
		const err = error as any;
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:153" });
		return [false];
	}
}

/**
 * Änder den Namen und/oder den Nachnamen eines Spielers mit der Spieler-ID pid ab
 * @param pid die Spieler ID
 * @param vorname der zu ändernde Vorname des Spielers oder null, sollte dieser nicht geändert werden
 * @param nachname der zu ändernde Nachname des Spielers oder null, sollte dieser nicht geändert werden
 * @returns true, falls erfolgreich, false sollte ein Fehler auftreten, die Spieler nicht vorhanden sein oder der USer kein Zugriffsrecht besitzt (erlaubt: Admin + User der auf einem Spielerdatensatz Zugriff hat)
 */
export async function updatePlayer(pid: string, nachname: string, vorname: string, geburtsdatum: Date, strasse: string, plz: string, ort: string, telefonnummer: string, trainingsgruppe: string, spielerausweis: string, sportmed_gueltig_bis: Date | undefined, email: string[]): Promise<boolean> {
	return firebaseApp.firestore().collection("Spieler").doc(pid).update({
		vorname,
		nachname,
		birthdate: geburtsdatum,
		strasse,
		plz,
		ort,
		telefonnummer,
		trainingsgruppe,
		spielerausweis,
		sportmed_gueltig_bis,
		eMail: email,
	})
		.then(() => true)
		.catch((err) => { analytics.logEvent("exception", { ErrorMsg: err.message || err.statusText || err, ErrorCode: err, Located: "table-player-function.tsx:180" }); return false });
}

export async function updatePlayerwithPlayerObject(player: Player): Promise<boolean> {
	return await updatePlayer(player.pid, player.nachname, player.vorname, player.birthdate, player.strasse, player.plz, player.ort, player.telefonnummer, player.trainingsgruppe, player.spielerausweis || "", player.sportmed_gueltig_bis, player.eMail);
}

/**
 * Fügt E-Mail zu einen Player hinzu
 * @param player der Spieler. Es fügt diesem Player die neue EMail auch im übergebenen Objekt hinzu
 * @param emails die E-Mails die hinzugefügt werden
 * @returns true, falls erfolgreich, ansonsten false
 */
export function addEmailToAPlayer(player: Player, addEmails: string[]) {
	let email: string[] = [];
	for (let i of addEmails) {
		if (!player.eMail.includes(i)) {
			email.push(i)
		}
	}
	if (email === [])
		player.eMail = [...player.eMail];
	else
		player.eMail = [...player.eMail, ...email];

	return updatePlayerwithPlayerObject(player);
}
/**
 * Löscht eine E-Mail von einem Player
 * @param player der Spieler. Es löscht von diesem Player auch die E-Mail aus dem übergebenen Objekt
 * @param emails die E-Mails die gelöscht wird
 * @returns true, falls erfolgreich, ansonsten false
 */
export function deleteEmailFromAPlayer(player: Player, deleteEmails: string[]) {
	for (let email of deleteEmails) {
		let index = player.eMail.indexOf(email);
		if (index > 0)
			player.eMail.splice(player.eMail.indexOf(email), 1);
	}
	return updatePlayerwithPlayerObject(player);
}


export async function uploadDocToPlayer(file: File, pid: string): Promise<DocumentLink | null> {
	if (file === null || !pid) {
		alert("Die Datei konnte nicht hochgeladen werden")
		return null;
	}
	let ref: firebase.storage.Reference | null = await (firebaseApp.storage().ref(`/user-data/${pid}/${file.name}`).put(file)
		.then((result) => {
			return result.ref
		})
		.catch((e) => {
			analytics.logEvent("exception", { ErrorMsg: e.message, ErrorCode: e.code, Located: "table-player-functions.tsx:210" });
			return null;
		}))
	if ((await ref) === null) {
		return null;
	}
	if (ref !== null) {
		let metadata = await ref.getMetadata();
		let date = await new Date(await metadata.timeCreated);
		let url = await ref.getDownloadURL();
		let doc: DocumentLink = {
			name: ref.name,
			url: url,
			type: metadata.type,
			timeCreated: date.toLocaleString(),
			size: parseSizeToString(metadata.size),
		}

		// alert("Die Datei wurde erfolgreich hochgeladen");
		return await doc;
	}
	return null;
}


/**
 * Prüft den übergebenen String, ob dieser ein gültiger Link ist
 * @param strg der zu überprüfene String
 * @returns true, falls strg ein gültiger Link ist ansonsten false
 */
// function validURL(strg: string) {
// 	var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
// 		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
// 		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
// 		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
// 		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
// 		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
// 	return !!pattern.test(strg);
// }

/**
 * Löscht ein Dokument / ein Link aus einem Spieler
 * @param pid die Spieler-ID
 * @param link der zu löschende Link
 * @returns true, falls der Vorgang erfolgreich, oder Link nicht vorhanden. Bei Fehler oder Zugriffsverweigerung false
 */
export async function deleteDocfromPlayer(pid: string, name: string): Promise<boolean> {
	return firebaseApp.storage().ref(`/user-data/${pid}/${name}`).delete()
		.then(() => {
			return true
		})
		.catch((err) => {
			analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:264" });
			return false;
		});
}

/**
 * Tauscht einen Link mit einem neuen Link aus
 * @param pid die ID des Users
 * @param old_link der zu austauschende Link
 * @param old_beschreibung die Beschreibung des zu austauschenden Links
 * @param new_link der neue Link, mitdem getauscht wird
 * @param new_beschreibung die neue Beschreibung des Links
 * @returns
 */
// export async function updateLinkofPlayer(pid: string, old_link: string, old_beschreibung: string, new_link: string, new_beschreibung: string): Promise<boolean> {
// 	// await deleteDocfromPlayer(pid, old_beschreibung, old_link);
// 	return addLinktoPlayer(pid, new_beschreibung, new_link);
// }

/**
 * Löscht aus der Spieler Tabelle den Spieler mit der ID pid und entfernt auch alle Referenzen in der BenutzerSpieler Tabelle zu ihm
 * @param pid der zu löschende Spieler
 * @returns true, falls der Spieler erfolgreich gelöscht wurde. Bei Fehler oder kein Zugriffsrecht false
 */
export async function deletePlayer(pid: string): Promise<boolean> {
	if (await cancelPlayerineveryUser(pid)) {
		return await firebaseApp.firestore().collection("Spieler").doc(pid).delete()
			.then(() => true).catch((err) => { analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "table-player-functions.tsx:312" }); return false });
	} else
		return false;

}

/**
 * Liefert den Beutzer mit der nötigen Berechtigung alle Spieler zurück
 * @returns
 */
export async function getAllPlayers(): Promise<Player[]> {
	console.log("getAllPlayers")
	let i = 0;
	if (!allPlayers) {
		const trace = perf.trace("gettingAllPlayer");
		trace.start();
		let querySnapshot = await firebaseApp.firestore().collection("Spieler").get()
			.then((querySnapshot: any) => querySnapshot.docs);
		let final_player = [];
		for (let doc of querySnapshot) {
			let document = undefined;
			let player: Player = {
				pid: doc.id,
				vorname: doc.data().vorname,
				nachname: doc.data().nachname,
				links: document,
				full_name: (doc.data().nachname + " " + doc.data().vorname),
				bild: doc.data().bild,
				birthdate: (doc.data().birthdate.toDate) ? doc.data().birthdate.toDate() : new Date(doc.data().birthdate),
				strasse: doc.data().strasse,
				plz: doc.data().plz,
				ort: doc.data().ort,
				telefonnummer: doc.data().telefonnummer,
				trainingsgruppe: doc.data().trainingsgruppe,
				spielerausweis: doc.data().spielerausweis,
				eMail: doc.data().eMail,
				sportmed_gueltig_bis: !!doc.data().sportmed_gueltig_bis ? doc.data().sportmed_gueltig_bis.toDate() : "",
			}
			final_player.push(player);
			i++;
			if (i === 10) {
			}
		}
		let sort_data = await final_player.sort((a, b) => (a.nachname === b.nachname) ? (a.vorname > b.vorname ? 1 : -1) : (a.nachname > b.nachname) ? 1 : -1);
		allPlayers = sort_data;
		trace.stop();
		return await sort_data;
	} else {
		return allPlayers;
	}
}

async function getImageOfPlayer(spielerausweis_id: string) {
	let storage = firebaseApp.storage();
	let list = await storage.ref(`/profil-image/${spielerausweis_id}.jpg`);
	if (!!list) {
		let link: string = await list.getDownloadURL().catch(err => { return "" });
		return await link;
	}
	return "";

}

export async function deleteAllPlayer() {
	let allPlayer = await getAllPlayers();
	for (let p of allPlayer)
		deletePlayer(p.pid)
}

export async function importPlayerFromDoc(data: string) {
	let fetch_data = await fetch(data);
	let json = await fetch_data
		.json()
		.then(liste => liste.Spielerliste.Spieler);
	for (let single_data of json) {
		setTimeout(() => {
			createnewPlayer(single_data._Nachname, single_data._Vorname, single_data._Geburtsdatum, single_data._Strasse || "", single_data._PLZ, single_data._Ort, single_data._Mobiltelefon || single_data._Telefon, single_data.Trainingsgruppe._Name, single_data._EMail, single_data._Spielerausweis || "", !!single_data._XF_gueltig ? new Date(single_data._XF_gueltig) : undefined)
				.catch(e => analytics.logEvent("exception", { ErrorMsg: e.message, ErrorCode: e.code, Located: "table-player-functions.tsx:388" }))
		}, 200);
	}
}


