import { createStyles, IconButton, ImageList, ImageListItem, ImageListItemBar, makeStyles, Theme } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";
import ScrollDialog from "../infos/popupwindow/popupwindow";
import { Article } from "../model/article";
import Gruppe from "../model/gruppe";
import ScrollDialogNews from "../popupwindow_news/popupwindow_news";
import "./kacheln_home_page.scss";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			overflow: 'hidden',
		},
		gridList: {

		},
		icon: {
			color: 'rgba(255, 255, 255, 0.54)',
		},
	}),
);

export default function KachelnHomepage(props: any) {
	const [data, setData] = useState<Gruppe>(props.data || null);
	const [data_art, setDataArt] = useState<Article>(props.data_art || null);

	const classes = useStyles();

	const handleVoidClick = () => { };
	const handleRef = ({ target: img }: any, id: string) => {
		if(img.offsetHeight > img.offsetWidth) {
			const element = document.getElementById(id);
			if (element)
			element.style.top = "75%";
		}
	}
	const generateArtHTML = () => {
		return (
			<ImageList className="gridList_styles">
				<ImageListItem key={data_art.image_urls[0] || "null"} >
					<img id={data_art.id} src={data_art.image_urls[0] || "logo512.webp"} alt={data_art.title || "LogoBadmintonÜberetsch"} onLoad={(ref) => handleRef(ref,data_art.id)} />
					<ImageListItemBar
						title={"Latest News" || "error"}
						actionIcon={
							<IconButton aria-label="info about" className={classes.icon} key={data_art.title}>
								<InfoIcon />
							</IconButton>
						}
					/>
				</ImageListItem>
			</ImageList>
		)
	}
	const generateHTML = () => {
		return (
			<ImageList className="gridList_styles">
				<ImageListItem key={(!!data && data.img) || "null"} >
					<img id={data.id} src={data.img || "logo512.webp"} alt={data.name || "LogoBadmintonÜberetsch"} onLoad={(ref) => handleRef(ref,data.id)}/>
					{
						(!!data && !!data.name)
							? (
								<ImageListItemBar
									title={data.name}
									actionIcon={
										<IconButton aria-label="info about" className={classes.icon}>
											<InfoIcon />
										</IconButton>
									}
								/>
							)
							: (<div />)
					}
				</ImageListItem>
			</ImageList>
		)
	}


	useEffect(() => {
		setData(props.data);
		setDataArt(props.data_art)
	}, [props, props.data, props.data_art])

	return (
		<div>
			{
				(props.isNews)
					? (<ScrollDialogNews className="scroll_dialog_news_kachel" info_data={data_art} html={generateArtHTML()} />)
					: (<ScrollDialog info_data={data} html={generateHTML()} onClick={handleVoidClick} onClose={handleVoidClick} />)
			}
		</div>
	)
}
