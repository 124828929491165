import "./table.scss";

import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useEffect, useState } from "react";

import TableWithPaginator from "../../../model/table/tableWithPaginator";
import { Player } from "../../interfaces/player";
import { User } from "../../interfaces/user";
import { getallBenutzer } from "../../table-functions/table-user-functions";
import UserConfig from "./user-config";



interface Column {
	id: 'name' | 'email' | 'rolle' | 'icon';
	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const columns: Column[] = [
	{ id: 'name', label: 'Name', minWidth: 170 },
	{ id: 'email', label: 'E-Mail', minWidth: 100 },
	{
		id: 'rolle',
		label: 'Rolle',
		align: 'right',
		format: (value: number) => value.toLocaleString('en-US'),
	}
];

interface Data {
	name: string;
	email: string;
	rolle: string;
	user: User;
}

interface UserInfoProps {
	player: Player[] | undefined;
}

export default function ChangeUserInformationGUI(props: UserInfoProps) {
	const [userdata, setUserData] = useState<User[]>();
	const [filteredData, setFilteredData] = useState<User[]>();
	const [rows, setRows] = useState<any>();
	const [currUser, setCurrUser] = useState<User>();
	const [allPlayers, setAllPlayer] = useState<Player[]>();
	const [searching_input, setSearchingInput] = useState<string>("");
	const [update, setUpdate] = useState<boolean>(false);

	useEffect(() => {
		setAllPlayer(props.player)
	}, [props, props.player]);

	const onClick = (onClickuser: User) => {
		setCurrUser(onClickuser);
	}

	function createData(name: string, email: string, rolle: string, user: User): Data {
		return { name, email, rolle, user };
	}

	function refreshData() {
		localStorage.removeItem("User");
		getallBenutzer()
			.then((d: User[]) => {
				setUserData(d);
				setFilteredData(d);
			})
	}

	useEffect(() => {
		getallBenutzer()
			.then((d: User[]) => {
				setUserData(d);
				setFilteredData(d);
			})
	}, []);

	useEffect(() => {
		if (searching_input.length !== 0 && !!userdata) {
			setFilteredData(userdata.filter((single_user) => single_user.name.toUpperCase().includes(searching_input.toLocaleUpperCase()) || single_user.email.toUpperCase().includes(searching_input.toLocaleUpperCase())))
		} else
			setFilteredData(userdata);
	}, [searching_input]);

	useEffect(() => {
		let final_rows = [];
		if (!!filteredData)
			for (let user of filteredData) {
				final_rows.push(createData(user.name, user.email, user.rolle === "null" ? "- -" : (user.rolle || "- -"), user))
			}
		setRows(final_rows)
	}, [filteredData, update]);

	function handleUpdate() {
		setUpdate(!update)
	}

	if (!!rows)
		return (
			<div>
				<div className="user-table-top-functions-styles">
					<form className="input-text-field-table-styles" noValidate autoComplete="off">
						<TextField id="standard-basic" label="Suche Benutzer/E-Mail" onChange={(e) => setSearchingInput(e.target.value)} />
					</form>
					<RefreshIcon onClick={() => refreshData()} />
				</div>
				<Paper className="table-root">
					<TableWithPaginator rows={rows} columns={columns} onClick={onClick} />
				</Paper>
				{
					(!!currUser)
						? (
							<UserConfig user={currUser} allPlayers={allPlayers} update={handleUpdate} />
						)
						: (<div />)
				}
			</div>
		);
	else
		return (<div></div>)
}
