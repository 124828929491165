
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";


export default function Spinner() {

	return (
		<div className="spinner">
			<ClipLoader
				size={50}
				color={"rgb(255,255,255)"}
				loading={true}
			/>
		</div>
	);





}
