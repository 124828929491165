import "./news.scss";

import {
    createStyles,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    makeStyles,
    Theme
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";

import { Article } from "../model/article";
import ScrollDialogNews from "../popupwindow_news/popupwindow_news";




const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			overflow: 'hidden',
		},
		icon: {
			color: 'rgba(255, 255, 255, 0.54)',
		},
	}),
);

interface NewsProps {
	news: Article[] | undefined;
}

export default function News(props: NewsProps) {
	const [news, setNews] = useState<Article[]>();
	const classes = useStyles();

	useEffect(() => {
		setNews(props.news)
	}, [props, props.news]);


	const generateHTML = (art: Article) => (
		<ImageListItem key={art.image_urls[0]}>
			<img src={art.image_urls[0] || "logo512.webp"} alt={art.title || "LogoBadmintonÜberetsch"} />
			<ImageListItemBar
				title={art.title}
				actionIcon={
					<IconButton aria-label="info about" className={classes.icon}>
						<InfoIcon />
					</IconButton>
				}
			/>
		</ImageListItem>
	)
	return (
		<div>
			<div className="newsContent">
				<div className="grid-root">
					<ImageList rowHeight={200} className="gridList">
						{
							news?.map(art => (
								<ScrollDialogNews info_data={art} html={generateHTML(art)} />
							)
							)}
					</ImageList>
				</div>
			</div>
		</div>
	);
}
