import "./profil.scss";
import "firebase/auth";

import { TextField } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import firebase from "firebase/app";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { analytics } from "../App";
import AuthDialog from "../authentication_required/Authentication/auth-popup/auth-popup";
import { getCurrentUser, getPlayerDataofUser } from "../authentication_required/Authentication/user-functions";
import { Player } from "../authentication_required/interfaces/player";
import { deleteUser } from "../authentication_required/table-functions/table-user-functions";
import { requestAccessMail } from "../model/email-functions";
import ChangePassword from "../passwort_ändern/change_password";



export default function Profil() {
	const [customUserPlayer, setCustomUserPlayer] = useState<Player[]>();
	const user = firebase.auth().currentUser;
	const [windowSize, setWindowSize] = useState<number>(100);
	const { enqueueSnackbar } = useSnackbar();
	const [requestPlayer, setRequestPlayer] = useState<string[]>([]);
	const [vorname, setVorname] = useState<string>("");
	const [nachname, setNachname] = useState<string>("");

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				display: 'flex',
				'& > *': {
					margin: theme.spacing(1),
				},
			},
			small: {
				width: theme.spacing(3),
				height: theme.spacing(3),
			},
			large: {
				width: theme.spacing(16),
				height: theme.spacing(16),
			},
		}),
	);
	useEffect(() => {
		function handleResize() {
			setWindowSize(window.screen.width);

		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [windowSize]);

	const classes = useStyles();
	useEffect(() => {
		getPlayerDataofUser(user?.uid || "")
			.then((items: any) => setCustomUserPlayer(items))

	}, [])

	function stelleAnfrage() {
		const cookies = new Cookies();
		if (user && user.email) {
			if (cookies.get('requestPlayer')) {
				enqueueSnackbar("Sie haben heute bereits eine Anfrage gestellt", { key: "InfoKey", variant: "error" });
			} else {
				cookies.set('requestPlayer', 'true', { path: '/Profil', expires: new Date(Date.now() + 3600 * 2 * 10000) });
				requestAccessMail(user.displayName || "", user.email, requestPlayer.toString());
				setRequestPlayer([]);
				enqueueSnackbar("Anfrage wurde erfolgreich versendet", { key: "InfoKey", variant: "success" });
			}
		}
	}

	async function deleteUserProfile() {
		var eingabe = window.confirm("ACHTUNG! Du bist dabei deinen Account zu löschen.")
		if (eingabe && !!user) {
			await deleteUser(user.uid);
			await getCurrentUser()?.delete()
				.then(() => {
					// window.location.href = "/";
					enqueueSnackbar(`Du hast deinen Account erfolgreich gelöscht`, { key: "deleteAcc", variant: "success" });
				})

				.catch((err) => {
					enqueueSnackbar(`Dein Account konnte nicht gelöscht werden`, { key: "deleteAcc", variant: "error" });
					analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "profil.tsx:100" });
				})
		}
		else {
			enqueueSnackbar(`Dein Account wurde nicht gelöscht`, { key: "deleteAcc", variant: "info" });
		}
	}

	if (!!user && customUserPlayer) {
		// The user object has basic properties such as display name, email, etc.


		const componentAddPlayer = (
			<div className="requestPlayer-root">
				<div className="component-textfield-requestPlayer">
					<div className="component-textfield">
						<TextField id="outlined-basic" label="Nachname" variant="outlined" required value={nachname} onChange={(e) => setNachname(e.target.value)} />
					</div>
					<div className="component-textfield">
						<TextField id="outlined-basic" label="Vorname" variant="outlined" required value={vorname} onChange={(e) => setVorname(e.target.value)} />
					</div>
				</div>
				<div className="requestPlayer-names">
					{
						requestPlayer.map(player => (
							<p>- {player}</p>
						))
					}
				</div>
				<div className="component-buttons-requestPlayer">
					<p className="one-request-a-Day">Es kann nur eine Anfrage pro Tag gestellt werden</p>
					<Button variant="contained" color="primary" disableElevation
						onClick={() => {
							if ((!!nachname && !!vorname)) {
								if (!requestPlayer.includes(nachname + " " + vorname)) {
									enqueueSnackbar("Spieler zur Anfrage hinzugefügt", { key: "InfoKey", variant: "success" });
									setRequestPlayer([...requestPlayer, (nachname + " " + vorname)]); setNachname(""); setVorname("");
								} else
									enqueueSnackbar("Spieler bereits zur Anfrage hinzugefügt", { key: "InfoKey", variant: "info" });
							}
						}}
						disabled={nachname.length < 2 || vorname.length < 2}>
						Spieler zur Anfrage hinzufügen
					</Button>
					<Button variant="contained" color="primary" disableElevation onClick={() => stelleAnfrage()} disabled={requestPlayer.length === 0}>
						Anfrage stellen
					</Button>
				</div>
			</div >
		);

		return (
			<div className="profil-root-height">
				<div className="profil-root">
					<div className={`${classes.root} profile-avatar`}>
						<Avatar alt={user.displayName || ""} src="Profilbid/26090.jpg" className={classes.large} />
					</div>
					<h1 className="userName">{(user !== null) ? user.displayName : ""}</h1>
					<p className="userName">{(user !== null) ? user.email : ""}</p>
					{/* <TextField
						color="secondary"
						defaultValue={user.email}
						variant="standard"
						className="userName editEmail">
						{(user !== null)? user.email : ""}
					</TextField> */}

					<div className="button-container">
						<AuthDialog button_name={
							<Button
								className="button-item"
								variant="contained"
								color="primary"
							>
								PASSWORT ÄNDERN
							</Button>
						}
							component={<ChangePassword/>}
							open={false} function={() => { }}
							donotgoBack={true}

						/>

					</div>
					<div className="button-container">
						<Button
							onClick={() => deleteUserProfile()}
							variant="contained"
							color="secondary"
							className="button-item"
							startIcon={<DeleteIcon />}
						>
							ACCOUNT LÖSCHEN
						</Button>
					</div>
					<div className="zugriff-auf-root">
						{
							(customUserPlayer)
								? (

									<div className="ZugriffAufListe-container">
										<List className="ZugriffAufListe">
											<div className="title-zugriff-auf-root">
												<p>Sie besitzen den Zugriff auf folgende Spieler</p>
											</div>
											{
												(customUserPlayer.length > 0)
													? (customUserPlayer.map((player, i) => (
														<ListItem alignItems="flex-start">
															<ListItemAvatar>
																<Avatar alt="" src={player.bild} />
															</ListItemAvatar>
															<ListItemText
																primary={player.full_name}
																secondary={
																	<React.Fragment>
																		<Typography
																			component="span"
																			variant="body2"
																			className="ZugriffAufNamen"
																			color="textPrimary"
																		>
																		</Typography>
																	</React.Fragment>
																}
															/>
														</ListItem>
													)))
													: (<ListItem alignItems="flex-start">
														<ListItemText
															primary="Zu Ihren Konto wurde noch kein Spieler zugeordnet."
															secondary={
																<React.Fragment>
																	<Typography
																		component="span"
																		variant="body2"
																		className="ZugriffAufNamen"
																		color="textPrimary"
																	>
																	</Typography>
																</React.Fragment>
															}
														/>
													</ListItem>)
											}

											<Divider variant="inset" component="li" />
											<ListItem alignItems="flex-start">
												<AuthDialog button_name={
													<Button >
														<AddIcon className="addIcon"></AddIcon>
														<ListItemText
															primary="Person hinzufügen"
															secondary={
																<React.Fragment>
																	<Typography
																		component="span"
																		variant="body2"
																		className="addPerson"
																		color="textPrimary"
																	>
																	</Typography>
																</React.Fragment>
															}
														/>
													</Button>
												}
													component={componentAddPlayer}
													donotgoBack={true}
												/>
											</ListItem>
										</List>
									</div>
								)
								: (<div />)
						}
					</div>
				</div>
			</div>
		)
	} else {
		return (<div></div>)
	}
}
// getCurrentUser()?.delete()
// .then(()=>)
// .catch(()=>)
