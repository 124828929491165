import "./popupwindow.scss";

import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";



export default function ScrollDialog(props: any) {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState(props.info_data);
	const [windowSize, setWindowSize] = useState<number>();
	let fullSizeAtribute: boolean = false;
	let title;

	const handleClickOpen = () => {
		setOpen(true);
		props.onClick();
	};
	const handleClose = () => {
		setOpen(false);
		props.onClose();
	};

	const descriptionElementRef = useRef<HTMLElement>(null);
	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
		setData(props.info_data);

		function handleResize() {
			setWindowSize(window.screen.width);
		}
		window.addEventListener("resize", handleResize);
		handleResize();


		return () => window.removeEventListener("resize", handleResize);
	}, [open, props]);

	if (!!windowSize && windowSize < 800)
		fullSizeAtribute = true;


	if (!!data) {
		if (!!data.title) {
			title = data.title;
		} else if (!!data.name) {
			title = data.name;
		}
	}
	if(!data.content) {
		return <div></div>;
	}
	return (
		<div className="popupwindow-root">
			{
				(!!data.content)
					? (
						<Button onClick={handleClickOpen}>
							<div className="button_kacheln">
								{props.html}
							</div>
						</Button>
					)
					: (
						<div className="button_kacheln">
							{props.html}
						</div>
					)
			}
			<Dialog fullScreen={fullSizeAtribute}
				open={open}
				onClose={handleClose}
				scroll="paper"
				aria-labelledby="scroll-dialog-title"
				className="dialog-styles"
				aria-describedby="scroll-dialog-description">

				<div className="closeicon">
					<p id="scroll-dialog-title" className="dialog-title">{title}</p>
					<Button onClick={handleClose} color="primary">
						<GrFormClose />
					</Button>
				</div>

				<DialogContent dividers={true}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						{/* <div className="info_data_content" dangerouslySetInnerHTML={(data.content)}></div> */}
						<MDEditor.Markdown className="info_data_content" source={data.content?.__html} />

					</DialogContentText>
				</DialogContent>
				<DialogActions>

				</DialogActions>
			</Dialog>
		</div>
	)
}
