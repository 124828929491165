import "./calendar.scss";

import { connectProps } from "@devexpress/dx-react-core";
import { EditingState, IntegratedEditing, ViewState } from "@devexpress/dx-react-scheduler";
import {
	AppointmentForm,
	Appointments,
	AppointmentTooltip,
	DateNavigator,
	Resources,
	Scheduler,
	Toolbar,
	WeekView
} from "@devexpress/dx-react-scheduler-material-ui";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { data as tasks } from "./grouping";
import { priorities } from "./tasks";
import { addCalendarData, deleteCalendarItem, getCalendarData, updateCalendarData } from "./calendarFunctions";
import { Player } from "../../authentication_required/interfaces/player";
import PlayerAnwesendList from "../PlayerAnwesendComp/playerAnwesendList";
import DownloadAList from "../downloadAnwesenheitslist/downloadAList";


const filterTasks = (items: any, priorityId: any) => items.filter((task: any) => (
	!priorityId || task.priorityId === priorityId
));



// #FOLD_BLOCK
const styles = (theme: any) => ({
	flexibleSpace: {
		margin: '0 auto 0 0',
	},
	prioritySelector: {
		marginLeft: theme.spacing(2),
		minWidth: 140,
		'@media (max-width: 500px)': {
			minWidth: 0,
			fontSize: '0.75rem',
			marginLeft: theme.spacing(0.5),
		},
	},
});
// #FOLD_BLOCK
const usePrioritySelectorItemStyles = makeStyles(({ palette, spacing }) => ({
	bullet: ({ color }: any) => ({
		backgroundColor: color ? color[400] : palette.divider,
		borderRadius: '50%',
		width: spacing(1),
		height: spacing(1),
		marginRight: spacing(1),
		display: 'inline-block',
	}),
	prioritySelectorItem: {
		display: 'flex',
		alignItems: 'center',
	},
	priorityText: {
		'@media (max-width: 500px)': {
			display: 'none',
		},
	},
	priorityShortText: {
		'@media (min-width: 500px)': {
			display: 'none',
		},
	},
}));

const PrioritySelectorItem = ({
	color, text: resourceTitle,
}: any) => {
	const text = resourceTitle || 'All Tasks';
	const shortText = resourceTitle ? text.substring(0, 1) : 'All';
	const classes = usePrioritySelectorItemStyles({ color });

	return (
		<div className={classes.prioritySelectorItem}>
			<span className={classes.bullet} />
			<span className={classes.priorityText}>{text}</span>
			<span className={classes.priorityShortText}>{shortText}</span>
		</div>
	);
};

const PrioritySelector = withStyles(styles, { name: 'PrioritySelector' })(({
	classes, priorityChange, priority,
}: any) => {
	const currentPriority = priority > 0 ? priorities[priority - 1] : { text: "", color: "" };
	return (
		<FormControl className={classes.prioritySelector}>
			<Select
				disableUnderline
				value={priority}
				onChange={(e) => {
					priorityChange(e.target.value);
				}}
				renderValue={() => (
					<PrioritySelectorItem text={currentPriority.text} color={currentPriority.color} />
				)}
			>
				<MenuItem value={0}>
					<PrioritySelectorItem />
				</MenuItem>
				{priorities.map(({ id, color, text }) => (
					<MenuItem value={id} key={id.toString()}>
						<PrioritySelectorItem color={color} text={text} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
});

const FlexibleSpace = withStyles(styles, { name: 'FlexibleSpace' })(({
	classes, priority, priorityChange, ...restProps
}: any) => (
	<Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
		<PrioritySelector priority={priority} priorityChange={priorityChange} />
	</Toolbar.FlexibleSpace>
));
// #FOLD_BLOCK

export default function ScheduleCalendar(calendarProps: { allPlayer: Player[] | undefined }) {
	const [currentDate, setCurrDate] = useState<Date>(new Date());
	const [currentPriority, setPriority] = useState<number>(0);
	const [resources, setResources] = useState<any>([{ fieldName: 'priorityId', title: 'Priority', instances: priorities }]);
	const [data, setData] = useState<any>(tasks);
	const [currAppointmentData, setAppointmentData] = useState<any>();
	// const [addedAppointment, setAddedAppointment] = React.useState({});


	useEffect(() => {
		getCalendarData(setData);
	}, []);


	const currentDateChange = (currentDate: Date) => {
		setCurrDate(currentDate);
	};

	const priorityChange = (value: number) => {
		const nextResources = [{
			...resources[0],
			instances: value > 0 ? [priorities[value - 1]] : priorities,
		}];
		setResources(nextResources);
		setPriority(value);
	};
	const flexibleSpace = connectProps(FlexibleSpace, () => {
		return {
			priority: currentPriority,
			priorityChange: priorityChange,
		};
	});

	const onCommitChanges = (({ added, changed, deleted }: any) => {
		if (added && !changed) {
			addCalendarData(added.title, added.priorityId, added.startDate, added.endDate, added.allDay, added.rRule)
				.then(() => setData([...data, { ...added }]))

		}
		if (changed) {
			data.forEach((appointment: any) => {
				if (changed[appointment.id]) {
					updateCalendarData(appointment.dbIndex, { ...appointment, ...changed[appointment.id] });
				}
			});
		}
		if (deleted !== undefined) {
			deleteCalendarItem(data.find((d: any) => d.id === deleted).dbIndex)
		}
	});

	const AppointmentFormComp = (props: any) => {
		return (<AppointmentForm.Layout {...props} >
			<div className="playerAnwesendListStyles">
				<PlayerAnwesendList
					allPlayer={calendarProps.allPlayer}
					dbIndex={currAppointmentData.dbIndex}
					trainingsgruppe={currAppointmentData.priorityId}
					title={currAppointmentData.title}
					startDate={currAppointmentData.startDate}
					endDate={currAppointmentData.endDate} />
				<DownloadAList allPlayer={calendarProps.allPlayer} dbIndex={currAppointmentData.dbIndex} startDate={currAppointmentData.startDate} endDate={currAppointmentData.endDate} />
			</div >
		</AppointmentForm.Layout >);
	}

	return (
		<Paper className="calendarStyles">
			<Scheduler
				data={filterTasks(data, currentPriority)}
				firstDayOfWeek={1}
			>
				<ViewState
					currentDate={currentDate}
					onCurrentDateChange={currentDateChange}
				/>
				<EditingState
					onCommitChanges={onCommitChanges}
				/>
				<IntegratedEditing />


				<WeekView
					startDayHour={12}
					endDayHour={24}
				/>
				<Appointments />
				<Resources
					data={resources}
				/>


				<Toolbar flexibleSpaceComponent={flexibleSpace} />
				<DateNavigator />

				<AppointmentTooltip
					// showDeleteButton={!!currAppointmentData && !!currAppointmentData.dbIndex}
					showOpenButton
				/>
				<AppointmentForm layoutComponent={AppointmentFormComp} onAppointmentDataChange={setAppointmentData} />
			</Scheduler>
		</Paper>
	);
}
