import { readString } from "react-papaparse";

import { analytics, firebaseApp, perf } from "../App";
import { Article } from "./article";
import { sendErrorToDev } from "./email-functions";
import { SportEvent } from "./event";
import Gruppe from "./gruppe";
import { DocumentItem } from "./utils";

let infos_data: any = null;

export async function getGruppenContent(fetchData?: boolean) {
	try {
		if ((infos_data == null || fetchData === true)) {
			const dbRef = firebaseApp.database().ref();
			const data = await dbRef.child("home-content").get().then((snapshot) => {
				if (snapshot.exists()) {
					return snapshot.val();
				} else {
					analytics.logEvent("exception", { ErrorMsg: "ErrorInGetGruppenContent", ErrorCode: JSON.stringify(snapshot), Located: "abfragen.tsx:19" });
					return [];
				}
			}).catch((err) => {
				if (err.message !== "Error: Client is offline.") {
					analytics.logEvent("exception", { ErrorMsg: "ErrorgetGruppenContent", ErrorCode: err.text || err.message || err.statusText || JSON.stringify(err), Located: "abfragen.tsx:28" });
					sendErrorToDev(err.toString(), "abfragen.tsx:25");
				}
				return [];
			});
			let final_data: Gruppe[] = [];
			for (var key of Object.keys(data)) {
				let singledata = data[key];
				final_data.push(
					{
						id: key,
						name: singledata.name,
						img: singledata.img,
						content: singledata.content,
						showOnIndex: singledata.showOnIndex,
					})
			}
			final_data.sort((a, b) => a.showOnIndex > b.showOnIndex ? 1 : -1);
			infos_data = final_data;
			return final_data;
		} else {
			return infos_data;
		}
	} catch (err) {
		analytics.logEvent("exception", { ErrorMsg: "ErrorgetGruppenContent", ErrorCode: JSON.stringify(err), Located: "abfragen.tsx:48" });
		return [];
	}
}

let events: SportEvent[];
export async function fetchEvents(): Promise<SportEvent[]> {
	if (!!events) {
		return events;
	}
	events = await getAllEvents();
	return events;
}

async function getAllEvents() {
	try {
		let final_events: SportEvent[] = [];
		const dbRef = firebaseApp.database().ref();
		const data = await dbRef.child("events").get().then((snapshot) => {
			if (snapshot.exists()) {
				return snapshot.val();
			} else {
				analytics.logEvent("exception", { ErrorMsg: "ErrorGetAllEvents", ErrorCode: snapshot, Located: "abfragen.tsx:69" });
				console.log("No data available");
				return [];
			}
		}).catch((error) => {
			if (error.message !== "Error: Client is offline.") {
				analytics.logEvent("exception", { ErrorMsg: error.text, ErrorCode: error.status, Located: "abfragen.tsx:67" });
				sendErrorToDev(error.toString(), "abfragen.tsx:74");
			}
		});
		if (data) {

			for (var key of Object.keys(data)) {
				let event = data[key];
				if (data[key])
					final_events.push({
						event_title: event.event_title,
						event_description: {
							__html: event.event_description
						},
						start_date: new Date(event.start_date),
						end_date: !!event.end_date ? new Date(event.end_date) : undefined,
						spielbeginn: event.spielbeginn,
						location: event.location,
						tournierart: event.tournierart,
						tournament_link: event.tournament_link || "",
						id: key,
					});
			}
			let events = final_events.filter((event: any) => !!event);
			events.sort((a: any, b: any) => a.start_date <= b.start_date ? -1 : 1);
			return events;
		} else {
			return [];
		}
	} catch (err) {
		analytics.logEvent("exception", { ErrorMsg: err, ErrorCode: JSON.stringify(err), Located: "abfragen.tsx:92" });
		sendErrorToDev((err as any).toString(), "abfragen.tsx:99");
		return [];
	}
}


let allArticles: Article[] = [];

export async function getAllArticles(): Promise<Article[]> {
	try {
		if (allArticles.length > 0) {
			return Promise.resolve(allArticles);
		}
		let localSDate = localStorage.getItem("articleDate");
		var date = new Date(localSDate || "");
		date.setHours(date.getHours() + 4);
		if (localSDate == null)
			localStorage.setItem("articleDate", new Date() + "");
		if (!localStorage.getItem("article") || localStorage.getItem("article") === "[]" || date < new Date()) {
			const trace = perf.trace("getAllArticlesEveryoneFacebookFetch");
			trace.start();
			return await getArticlesfromFacebookAPI()
			.then(article => {
				localStorage.setItem("article", JSON.stringify(article));
				localStorage.setItem("articleDate", new Date() + "");
				allArticles = article
				trace.stop();
				return article;
			})
			.catch(e => {
				trace.stop();
				return Promise.reject(e);
			});
		
		} else {
			let parse = localStorage.getItem("article");
			if (!!parse) {
				const art: Article[] = JSON.parse(parse).filter((article: Article) => !!article && !!article.id);
				allArticles = art;
				return art;
			} else
				return [];
		}
	} catch (er) {
		let error: any = er;
		analytics.logEvent("exception", { ErrorMsg: error?.message, ErrorCode: error?.code, Error: JSON.stringify(error), Located: "abfragen.tsx:136(trycatch)" });
		sendErrorToDev(JSON.stringify(error), "abfragen.tsx:143");
		localStorage.removeItem("article");
		return [];
	}
}

export async function import_downloads() {
	try {

		const dbRef = firebaseApp.database().ref();
		return dbRef.child("document").get().then((snapshot) => {
			if (snapshot.exists()) {
				if (snapshot.toJSON() === "") {
					return [];
				}
				return snapshot.val().sort((a: DocumentItem, b: DocumentItem) => a.name > b.name ? 1 : -1).filter((val: any) => val !== undefined);
			} else {
				console.log("No data available");
				analytics.logEvent("exception", { ErrorMsg: "ErrorInImport_downloads", ErrorCode: snapshot, Located: "abfragen.tsx:160" });
				return [];
			}
		}).catch((error) => {
			if (error.message !== "Error: Client is offline.") {
				analytics.logEvent("exception", { ErrorMsg: error.message, ErrorCode: error.code, Located: "abfragen.tsx:148" });
				sendErrorToDev(error.toString(), "abfragen.tsx:165");
			}
			return [];
		});
	} catch (error) {
		const err = error as any;
		analytics.logEvent("exception", { ErrorMsg: err.message, ErrorCode: err.code, Located: "abfragen.tsx:154" });
		return [];
	}
}

export async function getPlayerRanking(playmode: string, filename: string) {
	if (!localStorage.getItem(playmode)) {

		let data = await fetch(`../${filename}`);
		let text = await data.text();
		let object = readString(text);
		let parsobject = object.data.map((data: any) => (
			{
				platzierung: data[0],
				player: data[1],
				geburtsdatum: data[2],
				player_ID: data[3],
				nationalitaet: data[4],
				verein: data[5],
				punkte: data[6]
			}
		));
		let player_Data = parsobject.filter(filtered_data => (
			filtered_data.verein === "ASV UBERETSCH"
		));

		localStorage.setItem(playmode, JSON.stringify(player_Data));
		return player_Data;
	} else {
		let parse = localStorage.getItem(playmode);
		if (!!parse) {
			return JSON.parse(parse);
		}
	}
}

let playerDataRanking: any = null;
export async function getPlayerData() {
	if (playerDataRanking === null) {
		await fetch("../player_document_date.txt")
			.then(data => data.text())
			.then(data => localStorage.setItem("player_document_date", data));
		let data = await fetch("../BadmintonRanking.csv");
		let text = await data.text();
		let object = readString(text);
		let parsobject = object.data.map((data: any) => (
			{
				player_ID: data[0],
				player_name: data[1],
				geburtsdatum: data[2],
				geschlecht: data[3],
				verein: data[4],
				punkte_single: data[5],
				punkte_doppel: data[6],
				punkte_mixed: data[7],
				platz_single: data[8],
				platz_doppel: data[9],
				platz_mixed: data[10],
				age_category: getAgeCategory(data[2]),
			}
		));
		playerDataRanking = parsobject;
		return parsobject;
	} else
		return await playerDataRanking;
}

function getAgeCategory(date: string) {
	if (!!date) {
		const now = new Date();
		let yearString = new Date(date.slice(6));
		const ageNumber = now.getFullYear() - yearString.getFullYear();
		if (ageNumber === 17 || ageNumber === 18)
			return "U19"
		if (ageNumber === 15 || ageNumber === 16)
			return "U17"
		if (ageNumber === 13 || ageNumber === 14)
			return "U15"
		if (ageNumber <= 12)
			return "U13"
	}
}

async function getArticlesfromFacebookAPI(): Promise<Article[]> {
	try {
		const dbRef = firebaseApp.database().ref();
		const snapshot = await dbRef.child("news").get()
			.catch(error => {
				analytics.logEvent("exception", { ErrorMsg: error?.message || JSON.stringify(error), ErrorCode: error?.code || JSON.stringify(error), Located: "abfragen.tsx:271" });
				return Promise.reject(error);
		});
		if (snapshot != null && snapshot.exists()) {
			return snapshot.val()
				.filter((article: Article) => !!article)
				.map((art: Article) => ({
					...art, image_urls: art?.image_urls[0] === "" ? [] : art?.image_urls
				}));
		} else {
			analytics.logEvent("exception", { ErrorMsg: null, ErrorCode: null, Located: "abfragen.tsx:282" });
			return Promise.reject(null);
		}
	} catch (error) {
		let err = error as any;
		console.error(err);
		analytics.logEvent("exception", { ErrorMsg: err?.message || JSON.stringify(err), ErrorCode: err?.code || JSON.stringify(err), Located: "abfragen.tsx:288" });
		sendErrorToDev(JSON.stringify(err), "abfragen.tsx:289");
		return Promise.reject({...err, sent: true});
	}
}



