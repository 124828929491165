import { firebaseApp } from "../../App";
import { DocumentLink } from "../interfaces/player";

export async function getPlayerDocuments(player_id: string) {
	let storage = firebaseApp.storage();
	let list = await storage.ref(`/user-data/${player_id}`).list();
	if (list.items !== [] && list.items[0]) {
		let links = await list.items;
		let result: DocumentLink[] = [];
		for (let link of links) {
			let url = await link.getDownloadURL();
			let name = await link.name;
			let meta = await link.getMetadata();
			let timeCreated = new Date(await meta.timeCreated);
			let type = await name.split(".");
			let bytes = await meta.size;
			result.push({ name: name, url: url, type: type[type.length - 1], timeCreated: timeCreated.toLocaleString(), size: parseSizeToString(bytes) });
		}
		return result;
	} else {
		return;
	}
}

export function parseSizeToString(bytes: number): string {
		const sizes_types = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		let size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes_types[i];
		return size;
	}
