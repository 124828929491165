export function formatDate(date: Date): string {
	return date.toLocaleDateString();
}

export interface RankingPlayer {
	player_ID: number,
	player_name: string,
	geburtsdatum: Date,
	geschlecht: string,
	verein: string,
	punkte_single: number,
	punkte_doppel: number,
	punkte_mixed: number,
	platz_single: number,
	platz_doppel: number,
	platz_mixed: number,
	age_category: string,
	platz: number,
	punkte: number,
};

export interface DownloadDocumnetItem {
	name: string,
	groesse: string,
	type: string,
	url: string,
}

export interface CalendarItem {
	title: string, priorityId: number, startDate: Date, endDate: Date, allDay: boolean, id: number, rRule: string | undefined, dbIndex: string
}

export interface RankingType {
	date: string,
	points: number,
	user: string,
}


export interface DocumentItem {
	name: string,
	url: string,
	type: string,
	access: string[],
	path: string,
}
