import React, { useEffect, useState } from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import './slideshow.scss';


function getClasses(idx: number, currIdx: number) {
	return idx === currIdx ? "dot curr" : "dot";
}

export default function Slideshow(props: any) {
	function getDots(images: string[], idx: number) {
		if (!images)
			return (<div></div>)
		return images.map(
			(g: string, index: number) => (<div className={getClasses(index, idx)} onClick={() => setCurrentIndex(index)}></div>)
		)
	}


	const [currentIndex, setCurrentIndex] = useState(0);
	const [fadeInOut] = useState("fade");
	const [images, setImages] = useState<string[]>([]);
	const prev = () => {
		setCurrentIndex(currentIndex === 0 ? (images.length - 1) : currentIndex - 1);
	}

	const next = () => {
		setCurrentIndex((currentIndex + 1) % (images.length));
	}

	const getCurImage = () => {
		return images[currentIndex];
	}



	useEffect(() => {
		if (props.images) {
			setImages(props.images);
		}
	}, [props.images]);


	return (
		<div className="slide-show-container_components">
			<div className="slideshow_buttons_components">
				<div className="prev" onClick={() => prev()}>
					<div className="round">
						<GrPrevious size="20px" />
					</div>
				</div>
				<div className={fadeInOut}>
					<img src={getCurImage()} alt="slideshowImage"/>
				</div>
				<div className="next" onClick={() => next()}>
					<div className="round">
						<GrNext size="20px" />
					</div>
				</div>
			</div>
			<div className="dot-container">
				{getDots(images, currentIndex)}
			</div>
		</div>
	)
}
