import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";

const useStyles = makeStyles({
	container: {
		maxHeight: 440,
	},
});

interface TableProps {
	columns: any[];
	rows: any[];
	onClick?: (event: any) => void;
}

export default function TableWithoutPaginator(props: TableProps) {
	const date = new Date();
	const { columns, rows, onClick } = props;
	const classes = useStyles();
	return <TableContainer className={classes.container} >
		<Table stickyHeader aria-label="sticky table" >
			<TableHead >
				<TableRow>
					{columns.map((column) => (
						<TableCell

							key={column.id}
							align={column.align}
							style={{ minWidth: column.minWidth }}
						>
							{column.label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row, i) => {
					return (
						<TableRow hover role="checkbox" tabIndex={-1} onClick={() => !!onClick && onClick(row.event)}>
							{
								columns.map((column) => {
									const value = row[column.id];
									return (
										<TableCell key={column.id} align={column.align} className="tableCellStyles">
											<p>{column.format && typeof value === 'number' ? column.format(value) : value}</p>
										</TableCell>
									);
								})

							}
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	</TableContainer>
}
