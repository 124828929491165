export const data = [
	 {
	  title: 'Provide New Health Insurance Docs',
	  priorityId: 3,
	  startDate: new Date(2018, 4, 18, 12, 0, 0),
	  endDate: new Date(2018, 4, 18, 15, 0, 0),
	  id: 24,
	  rRule: 'RRULE:INTERVAL=1;FREQ=WEEKLY;COUNT=13;BYDAY=MO,WE',
	},
  ];

  export const priorityData = [
	{
	  text: 'Low Priority',
	  id: 1,
	}, {
	  text: 'High Priority',
	  id: 2,
	},
  ];
