import "./event-beschreibung.scss";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";

import { SportEvent } from "../model/event";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

export default function EventDialog(data: any) {
	const [single_data, setSingle_data] = useState<SportEvent>(data.data.event || data.data);
	const [open, setOpen] = React.useState(false);
	const [windowSize, setWindowSize] = useState<number>();

	let fullSizeAtribute: boolean = false;

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	if (!!windowSize && windowSize < 800)
		fullSizeAtribute = true;

	useEffect(() => {
		setSingle_data(data.data.event || data.data);
	}, [data]);

	useEffect(() => {

		function handleResize() {
			setWindowSize(window.screen.width);
		}
		window.addEventListener("resize", handleResize);
		handleResize();


		return () => window.removeEventListener("resize", handleResize);
	}, [open]);

	return (
		<div>
			<div onClick={handleClickOpen}>
				<div className="information">
					<Button className="info-icon-button"><BsInfoCircle /></Button>
				</div>
			</div>
			<Dialog fullScreen={fullSizeAtribute} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="event-description">
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					{single_data.event_title}
				</DialogTitle>
				<DialogContent dividers>
					<div dangerouslySetInnerHTML={single_data.event_description}></div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
