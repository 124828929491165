export interface SportEvent {
	event_title: string;
	event_description: {
        __html: string;
    },
	start_date: Date;
	end_date?: Date;
	spielbeginn: string;
	location: string;
	tournierart: string;
	tournament_link: string;
	id: string;
};

export function betweenADate(compareDate: Date, startDate: Date, endDate: Date): boolean {
	const compareDateUTC = new Date(Date.UTC(compareDate.getFullYear(), compareDate.getMonth(), compareDate.getDate()));
	const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
	const endDateUTC = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
	return compareDateUTC >= startDateUTC && compareDateUTC <= endDateUTC;
}
