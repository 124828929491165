import "./player-config.scss";

import { Avatar, Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import UploadDocOfPlayersDialog from "../../../components/upload-doc-dialog-player/uploadDocOfPlayersDialog";
import AuthDialog from "../../Authentication/auth-popup/auth-popup";
import StickyHeadTable from "../../custom-player-data/table-for-data";
import { DocumentLink, Player } from "../../interfaces/player";
import { deletePlayer } from "../../table-functions/table-player-functions";
import EditPlayerComponent from "../playerData/editPlayer/editPlayerComponent";



export interface PlayerConfigProps {
	currPlayer: Player | null;
	allPlayers: Player[];
	update: (currPNull?: boolean) => void;
	getDocOfPlayerAddToData: (pird: string) => Promise<boolean>; //in App implementiert
}

enum ButtonNumber {
	SpielerLöschen = 1,
	SpielerBearbeiten = 2,
	DokumenteVerwalten = 3,
}

export default function PlayerConfig(props: PlayerConfigProps) {
	const [currPlayer, setCurrPlayer] = useState<Player | null>(props.currPlayer);
	const [clickOnButton, setClickOnButton] = useState<ButtonNumber>(-1);
	const { enqueueSnackbar } = useSnackbar();
	const [update, setUpdate] = useState<boolean>(false);

	function updatePlayerLinks(index: number, links: DocumentLink[]) {
		if (!!currPlayer) {
			currPlayer.links = links;
			setUpdate(!update)
		}
	}

	useEffect(() => {
		if (props.currPlayer) {
			setCurrPlayer(props.currPlayer);
			props.getDocOfPlayerAddToData(props.currPlayer.pid)
			setClickOnButton(-1)
		}
	}, [props, props.currPlayer, update]);

	if (currPlayer) {
		const getCodeOnButtonEvent = (button: ButtonNumber) => {
			if (button === ButtonNumber.DokumenteVerwalten) {
				return (
					<div className="player-config-single-data">
						<StickyHeadTable links={currPlayer.links} pid={currPlayer.pid} currPlayer={currPlayer} updatePlayer={updatePlayerLinks} canSendNotifications={true} />
					</div>
				);
			}
		}

		const handledeletePlayer = () => {
			var eingabe = window.confirm("ACHTUNG! Du bist dabei den Spieler " + currPlayer.full_name + " zu löschen. Bitte bestätige den Vorgang");
			if (eingabe) {
				deletePlayer(currPlayer.pid)
					.then(result => {
						if (result) {
							props.allPlayers.splice(props.allPlayers.indexOf(currPlayer), 1)
							enqueueSnackbar("Der Spieler " + currPlayer.full_name + " wurde erfolgreich gelöscht", { key: "DeletePlayerSuccess", variant: "success" });
							setCurrPlayer(null);
							props.update(true);
						} else {
							enqueueSnackbar("Der Spieler " + currPlayer.full_name + " konnte nicht gelöscht werden", { key: "DeletePlayerError", variant: "warning" });
						}
					})

			}
		}
		return (
			<div className="player-config-outer-root">
				<div className="player-config-name">
					<Avatar >{currPlayer.nachname[0]}{currPlayer.vorname[0]}</Avatar>
					<p>Spieler: {currPlayer.full_name}</p>
				</div>
				<div className="buttons-player-root">
					<div className="button-player-config-outer-container">
						<div className="button-player-config-outer-container">
							<Button variant="contained" className="buttons-player-settings" onClick={() => handledeletePlayer()}>Spieler löschen</Button>
						</div>

						<div className="button-player-config-outer-container">
							<AuthDialog
								button_name={<Button variant="contained" className="buttons-player-settings" >Spielerdaten bearbeiten</Button>}
								component={<EditPlayerComponent currPlayer={currPlayer} update={props.update} />} open={false} function={() => { }}
								donotgoBack={true}
							/>
						</div>
						<div className="button-player-config-outer-container">
							<Button
								variant="contained"
								className="buttons-player-settings"
								onClick={() => setClickOnButton(ButtonNumber.DokumenteVerwalten)}>
								Dokumente verwalten
							</Button>
						</div>
						<div className="button-player-config-outer-container">
							<AuthDialog
								button_name={<Button variant="contained" className="buttons-player-settings">Upload Dokumente</Button>}
								component={<UploadDocOfPlayersDialog index={0} links={currPlayer.links} currPlayer={currPlayer} updatePlayer={updatePlayerLinks} />}
								donotgoBack={true}
								close={true}
							/>
						</div>
					</div>

				</div>
				{
					getCodeOnButtonEvent(clickOnButton)
				}
			</div>
		)
	} else
		return (<div />)
}
