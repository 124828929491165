import { Accordion, AccordionDetails, AccordionSummary, Avatar, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../components/spinner";
import AuthDialog from "../Authentication/auth-popup/auth-popup";
import EditPlayerComponent from "../data-components/playerData/editPlayer/editPlayerComponent";
import { DocumentLink, Player } from "../interfaces/player";
import "./customPlayerData.scss";
import StickyHeadTable from "./table-for-data";



interface CustomDataProps {
	userPlayer: Player[] | undefined;
}

export default function CustomPlayerData(props: CustomDataProps) {
	const [player, setPlayer] = useState<Player[]>();
	const [update, setUpdate] = useState<boolean>(false);
	if (window.performance && performance.navigation.type === 1) {
		// localStorage.clear();
	}
	useEffect(() => {
		setPlayer(props.userPlayer)
	}, [props, props.userPlayer]);

	function updatePlayer(index: number, links?: DocumentLink[]) {
		if (player) {
			if (!!links) {
				player[index].links = links;
			}
			setUpdate(!update)
		}
	}
	if (!!player && player.length === 0) {
		return <div className="noPlayerRef">
			<p>Zu Ihren Konto wurde noch kein Spieler zugeordnet. <Link to="/Profil">Den Zugriff für einen Spieler können Sie <u>hier</u> anfordern</Link></p>
		</div>
	}


	if (player)
		return (
			<div className="customPlayer-outer-styles">
				<div>
					{

						player.map((single_player: Player, index: number) =>
							<Accordion id={single_player.nachname + single_player.vorname} defaultExpanded>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id={"panel1a-header-" + (index)}
								>
									<Typography className="player-infos"><Avatar src={single_player.bild || ""} /><p className="player-name">{single_player.full_name}</p></Typography>
									<div className="infoicon-player">
										<AuthDialog
											button_name={<InfoIcon />}
											component={
												<EditPlayerComponent
													currPlayer={single_player}
													update={function () {
														setUpdate(!update)
													}}
													disableFields={[true, true, true, false, false, false, true, false, true, false, true]}
												/>
											} open={false} function={() => { }}
											donotgoBack={true}
										/>

									</div>
								</AccordionSummary>
								<AccordionDetails >
									<StickyHeadTable links={single_player.links} pid={single_player.pid} player={single_player} index={index} updatePlayer={updatePlayer} />
								</AccordionDetails>
							</Accordion>

						)
					}
				</div>
			</div>
		)
	return <div className="customPlayerData-spinner"><Spinner /></div>
}

